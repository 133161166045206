import React from 'react';
import SEO from '~/components/Seo';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation('404');

  return (
    <>
      <SEO title={t('title')} />
      <div style={{ margin: '80px', textAlign: 'center' }}>
        <h1>{t('notFound')}</h1>
        <p>{t('body')}</p>
      </div>
    </>
  );
};

export default NotFoundPage;

export const translationrQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
