/* eslint-disable  @typescript-eslint/ban-ts-comment  */
import { Maybe, Scalars } from '../types/generated';
import { ShopifyStorefront } from '../types/shopify-storefront';
import { SearchResult } from '../types';
import { parseShopifyId } from './shopify';
import { FullProduct } from '../hooks/useAllProducts';

export type Discount = {
  title: string;
  amount: string;
};
export type LineItemTransformed = {
  key: string;
  title: string;
  imageSrc: string;
  productUrl?: string;
  variantTitle: string;
  quantity: number;
  customAttributes: ShopifyStorefront.Attribute[];
  originalTotal: number;
  discountedTotal: number;
  originalUnitPrice: number;
  discountedUnitPrice: number;
  quantityAvailable?: number;
  discounts: Array<Discount>;
  sku?: string;
  isProDiscount: boolean;
};
export const itemToLineItemProps = (
  item: ShopifyStorefront.CheckoutLineItem,
): LineItemTransformed => {
  const {
    id,
    title,
    variant,
    quantity,
    customAttributes = [],
    discountAllocations = [],
  } = item;

  const cleanCustomAttributes = customAttributes.filter(({ key, value }) => {
    const hasValidKey = !key.startsWith('_');
    const hasValidVal = value?.trim() !== '';

    // There are shopify attributes that private applications can add
    // These are blank or start with underscores
    return hasValidKey && hasValidVal;
  });

  const totalLineDiscounts = discountAllocations.reduce(
    (memo, { allocatedAmount }) => {
      return memo + parseFloat(allocatedAmount.amount);
    },
    0,
  );
  const discounts: Array<Discount> = discountAllocations
    .map(({ allocatedAmount, discountApplication: app }) => {
      return {
        title:
          (app as ShopifyStorefront.DiscountCodeApplication).code ||
          (app as ShopifyStorefront.ScriptDiscountApplication).title ||
          (app as ShopifyStorefront.ScriptDiscountApplication).description,
        amount: allocatedAmount.amount as string,
      };
    })
    .filter(({ amount }) => parseFloat(amount) > 0);
  const isProDiscount = !!discounts.find(
    (discount: Discount) =>
      discount.title.toLowerCase() === 'free reward applied' ||
      discount.title.toLowerCase().startsWith('pro member'),
  );

  const {
    image,
    product,
    id: variantId,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    priceV2, // deprecated
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    compareAtPriceV2, // deprecated
    title: variantTitle,
    quantityAvailable,
    sku,
  } = variant!;

  const unitPrice = parseFloat(priceV2?.amount);
  const originalTotal =
    parseFloat(compareAtPriceV2?.amount || priceV2?.amount) * quantity;
  const discountedTotal = unitPrice * quantity - totalLineDiscounts;
  const discountedUnitPrice = unitPrice - totalLineDiscounts / quantity;

  const productUrl =
    product.handle === 'whoop-pro'
      ? undefined
      : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `/products/${product.handle}?variant=${parseShopifyId(variantId)!}`;

  return {
    key: id.toString(),
    title,
    imageSrc: image?.src as string,
    productUrl,
    variantTitle,
    quantity,
    customAttributes: cleanCustomAttributes,
    originalTotal: originalTotal,
    discountedTotal: discountedTotal,
    originalUnitPrice: parseFloat(compareAtPriceV2?.amount || priceV2?.amount),
    discountedUnitPrice: discountedUnitPrice,
    quantityAvailable,
    discounts,
    sku,
    isProDiscount: !!isProDiscount,
  };
};
export type LineItemMessage = {
  message?: string;
  isWarning?: boolean;
};

export function getCompatibilityMessage(
  tags?: string[],
  userStrap?: string,
  isWyw?: boolean,
): LineItemMessage {
  const gen3Compatible = tags?.find((tag) => tag === 'gen3');
  const gen4Compatible = tags?.find((tag) => tag === 'gen4');
  let message;
  let isWarning = false;
  if (isWyw) {
    isWarning = !!(userStrap && userStrap !== 'harvard');
    return { message: '4.0 compatible', isWarning: isWarning };
  }

  // @ts-ignore: XOR
  if (!!gen3Compatible ^ !!gen4Compatible) {
    message = `${gen4Compatible ? '4.0' : '3.0'} compatible`;
    isWarning = !!(
      userStrap && userStrap !== (gen4Compatible ? 'harvard' : 'copley')
    );
  }
  return { message, isWarning };
}

export function isWhoopProExclusive(
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
  sku?: Maybe<string>,
): boolean {
  return !!tags?.find(
    (tag) =>
      tag === '__pro:exclusive' || tag === `__pro:exclusive:${sku ?? ''}`,
  );
}

export function isNewProduct(
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
): boolean {
  return !!tags?.find((tag) => tag?.toLowerCase() === 'new');
}

export function is3for2Sale(
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
): boolean {
  return !!tags?.find((tag) => tag?.toLowerCase() === 'sale');
}
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */
export function showGenerationToggle(
  products: FullProduct[] | SearchResult[],
): boolean {
  if (products) {
    // @ts-ignore
    const hasGen4OnlyItems = products.find(
      // @ts-ignore
      ({ tags }) =>
        tags?.find((tag: string) => tag === 'gen4') &&
        !tags?.find((tag: string) => tag === 'gen3'),
    );
    // @ts-ignore
    const hasGen3OnlyItems = products.find(
      // @ts-ignore
      ({ tags }) =>
        tags?.find((tag: string) => tag === 'gen3') &&
        !tags?.find((tag: string) => tag === 'gen4'),
    );
    return !!hasGen4OnlyItems && !!hasGen3OnlyItems;
  } else {
    return false;
  }
}

export type FilterFunction = (item: [string, any]) => boolean;

/**
 * Creates a new object from the given object, filtering its properties to only
 * those properties for which the filter function returns true.
 *
 * @param object The object whose properties to filter
 * @param filterFn The filter function to filter the object properties with
 */
export function filterProperties(
  object: Record<string, any>,
  filterFn: FilterFunction,
): Record<string, any> {
  return Object.entries(object).reduce<Record<string, any>>((acc, entry) => {
    const [name, value] = entry;
    if (filterFn(entry)) {
      acc[name] = value;
    }
    return acc;
  }, {} as Record<string, any>);
}

/**
 * Creates a new object from the given object, filtering the properties to only
 * data attributes, ie. properties whose name starts with 'data'.
 */
export function filterDataAttributes(
  object: Record<string, any>,
): Record<string, any> {
  return filterProperties(object, ([name]) => {
    return name.startsWith('data');
  });
}

export function isFreeCheckoutItem(
  item: ShopifyStorefront.CheckoutLineItem,
): boolean {
  return !!item.discountAllocations.find(
    (discount) =>
      discount.discountApplication.description === 'Free Reward Applied',
  );
}
