import fetch from 'isomorphic-fetch';
import { API_DOMAIN, API_PROTOCOL } from './regions';
import { getAccessToken } from '@whoop/web-auth-client';
import { InvalidCredentialsError } from './errors';
import { sendSentryError } from './wrapped-sentry';
import { Optional } from '@whoop/web-components/dist/types';

// -----
// Type definitions matching JSON responses from membership-service APIs
// -----
/* eslint-disable camelcase */
export interface WhoopProInfo {
  store_discount: number;
  status: 'active' | 'expiring' | 'inactive' | 'ineligible' | 'pending';
  end_date?: string; // "yyyy-MM-dd'T'HH:mm:ss.SSS"
  reward_status: 'available' | 'unavailable';
  welcome_reward_status: 'available' | 'unavailable';
  next_reward_date?: string; // "yyyy-MM-dd"
}

export interface WhoopProCtaIF {
  title: string;
  button_text: string;
  button_link: string;
  footer: string;
}

export interface WhoopProPerkIF {
  description: string;
  icon: {
    name: string;
  };
}

export interface WhoopProLandingContent {
  store_discount: number;
  monthly_price: string;
  unformatted_monthly_price: number;
  header: string;
  subtitle: string;
  cta: WhoopProCtaIF;
  perks: WhoopProPerkIF[];
}

export interface StrapRecord {
  generation?: string;
  serial?: string;
  first_seen?: string;
}

export interface StorefrontUser {
  has_whoop_pro?: Optional<boolean>;
  whoop_pro_end_date?: Optional<string>;
  has_free_reward?: Optional<boolean>;
  could_upgrade_for_free?: Optional<boolean>;
  upgrade_requires_annual_plan?: Optional<boolean>;
  upgrade_pro_rated_months?: Optional<number>;
  is_employee?: Optional<boolean>;
  billing_region?: Optional<string>;
  feature_flags?: Optional<string[]>;
  id: Optional<number>;
  created_at?: Optional<number>;
  updated_at?: Optional<number>;
  avatar_url?: Optional<string>;
  first_name?: Optional<string>;
  last_name?: Optional<string>;
  country?: Optional<string>;
  admin_division?: Optional<string>;
  city?: Optional<string>;
  email?: Optional<string>;
  straps?: {
    last_seen_strap: Optional<StrapRecord>;
    ordered_strap: Optional<StrapRecord>;
    previous_straps: Optional<StrapRecord[]>;
  };
}

export interface MultipassResponse {
  multipass_token: string;
  user: StorefrontUser;
}

interface WhoopProLandingContentResponse extends WhoopProLandingContent {
  whoop_pro_info?: WhoopProInfo;
}

// TypeScript analog of the Java enumeration com.whoop.membership.models.billing.BillingRegion:
//     public enum BillingRegion {
//       domestic, uk, uae, australia, international
//     }
enum BillingRegion {
  domestic = 'domestic',
  ca = 'ca',
  uk = 'uk',
  uae = 'uae',
  australia = 'australia',
  international = 'international',
}

/* eslint-enable camelcase */
// -----
// End type definitions matching JSON responses from membership-service APIs
// -----

export interface MemberWhoopProLandingContent {
  whoopProInfo?: WhoopProInfo;
  landingContent?: WhoopProLandingContent;
}

// Convert the SHOP_NAME string literals to the matching billing regions
function getRegion(): BillingRegion {
  switch (process.env.SHOP_REGION) {
    case 'AE':
      return BillingRegion.uae;
    case 'AU':
      return BillingRegion.australia;
    case 'EU':
      return BillingRegion.international;
    case 'UK':
      return BillingRegion.uk;
    case 'CA':
      return BillingRegion.ca;
    case 'US':
    default:
      return BillingRegion.domestic;
  }
}

export async function fetchDefaultWhoopProLandingContent(): Promise<
  Optional<WhoopProLandingContent>
> {
  try {
    const region = getRegion();
    const locale = process.env.SHOP_LANGUAGE;
    const response: Response = await fetch(
      new Request(
        `${API_PROTOCOL}://${API_DOMAIN}/membership-service/v1/billing/whoop-pro/landing-content/${region}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': locale || 'en',
          },
        },
      ),
    );

    if (response.status === 200) {
      const json = (await response.json()) as WhoopProLandingContentResponse;
      return {
        store_discount: json?.store_discount,
        monthly_price: json?.monthly_price,
        unformatted_monthly_price: json?.unformatted_monthly_price,
        header: json.header,
        subtitle: json.subtitle,
        cta: json.cta,
        perks: json.perks,
      };
    } else {
      console.error(
        'Error fetching default whoop pro landing config',
        response.status,
        response.statusText,
      );
    }
  } catch (e) {
    console.error('Exception fetching default whoop pro landing config', e);
  }
}

export async function fetchMemberWhoopProClaimFreeReward(): Promise<boolean> {
  const whoopAuthToken = await getAccessToken().catch((err) =>
    console.error(err),
  );
  const region = getRegion();
  if (whoopAuthToken) {
    const locale = process.env.SHOP_LANGUAGE;
    const response: Response = await fetch(
      new Request(
        `${API_PROTOCOL}://${API_DOMAIN}/membership-service/v1/storefront/whoop-pro/claim-reward/${region}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${whoopAuthToken}`,
            'Accept-Language': locale || 'en',
          },
        },
      ),
    );

    if (response.status === 200) {
      return (await response.json()) as boolean;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function updateMemberWhoopProClaimFreeReward(
  flag: boolean,
): Promise<void> {
  const whoopAuthToken = await getAccessToken().catch((err) =>
    console.error(err),
  );
  const region = getRegion();
  if (whoopAuthToken) {
    const locale = process.env.SHOP_LANGUAGE;
    await fetch(
      new Request(
        `${API_PROTOCOL}://${API_DOMAIN}/membership-service/v1/storefront/whoop-pro/claim-reward/${region}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${whoopAuthToken}`,
            'Accept-Language': locale || 'en',
          },
          body: JSON.stringify({
            claim_reward: flag,
          }),
        },
      ),
    );
  }
}

export async function fetchMemberWhoopProLandingContent(): Promise<MemberWhoopProLandingContent> {
  const whoopAuthToken = await getAccessToken().catch((err) =>
    console.error(err),
  );
  if (whoopAuthToken) {
    const locale = process.env.SHOP_LANGUAGE;
    const response: Response = await fetch(
      new Request(
        `${API_PROTOCOL}://${API_DOMAIN}/membership-service/v1/billing/whoop-pro/landing-content?client=storefront`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${whoopAuthToken}`,
            'Accept-Language': locale || 'en',
          },
        },
      ),
    );

    if (response.status === 200) {
      const json = (await response.json()) as WhoopProLandingContentResponse;
      return {
        whoopProInfo: json?.whoop_pro_info,
        landingContent: {
          store_discount: json?.store_discount,
          monthly_price: json?.monthly_price,
          unformatted_monthly_price: json?.unformatted_monthly_price,
          header: json?.header,
          subtitle: json?.subtitle,
          cta: json?.cta,
          perks: json?.perks,
        },
      };
    } else if (response.status === 401 || response.status === 403) {
      // Log authentication error to the console
      console.warn(
        'Authentication error fetching member whoop pro landing content',
        response.status,
        response.statusText,
      );
    } else {
      // Unexpected error, such as a server error or a network timeout. Do not attempt to refresh the session,
      // since the same error is likely to reoccur.
      console.warn(
        'Unexpected error fetching member whoop pro landing content',
        response.status,
        response.statusText,
      );
    }
  }

  // No access token or error fetching the member landing content
  throw new Error('Error fetching landing content');
}

export async function syncShopifyTags() {
  const whoopAuthToken = await getAccessToken().catch((err) =>
    console.error(err),
  );
  const region = getRegion();
  if (whoopAuthToken) {
    const locale = process.env.SHOP_LANGUAGE;
    return fetch(
      new Request(
        `${API_PROTOCOL}://${API_DOMAIN}/membership-service/v1/storefront/synchronize-customer/${region}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${whoopAuthToken}`,
            'Accept-Language': locale || 'en',
          },
        },
      ),
    );
  }
  throw new Error('Error syncing Shopify tags. No WHOOP access token present.');
}

export async function fetchMultipassToken(): Promise<MultipassResponse> {
  const whoopAuthToken = await getAccessToken().catch((err) =>
    console.error(err),
  );
  if (!whoopAuthToken) {
    throw new InvalidCredentialsError('Invalid credentials');
  }

  const locale = process.env.SHOP_LANGUAGE;
  const response = await fetch(
    `${API_PROTOCOL}://${API_DOMAIN}/membership-service/v1/storefront/multipass`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${whoopAuthToken}`,
        'Accept-Language': locale || 'en',
      },
      body: JSON.stringify({
        redirect_url: window.location.href,
        shop_name: process.env.SHOP_NAME,
      }),
    },
  );

  if (response.status === 403 || response.status === 401) {
    throw new InvalidCredentialsError('Invalid credentials');
  } else if (response.status !== 200) {
    sendSentryError(
      new Error(
        `Unexpected error in multipass response: ${JSON.stringify(response)}`,
      ),
    );
    throw new Error('Operation failed');
  }

  return response.json() as Promise<MultipassResponse>;
}
