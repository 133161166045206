import { EngravingInfo, ProductItem, ProductNode } from '@whoop/web-components';
import { WhoopYourWaySelection } from './product-service';
import { Optional } from '@whoop/web-components/dist/types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useReduxDispatch } from '../redux/create-store';
import { showGlobalError } from '../redux/reducers/viewReducer';
import {
  addItemToCart,
  replaceCartLineItems,
} from '../redux/reducers/cartReducer';
import {
  findFirstProductItem,
  isEngraveable,
  isProductItemExclusive,
  ProductItemKey,
} from './productUtils';
import { useMemo } from 'react';
import { parseShopifyId } from './shopify';
import { useAutomaticDiscount } from './priceUtils';
import { useShopifyProductData } from './shopifyProductUtils';
import useShopifyVariantsBySku, {
  ShopifyVariantsBySku,
} from '../hooks/useShopifyVariantsBySku';
import { useSkuMaxQuantities } from './quantityUtils';
import { ShopifyStorefront } from '../types/shopify-storefront';
import { useSelector } from 'react-redux';
import { Store } from '../types';
import { ShopifyProductVariant } from '../types/generated';
import { trackAddToCart } from './analytics';

interface CustomAttribute {
  key: string;
  value: string;
}

interface PurchasableItem {
  type: 'item';
  item: ProductItem;
}

interface PurchasableWhoopYourWay {
  type: 'wyw';
  item: ProductItem;
  wywSelection: WhoopYourWaySelection;
}

export interface PurchasablePack {
  type: 'pack';
  item: ProductItem;
  packItems: Optional<ProductItem>[];
}

export interface PurchasableEngravedItem {
  type: 'engraved';
  item: ProductItem;
  engraving: Optional<EngravingInfo>;
}

export type Purchasable =
  | PurchasableItem
  | PurchasableWhoopYourWay
  | PurchasablePack
  | PurchasableEngravedItem;

export const usePurchasablePack = (
  node?: Optional<ProductNode>,
  packItems?: Optional<ProductItem>[],
): Optional<PurchasablePack> => {
  return useMemo(() => {
    const item = node && findFirstProductItem(node);
    if (item && packItems?.filter((_) => !_)?.length === 0) {
      return {
        type: 'pack',
        item,
        packItems,
      };
    }
  }, [node, packItems]);
};

export const usePurchasableItem = (
  item: Optional<ProductItem>,
  node: ProductNode,
  engraving?: EngravingInfo,
): Optional<PurchasableItem | PurchasableEngravedItem> => {
  return useMemo(() => {
    if (item) {
      if (isEngraveable(node)) {
        return {
          type: 'engraved',
          item,
          engraving,
        };
      }
      return {
        type: 'item',
        item,
      };
    }
  }, [item, node, engraving]);
};

export const isInStock = (purchasable: Optional<Purchasable>): boolean => {
  if (purchasable && 'packItems' in purchasable) {
    return (
      (purchasable?.item?.quantity || 0) > 0 ||
      !!purchasable?.packItems?.find((item) => (item?.quantity || 0) > 0)
    );
  }
  return (purchasable?.item?.quantity || 0) > 0;
};

export const usePurchasableMaxQuantity = (
  purchasable: Optional<Purchasable>,
): number | undefined => {
  const skus = useMemo(() => {
    if (purchasable && 'packItems' in purchasable) {
      return purchasable?.packItems?.map((item) => item?.sku);
    }
    return [purchasable?.item?.sku];
  }, [purchasable]);
  const maxQuantities = useSkuMaxQuantities(skus);
  return useMemo(() => {
    const isIndeterminate =
      maxQuantities.filter((qty) => typeof qty === 'undefined').length > 0;
    if (!isIndeterminate) {
      return skus?.reduce((min, sku, i) => {
        const maxQtyForSku = maxQuantities[i] || 0;
        const countInSku = skus.filter((s) => s === sku).length - 1;
        return Math.min(min, maxQtyForSku - countInSku);
      }, Infinity);
    }
  }, [skus, maxQuantities]);
};

const getPackCustomAttributes = (
  purchasable: PurchasablePack,
  variants: ShopifyVariantsBySku,
  discount: number,
): CustomAttribute[] => {
  const customAttributes: CustomAttribute[] = [];

  purchasable?.packItems?.forEach((packItem, index) => {
    const variant = packItem && variants?.[packItem?.sku];
    if (variant) {
      const unitPrice = parseFloat((variant.price as string) || '0');
      const finalPrice = unitPrice * (1.0 - discount / 100.0);

      customAttributes.push({
        key: `${index + 1}`,
        value: `${variant.product.title!}: ${variant.title!}`,
      });
      customAttributes.push({
        key: `_sku_${index + 1}`,
        value: `${variant.sku || ''}`,
      });
      customAttributes.push({
        key: `_unit_price_${index + 1}`,
        value: `${finalPrice.toFixed(2)}`,
      });
      customAttributes.push({
        key: `_product_id_${index + 1}`,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `${parseShopifyId(variant.product?.shopifyId || '') || ''}`,
      });
      customAttributes.push({
        key: `_variant_id_${index + 1}`,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `${parseShopifyId(variant.shopifyId || '') || ''}`,
      });
    }
  });
  if (isProductItemExclusive(purchasable, ProductItemKey.pro_exclusive)) {
    customAttributes.push({
      key: `_pro_exclusive`,
      value: 'true',
    });
  }
  return customAttributes;
};

interface LineItem {
  variant?: ShopifyProductVariant;
  sku?: string;
  maxQuantity?: number;
  customAttributes?: CustomAttribute[];
}

const getLineItemFromPurchasable = (
  purchasable: Optional<Purchasable>,
): LineItem => {
  const variants = useShopifyVariantsBySku();
  const { tags } = useShopifyProductData(purchasable?.item?.sku);
  const automaticDiscount = useAutomaticDiscount(tags);

  const sku = purchasable?.item?.sku;
  const variant = sku ? variants[sku] : undefined;
  const maxQuantity = usePurchasableMaxQuantity(purchasable);
  let customAttributes: CustomAttribute[] = [];

  if (purchasable?.type === 'pack') {
    customAttributes = getPackCustomAttributes(
      purchasable,
      variants,
      automaticDiscount,
    );
  } else if (purchasable?.type === 'wyw') {
    customAttributes = [];
  } else if (purchasable?.type === 'engraved') {
    customAttributes = [];
    Object.keys(purchasable?.engraving?.data || {})?.forEach((key) => {
      const engravingLine = purchasable?.engraving?.data[key];
      customAttributes.push({
        key: engravingLine?.label || '',
        value: engravingLine?.value || '',
      });
      customAttributes.push({
        key: `_${key}`,
        value: engravingLine?.value || '',
      });
    });
  }

  return {
    variant,
    sku,
    maxQuantity,
    customAttributes,
  };
};

export const useAddToCart = (
  purchasable: Optional<Purchasable>,
  quantity: number,
) => {
  const { t } = useTranslation('cart');
  const dispatch = useReduxDispatch();
  const item = getLineItemFromPurchasable(purchasable);
  const { variant, sku, maxQuantity, customAttributes } = item;

  // The error cases should be guarded from by the UI
  return async () => {
    // ERROR: if SKU is not in Shopify or could nto be matched we cannot sell this item
    if (!variant?.shopifyId) {
      dispatch(
        showGlobalError({
          message: t('thereWasAnIssue'),
        }),
      );
      throw new Error(
        `FailedAddToCart:NoShopifyID:SKU:${String(
          sku,
        )}:Variant:(${JSON.stringify(variant)})`,
      );
    }
    // ERROR: if maxQuantity is not loaded we don't have the latest quantity
    if (maxQuantity === undefined) {
      dispatch(
        showGlobalError({
          message: t('error.couldNotAdd'),
        }),
      );
      throw new Error(`FailedAddToCart:QuantityNotLoaded:SKU:${String(sku)}`);
    }
    // ERROR: prevents overselling
    if (quantity > maxQuantity) {
      dispatch(
        showGlobalError({
          message: t('cannotPurchaseMore', { maxQuantity }),
        }),
      );
      throw new Error(
        `FailedAddToCart:MaxQuantityCap::SKU:${String(
          sku,
        )}:(${quantity} > ${maxQuantity})`,
      );
    }

    return dispatch(
      addItemToCart({
        variantId: variant?.shopifyId || '',
        quantity,
        customAttributes,
        item: purchasable?.item,
      }),
    );
  };
};

export const useItemSwap = (pack: Optional<Purchasable>, quantity = 1) => {
  const dispatch = useReduxDispatch();
  const packItem = getLineItemFromPurchasable(pack);
  const packSkus =
    pack && 'packItems' in pack
      ? pack?.packItems?.map((item) => item?.sku)
      : [];
  const cart = useSelector((state: Store) => state.checkout);

  return async () => {
    // Create new cart line items, based on the existing cart.
    const lineItems: ShopifyStorefront.CheckoutLineItemInput[] =
      cart?.lineItems
        .map((item) => {
          // If the item is being replaced by the pack upsell, adjust its
          // quantity. Note: the cart item is matched to the selected pack's
          // items based on the unique SKU of the item.
          const skuOccurances = packSkus?.filter(
            (sku) => sku === item.variant?.sku || sku === "'",
          ).length;
          const quantity =
            skuOccurances !== 0 ? item.quantity - skuOccurances : item.quantity;
          return {
            variantId: item.variant!.id,
            quantity,
            customAttributes: item.customAttributes.map((attr) => ({
              key: attr.key,
              value: attr.value || '',
            })),
          };
        })
        .filter((item) => item.quantity > 0) || [];

    const { variant, customAttributes } = packItem;
    lineItems.push({
      variantId: variant?.shopifyId || '',
      quantity: quantity,
      customAttributes,
    });
    trackAddToCart(variant, quantity, false, true, customAttributes);
    return dispatch(replaceCartLineItems(lineItems));
  };
};
