import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  Alerts,
  getFeaturedMediaFromSelection,
  Optional,
  ProductHeader,
} from '@whoop/web-components';
import { BasicComponentProps } from '@whoop/web-components/dist/types';
import { useDiscountMessage, usePriceTag } from '../../utils/priceUtils';
import {
  useProductNode,
  useProductNodes,
} from '../../hooks/staticProductHooks';
import { useItemSwap, usePurchasablePack } from '../../utils/purchasableUtils';
import { findFirstProductItem } from '../../utils/productUtils';
import { useShopifyProductData } from '../../utils/shopifyProductUtils';
import {
  useCartItemCount,
  useFreeRewardStatus,
  useHasSkuInCart,
  useIsCartLoaded,
  useIsCartMutating,
  useIsEligibleForProCheckout,
} from '../../redux/hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  trackAddToCart,
  trackViewCart,
  trackWhoopAnalyticsEvent,
} from '../../utils/analytics';

import { Upsell, useGetUpsell } from '../../hooks/useCartUpsell';
import { ShopifyStorefront } from '../../types/shopify-storefront';
import styles from './cartUpsell.module.scss';
import {
  useOpenUpsellModal,
  useToggleCart,
  useToggleUpsellModal,
} from '../../redux/action-hooks';
import usePackData from '../../hooks/usePackData';
import { quantity } from '../FullCart/full-cart.module.scss';
import { useSelector } from 'react-redux';
import { Store } from '../../types';

function useHasWhoopProUpsells() {
  const freeRewardStatus = useFreeRewardStatus();
  const hasItems = (useCartItemCount() || 0) > 0;
  const isEligibleForProCheckout = useIsEligibleForProCheckout();
  return (
    freeRewardStatus !== 'ineligible' || (hasItems && isEligibleForProCheckout)
  );
}

function useShowUpsell(sku?: string) {
  const hasOtherUpsells = useHasWhoopProUpsells();
  const isCartLoaded = useIsCartLoaded();
  const isCartMutating = useIsCartMutating();
  const hasItemInCart = useHasSkuInCart(sku);
  return isCartLoaded && !isCartMutating && !hasOtherUpsells && !hasItemInCart;
}

interface CartUpsellProps extends BasicComponentProps {
  lineItems: ShopifyStorefront.CheckoutLineItem[];
}

export default function CartUpsellV2({
  lineItems,
  ...props
}: CartUpsellProps): JSX.Element | null {
  const cartHandles: string[] = [];
  const cartSkus: Optional<string>[] = [];
  lineItems.forEach((item) => {
    const handle: Optional<string> = item?.variant?.product?.handle;
    if (handle) {
      cartHandles.push(handle);
      cartSkus.push(item?.variant?.sku);
      if (item?.quantity > 1) {
        // if a line item have multiple quantity we want to make sure that gets reflected in array
        for (let i = 1; i < item?.quantity; i++) {
          cartHandles.push(handle);
          cartSkus.push(item?.variant?.sku);
        }
      }
    }
  });

  const packData = usePackData();
  const upsell: Upsell = useGetUpsell(cartHandles, packData);
  const { t } = useTranslation('cart');
  const node = useProductNode(upsell.packHandle);
  const packItems = useProductNodes(
    node?.product_info?.pack_details?.pack_items || [],
  ).map((n) => findFirstProductItem(n));
  const purchasable = usePurchasablePack(node, packItems);
  const item = useMemo(() => node && findFirstProductItem(node), [node]);
  const itemSwap = useItemSwap(purchasable);
  const { variant, tags } = useShopifyProductData(item?.sku);
  const priceTag = usePriceTag(tags, variant, purchasable);
  const image = item ? getFeaturedMediaFromSelection(item, node) : undefined;
  const showUpsell = useShowUpsell(item?.sku);
  const discountMessage = useDiscountMessage(variant, tags);
  const toggleCart = useToggleCart();
  const setUpsellData = useOpenUpsellModal({
    node: node,
    priceTag: priceTag,
    cartSkus: cartSkus,
  });
  const toggleUpsellModal = useToggleUpsellModal();

  // analytics with upsell included
  const showCart = useSelector((state: Store) => state.showCart);
  const checkout = useSelector((state: Store) => state.checkout);
  useEffect(() => {
    if (showCart && checkout) {
      trackViewCart(window.location.pathname, checkout, upsell);
    }
  }, [showCart, checkout]);

  const onAddToCart = () => {
    trackWhoopAnalyticsEvent('Clicked Pack Upsell', upsell);
    trackAddToCart(variant, quantity, false, true);
    itemSwap();
  };

  if (!showUpsell) {
    return null;
  }
  return (
    <Alert
      type={Alerts.NEUTRAL}
      data-test-id='upsell-header'
      actionIcon='add'
      image={image?.url}
      bannerLabel={
        upsell && upsell.packHandle !== 'battery-pack'
          ? t('saveOnPack', { percentage: discountMessage })
          : ''
      }
      bannerClassName={styles.bannerHeader}
      onClick={
        upsell && upsell.isStatic
          ? onAddToCart
          : () => {
              toggleCart();
              setUpsellData();
              toggleUpsellModal();
              trackWhoopAnalyticsEvent('Clicked Pack Upsell', upsell);
            }
      }
      {...props}
    >
      <ProductHeader
        title={
          upsell && upsell.packHandle === 'battery-pack'
            ? t('additionalBatteryPack')
            : node.product_info.title
        }
        price={priceTag.price}
        originalPrice={priceTag.originalPrice}
        isProDiscount={priceTag.isProDiscount}
        size='small'
      />
    </Alert>
  );
}
