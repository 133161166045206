import React, { useState } from 'react';
import { classes } from '../../utils';
import styles from './accordion.module.scss';

export interface Page {
  id: string;
  title: string;
  body: string;
}
type AccordionProps = {
  pages?: Page[];
  className?: string;
  size?: 'small' | 'large';
  onClick?: (page: Page) => void;
};

/**
 * Component to display pages associated with a product
 */

export default function Accordion(props: AccordionProps): JSX.Element {
  const { pages, className, size, onClick, ...rest } = props;

  const [openPageId, setOpenPageId] = useState<string>();

  function isPageOpen(pageId: string) {
    return openPageId === pageId;
  }

  function toggleOpenPage(page: Page) {
    if (openPageId === page.id) {
      setOpenPageId(undefined);
    } else {
      setOpenPageId(page.id);
    }
    onClick && onClick(page);
  }

  return (
    <div
      className={classes(styles.accordion, styles[size || 'small'], className)}
      {...rest}
    >
      <div className={styles.productTabs}>
        {pages?.map((page: Page) => (
          <div className={styles.collapsible} key={page.id}>
            <span
              className={styles.collapsibleButton}
              onClick={() => toggleOpenPage(page)}
            >
              {page.title}
              <span
                className='whoop-collapsible-plus'
                aria-expanded={isPageOpen(page.id)}
              />
            </span>
            <div
              className={classes(
                styles.collapsibleInner,
                isPageOpen(page.id) && styles.expanded,
              )}
            >
              <div
                className={styles.collapsibleContent}
                dangerouslySetInnerHTML={{
                  __html: page.body ?? '',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
