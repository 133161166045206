import React, { CSSProperties, useState } from 'react';
import styles from './info-button.module.scss';
import { Icon } from '@whoop/web-components';
import { classes } from '../../utils';

export default function InfoButton({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [show, setShow] = useState<boolean>();
  const [style, setStyle] = useState<CSSProperties>();

  const onIconClick = (event: MouseEvent) => {
    if (show) {
      setShow(false);
    } else {
      const element: Element = event.currentTarget as Element;
      const rect = element.getBoundingClientRect();
      setStyle({ top: rect.y + rect.height + 8 });
      setShow(true);
    }
  };

  return (
    <>
      <Icon
        className={styles.infoIcon}
        name='education'
        onClick={onIconClick}
        data-test-id='info-button'
      />
      <div
        className='whoop-focus-background'
        style={{ zIndex: 2000 }}
        data-showing={show}
        onClick={() => setShow(false)}
      />
      <div
        className={classes(styles.infoBox, !show && styles.hidden)}
        onClick={() => setShow(false)}
        style={style}
        data-test-id='info-button-content'
      >
        {children}
      </div>
    </>
  );
}
