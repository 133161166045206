import { ShopifyStorefront } from '../types/shopify-storefront';
import { ProductItem } from '@whoop/web-components';
import { useStaticProductNodes } from './staticProductHooks';
import { findProductItemBySku } from '../utils/productUtils';
import { nonNull } from '../utils';

function useShopifyCheckoutItemMap(
  checkout: ShopifyStorefront.Checkout | undefined,
): {
  [sku: string]: ProductItem;
} {
  const cartItemsBySku: { [sku: string]: ProductItem } = {};
  const handles = checkout?.lineItems
    .map((line) => line.variant?.product.handle)
    .filter(nonNull);
  const nodes = useStaticProductNodes(handles || []);

  checkout?.lineItems.forEach((line) => {
    if (line.variant?.product.handle && line.variant?.sku) {
      const node = nodes.find(
        (n) => n.product_info.handle === line.variant?.product.handle,
      );
      const item = node
        ? findProductItemBySku(line.variant?.sku, node)
        : undefined;
      if (item) {
        cartItemsBySku[line.variant.sku] = item;
      }
    }
  });

  return cartItemsBySku;
}

export default useShopifyCheckoutItemMap;
