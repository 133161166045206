import React, { useRef } from 'react';
import styles from './okendo-reviews-widget.module.scss';
import { Optional } from '@whoop/web-components/dist/types';
import { useIsOnForAll } from '../../utils/feature-flags';
import { classes } from '../../utils';
import useOkendoReviews from '../../hooks/okendoHooks';
interface OkendoReviewsWidgetProps extends React.HTMLAttributes<HTMLElement> {
  productId: Optional<string> | undefined;
  className?: string;
}

const OkendoReviewsWidget = ({
  productId,
  className,
  ...props
}: OkendoReviewsWidgetProps): JSX.Element => {
  const widgetContainer = useRef(null);
  const showWriteReviewButton = useIsOnForAll('storefront-show-write-review');
  const reviewCount = useOkendoReviews(widgetContainer);

  return (
    <section
      className={classes(
        styles.productReviews,
        !!showWriteReviewButton && styles.showReviewButton,
        reviewCount < 1 && styles.noReviews,
        className,
      )}
      id='add-review'
      {...props}
    >
      <h2 data-impress='Viewed Reviews'>Reviews</h2>
      <div
        ref={widgetContainer}
        data-oke-widget
        data-oke-reviews-product-id={`shopify-${productId ?? ''}`}
      ></div>
    </section>
  );
};

export default OkendoReviewsWidget;
