import { parseShopifyId } from './shopify';
import { handleize } from './index';
import { isNil } from 'lodash';
import store from '../redux/create-store';
import { BILLING_REGION } from './regions';
import { isFreeCheckoutItem } from './products';
import { formatPrice } from './priceUtils';
import { sendSentryError } from './wrapped-sentry';
import whoopAnalytics from '@whoop/web-analytics';

export const CHECKOUT_FROM_CART = 'cart';
export const CHECKOUT_FROM_BUY_IT_NOW = 'buyItNow';

const parseProductMetadata = function (variant) {
  // GUARD: Return an empty object if there is no variant to parse metadata from
  if (!variant) {
    return {};
  }

  // We only want (name,value) for options, and not any other attributes that might come in as part of the
  // GraphQL data.
  const selectedOptions = variant.selectedOptions
    ? variant.selectedOptions.map(({ name, value }) => ({ name, value }))
    : [];
  const metadata = {
    product_handle: variant.product?.handle,
    item_name: variant.product?.title,
    variant_name: variant.title,
    options_selected: selectedOptions,
    price: variant.price,
  };

  // Most product variants will have an SKU. In the rare case where the variant does not,
  // use the Shopify ID instead.
  if (variant.sku) {
    metadata.sku = variant.sku;
  } else {
    metadata.product_id = parseShopifyId(variant.product?.shopifyId);
  }

  // Only add the compare-at price for variants that have one set
  if (variant.compareAtPrice) {
    metadata.compare_at_price = variant.compareAtPrice;
  }

  return metadata;
};

// The item from the Shopify Checkout is not quite identical to a variant. The product
// title is at the item level, rather than included as part of the variant.product data.
// This function transforms the item into a variant object, in the format that the
// standard parseProductMetadata() logic expects.
//
// Note: include the handle and shopifyId in the spread for the product data, to avoid
// losing them during the transformation.
function itemToVariant(item) {
  return {
    ...item.variant,
    ...{
      product: {
        title: item.title,
        handle: item.variant?.product?.handle,
        shopifyId: item.variant?.product?.shopifyId,
      },
    },
  };
}

function getItemDetails(checkout, checkoutItemMap) {
  return checkout && checkout.lineItems
    ? checkout.lineItems.map((item) => {
        const productItem = checkoutItemMap
          ? checkoutItemMap[item.variant.sku]
          : undefined;
        return {
          ...parseProductMetadata(itemToVariant(item)),
          count: item.quantity,
          is_free_item: isFreeCheckoutItem(item),
          is_sale: productItem?.on_sale,
          is_new: productItem?.new,
          is_exclusive: productItem?.pro_exclusive,
        };
      })
    : [];
}

// Amplitude Utils

const ampSetUserId = function (userId) {
  if (
    process.env.NODE_ENV === 'production' &&
    typeof window.amplitude !== 'undefined'
  ) {
    try {
      window.amplitude.getInstance().setUserId(userId);
    } catch (e) {
      // Ad blockers may cause 'window.amplitude' to exist, but still end up throwing an error.
      // Catch the error to avoid blocking the user, and log to the console so Datadog can report
      // back to us about it.
      console.error(e);
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('ampSetUserId', userId);
  }
};

const ampLogEvent = function (eventName, eventProperties) {
  if (!eventProperties) {
    eventProperties = {};
  }
  eventProperties.region = process.env.SHOP_REGION;

  if (store) {
    const state = store.getState();
    if (state) {
      eventProperties.is_logged_in = !!state.user?.isLoggedIn;
      eventProperties.is_employee = !!state.user?.isEmployee;
      if (state.whoopProStatus && state.whoopProStatus.status) {
        const status = state.whoopProStatus.status;
        eventProperties.is_whoop_pro =
          status === 'active' || status === 'expiring' || status === 'pending';
      } else {
        eventProperties.is_whoop_pro = false;
      }
      const user = state.user;
      if (user) {
        eventProperties.is_in_own_billing_region =
          user.billingRegion === BILLING_REGION;
        eventProperties.whoop_pro_prorated_months = user.upgradeProRatedMonths;
        eventProperties.featureFlags = user.featureFlags;
      }
    }
  }

  if (window && window.screen) {
    eventProperties.width = window.screen.width;
    eventProperties.height = window.screen.height;
  }

  if (
    process.env.NODE_ENV === 'production' &&
    typeof window.amplitude === 'object'
  ) {
    try {
      window.amplitude.getInstance().logEvent(eventName, eventProperties);
    } catch (e) {
      // Ad blockers may cause 'window.amplitude' to exist, but still end up throwing an error.
      // Catch the error to avoid blocking the user, and log to the console so Datadog can report
      // back to us about it.
      console.error(e);
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('ampLogEvent', eventName, eventProperties);
  }
};

export const ampSetUserProperty = function (key, value) {
  if (
    process.env.NODE_ENV === 'production' &&
    typeof window.amplitude === 'object'
  ) {
    try {
      const identify = new window.amplitude.Identify().set(key, value);
      window.amplitude.getInstance().identify(identify);
    } catch (e) {
      // Ad blockers may cause 'window.amplitude' to exist, but still end up throwing an error.
      // Catch the error to avoid blocking the user, and log to the console so Datadog can report
      // back to us about it.
      console.error(e);
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('ampSetUserProperty', key, value);
  }
};

// Klaviyo Utils
const queueKlaviyoTask = function (task) {
  window._learnq = window._learnq || [];
  window._learnq.push(task);
};

export const klaviyoTrackViewedProductPage = function (product) {
  const item = {
    Name: product?.title,
    ProductID: parseShopifyId(product?.shopifyId),
    ImageURL: product?.images?.[0]?.originalSrc,
    URL: `https://shop.whoop.com/products/${product?.handle}`,
    Brand: 'WHOOP',
    Price: formatPrice(product?.priceRangeV2?.minVariantPrice?.amount),
  };
  queueKlaviyoTask(['track', 'Viewed Product', item]);
  queueKlaviyoTask([
    'trackViewedItem',
    {
      Title: item.Name,
      ItemId: item.ProductID,
      ImageUrl: item.ImageURL,
      Url: item.URL,
      Metadata: {
        Brand: item.Brand,
        Price: item.Price,
      },
    },
  ]);
};

export const klaviyoTrackViewedChildProduct = function (
  product,
  priceTag,
  imageUrl,
) {
  const item = {
    Name: product?.title,
    ProductID: parseShopifyId(product?.shopifyId),
    ImageURL: imageUrl,
    URL: `https://shop.whoop.com/products/${product?.handle}`,
    Brand: 'WHOOP',
    Price: formatPrice(priceTag.price),
  };
  queueKlaviyoTask(['track', 'Viewed Product', item]);
  queueKlaviyoTask([
    'trackViewedItem',
    {
      Title: item.Name,
      ImageUrl: item.ImageURL,
      Url: item.URL,
      Metadata: {
        Brand: item.Brand,
        Price: item.Price,
      },
    },
  ]);
};

export const segmentTrackSubscribedToOOS = async function (
  productTitle,
  itemTitle,
  email,
  sku,
  price,
  imageUrl,
  shopUrl,
  eventType,
) {
  if (process.env.SEGMENT_KEY) {
    whoopAnalytics.trackSegmentEvent('Email me When Back in Stock', {
      env: process.env.NODE_ENV,
      region: process.env.SHOP_REGION,
      productTitle,
      itemTitle,
      email,
      sku,
      price,
      imageUrl,
      shopUrl,
      eventType,
    });
  } else {
    // eslint-disable-next-line no-console
    console.log('SegmentTrackSubscribedToOOS()', {
      productTitle,
      itemTitle,
      email,
      sku,
      region: process.env.SHOP_REGION,
      eventType,
    });
  }

  return 0;
};

// Datdog Utils
const addDatadogRumContext = function (key, value) {
  setTimeout(() => {
    if (window.DD_RUM) {
      window.DD_RUM.addRumGlobalContext(key, value);
    } else {
      // eslint-disable-next-line no-console
      console.log('datadogRumContext', key, value);
    }
  }, 800);
};

const datadogTrackViewedPage = function () {
  if (window && window.location) {
    const url = new URL(window.location);
    if (url.search) {
      const urlParams = new URLSearchParams(url.search);
      if (urlParams) {
        urlParams.forEach((value, key) => {
          if (key !== 'token') {
            addDatadogRumContext(`query.${key}`, value);
          }
        });
      }
    }
  }
};

// Events
export const identifyUser = function (user) {
  if (user) {
    queueKlaviyoTask([
      'identify',
      {
        $email: user.email,
        $first_name: user.firstName,
        $last_name: user.lastName,
        WhoopUserId: user.id,
      },
    ]);
    window.DD_RUM &&
      window.DD_RUM.setUser({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    ampSetUserId(user.id);
    ampLogEvent('[Shop] Logged In', {});
  }
};

export const trackClientEntry = function () {
  addDatadogRumContext('site_region', process.env.SHOP_REGION);
  addDatadogRumContext('feature_branch', process.env.FEATURE_BRANCH);

  ampSetUserProperty('Site Region', process.env.SHOP_REGION);
  ampSetUserProperty('Feature Branch', process.env.FEATURE_BRANCH);
};

export const trackViewedPage = function (location, prevLocation) {
  datadogTrackViewedPage(location, prevLocation);
};

const parseItemMetadata = (item) => {
  if (!item) {
    return {};
  }

  return {
    is_sale: item.on_sale,
    is_new: item.new,
    is_exclusive: item.pro_exclusive,
  };
};

export const trackAddToCart = function (
  variant,
  quantity,
  is3d,
  isUpsell,
  customAttributes,
  item,
) {
  const eventName = '[Shop] Added Accessory to Cart';
  const payload = {
    count: quantity,
    ...parseProductMetadata(variant),
    ...parseItemMetadata(item),
  };
  if (is3d !== undefined) {
    payload.is_3d = is3d;
  }
  if (isUpsell === undefined) {
    payload.is_upsell = false;
  } else {
    payload.is_upsell = isUpsell;
  }
  customAttributes?.forEach(({ key, value }) => {
    payload[handleize(key)?.replace(/-/g, '_')] = value;
  });
  ampLogEvent(eventName, payload);
  try {
    window.fbq('track', 'AddToCart', { sku: payload.sku || 'unknown' });
  } catch (e) {
    console.error(e);
  }
};

export const trackViewCart = function (page, checkout, upsell) {
  const itemDetails = getItemDetails(checkout);
  const eventName = '[Shop] Viewed Cart';
  let itemCount = 0;
  itemDetails.forEach((item) => {
    itemCount += item.count;
  });

  const payload = {
    previous_page: page,
    total_value: checkout.subtotalPrice,
    item_count: itemCount,
    items: itemDetails,
    upsell: upsell || false,
  };
  ampLogEvent(eventName, payload);
};

export const trackCheckout = function (
  checkout,
  checkoutItemMap,
  source,
  loggedIn,
) {
  const itemDetails = getItemDetails(checkout, checkoutItemMap);
  const eventName =
    source === CHECKOUT_FROM_BUY_IT_NOW
      ? '[Shop] Clicked "Buy It Now"'
      : '[Shop] Clicked Checkout';
  let itemCount = 0;
  itemDetails.forEach((item) => {
    itemCount += item.count;
  });
  const payload = {
    total_value: checkout.subtotalPrice,
    item_count: itemCount,
    items: itemDetails,
    logged_in: loggedIn,
  };
  ampLogEvent(eventName, payload);
};

export const trackLogout = function () {
  ampSetUserId(null);
  ampLogEvent('[Shop] Logged Out', {});
};

export const trackRedirectToAssignedBillingRegion = ({ from, to }) => {
  ampLogEvent('[Shop] Redirecting to Assigned Billing Region', {
    initialShopRegion: from,
    redirectedToShopRegion: to,
  });
};

export const trackRemoveFromCart = function (variant) {
  if (variant) {
    const eventName = '[Shop] Item Removed From Cart';
    const payload = { ...parseProductMetadata(variant) };
    ampLogEvent(eventName, payload);
  }
};

export const trackChangeQuantity = function (
  variant,
  previousQuantity,
  resultingQuantity,
) {
  if (variant) {
    const eventName = '[Shop] Changed Quantity From Cart';
    const payload = {
      ...parseProductMetadata(variant),
      previous_quantity: previousQuantity,
      resulting_quantity: resultingQuantity,
    };
    ampLogEvent(eventName, payload);
  }
};

export const trackFilteredCollection = function (
  collection,
  filters,
  resultCount,
) {
  const eventName = '[Shop] Filtered View';
  const payload = {
    collection,
    filters,
    number_results: resultCount,
  };
  ampLogEvent(eventName, payload);
};

export const trackWhoopProModalOpened = function (
  location,
  source,
  fromCart = false,
) {
  const eventName = '[Shop] Viewed WHOOP Pro Modal';
  const payload = {
    location: location,
    source,
    from_cart: fromCart,
  };
  ampLogEvent(eventName, payload);
};

export const stripToken = function (url) {
  const index = url.indexOf('?');
  if (index === -1) {
    // No query parameters in URL, so no token to strip out
    return url;
  } else {
    const location = url.slice(0, index);
    const query = url
      .slice(index + 1)
      .split('&')
      .filter((p) => !p.startsWith('token'))
      .join('&');
    return location + '?' + query;
  }
};

export const trackWhoopProCta = function (
  destination,
  user,
  whoopProStatus,
  source,
) {
  const eventName = '[Shop] Clicked WHOOP Pro CTA';
  const payload = {
    destination: stripToken(destination),
    source,
  };
  if (user) {
    payload.strap = user.strap || 'none';
    if (!isNil(user.couldUpgradeForFree)) {
      payload.could_upgrade_for_free = !!user.couldUpgradeForFree;
    }
    if (!isNil(user.upgradeRequiresAnnualPlan)) {
      payload.requires_annual_plan = !!user.upgradeRequiresAnnualPlan;
    }
    if (!isNil(user.upgradeProRatedMonths)) {
      payload.prorated_months = user.upgradeProRatedMonths;
    }
  }
  if (whoopProStatus && whoopProStatus.status) {
    payload.whoop_pro_status = whoopProStatus.status;
  }
  ampLogEvent(eventName, payload);
};

// Track current state of 2d vs 3d render when WYW page initially loads
export const trackWywPageView = function (is3d) {
  const eventName = '[Shop] View WYW';
  const payload = {
    is_3d: is3d,
  };
  ampLogEvent(eventName, payload);
};

// Track when user clicks on the 2d/3d toggle buttons
export const trackWywToggle = function (is3d) {
  const eventName = '[Shop] Toggle WYW Render';
  const payload = {
    is_3d: is3d,
  };
  ampLogEvent(eventName, payload);
};

// Track when user clicks on size modal
export const trackOpenedSizeGuide = function (variant) {
  const eventName = '[Shop] Opened Size Guide';
  const payload = parseProductMetadata(variant);
  ampLogEvent(eventName, payload);
};

// Track when user starts video playback on a PDP page
export const trackVideoPlay = function (variant, index) {
  const eventName = '[Shop] Started Product Video';
  const payload = {
    video_index: index,
    ...parseProductMetadata(variant),
  };
  ampLogEvent(eventName, payload);
};

const JSON_PREFIX = 'json-';
const REDUX_PREFIX = 'redux-';

const getObjectProperty = (object, path) => {
  if (path === undefined || path === null) {
    return object;
  }
  const parts = path.split('.');
  return parts.reduce((object, key) => object?.[key], object);
};

// Pass events from the @whoop/web-analytics tracker on to Amplitude
export const trackWhoopAnalyticsEvent = function (
  eventName,
  context = {},
  trigger = { type: 'click' },
) {
  const prefixedEventName = `[Shop] ${eventName}`;

  // Opinionated data transformations:
  // 1. If the context attribute name starts with "json-", assume that the attribute
  //    value is an object encoded as a string using JSON.stringify(). Parse the JSON
  //    data out, and store in the payload as an attribute with the "json-" prefix removed.
  // 2. If the context attribute name starts with "redux-", assume that the attribute
  //    is a dot-separated path into the Redux State. Extract the data from Redux
  //    and if the data is not undefined add it to the payload as an attribute with the
  //    "redux- prefix removed.
  const data = Object.entries(context).reduce((acc, [name, value]) => {
    if (name.startsWith(JSON_PREFIX)) {
      const key = name.slice(JSON_PREFIX.length);
      try {
        const data = JSON.parse(value);
        // Special case for 'variant': add the standard parseProductMetadata() event attributes
        if (key === 'variant') {
          acc = { ...acc, ...parseProductMetadata(data) };
        } else {
          acc[key] = data;
        }
      } catch (e) {
        acc[key] = value;
      }
    } else if (name.startsWith(REDUX_PREFIX)) {
      const key = name.slice(REDUX_PREFIX.length);
      const data = getObjectProperty(store?.getState(), value);
      if (data !== undefined) {
        acc[key] = data;
      }
    } else {
      acc[name] = value;
    }

    return acc;
  }, {});

  if (!data.location || !data.pathname) {
    // Add current location & path
    const location = window.location.href;
    const url = new URL(location);
    data.location = `${url.pathname}${url.search}`;
    data.pathname = url.pathname;
  }

  const payload = {
    trigger: trigger?.type,
    ...data,
  };

  if (trigger.type === 'routechange') {
    datadogTrackViewedPage(context.location);
  }
  ampLogEvent(prefixedEventName, payload);
};

export const withTracking = (trackFn, setFn) => (value) => {
  const { eventName, context } = trackFn(value);
  trackWhoopAnalyticsEvent(eventName, context);
  return setFn && setFn(value);
};

export const trackToggleFreeItem = (isChecked, item) => {
  const eventName = '[Shop] Click WP Free Item Radio Button';
  let payload;
  if (isChecked && item) {
    payload = {
      checked: true,
      ...parseProductMetadata(itemToVariant(item)),
      is_free_item: isFreeCheckoutItem(item),
    };
  } else {
    payload = {
      checked: false,
    };
  }

  ampLogEvent(eventName, payload);
};

export const trackGenToggleClicked = (value) => {
  const eventName = '[Shop] Generation Toggle Clicked';
  const payload = {
    selection: value,
  };

  ampLogEvent(eventName, payload);
};

export const trackError = (error, context) => {
  if (window.DD_RUM) {
    window.DD_RUM.addError(error, context);
  }
  sendSentryError(error);
};
