import React from 'react';
import FullSearch from '../components/FullSearch';
import { graphql } from 'gatsby';

const Search = ({ location }) => {
  return <FullSearch location={location} />;
};

export default Search;

export const translationQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
