import { startExperiment } from '../utils/experiments';
import { useIsOnForAll } from '../utils/feature-flags';
import { useEffect, useState } from 'react';

export enum PDPRedesignVariant {
  'with_sticky_pdp' = 'with_sticky_pdp',
  'no_sticky_pdp' = 'no_sticky_pdp',
}

export enum ProductCardRedesignVariant {
  'with_large_product_card' = 'with_large_product_card',
  'no_large_product_card' = 'no_large_product_card',
}

export function usePdpRedesign(): PDPRedesignVariant | undefined {
  const [variant, setVariant] = useState<PDPRedesignVariant>();
  const featureIsOn = useIsOnForAll('storefront-pdp-redesign');

  useEffect(() => {
    if (!variant && featureIsOn !== undefined) {
      if (featureIsOn) {
        setVariant(
          startExperiment('experiment_pdp_redesign') as PDPRedesignVariant,
        );
      }
    }
  }, [featureIsOn]);

  return variant;
}

export function useProductCardRedesign():
  | ProductCardRedesignVariant
  | undefined {
  const [variant, setVariant] = useState<ProductCardRedesignVariant>();
  const featureIsOn = useIsOnForAll('storefront-product-card-redesign');

  useEffect(() => {
    if (!variant && featureIsOn !== undefined) {
      if (featureIsOn) {
        setVariant(
          startExperiment(
            'experiment_product_card_redesign',
          ) as ProductCardRedesignVariant,
        );
      }
    }
  }, [featureIsOn]);

  return variant;
}
