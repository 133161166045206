/* eslint-disable @typescript-eslint/dot-notation */
import React, { useMemo } from 'react';
import styles from './line-item.module.scss';
import {
  Alert,
  Alerts,
  Badge,
  BadgeColors,
  LayeredImage,
  NumberInput,
} from '@whoop/web-components';
import { classes } from '../../utils';
import Price from '../Price';
import { ShopifyStorefront } from '../../types/shopify-storefront';
import {
  is3for2Sale,
  isNewProduct,
  itemToLineItemProps,
} from '../../utils/products';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  formatPrice,
  generatePriceTag,
  useIsDiscounted,
} from '../../utils/priceUtils';
import { useShopifyProductData } from '../../utils/shopifyProductUtils';
import { useWhoopYourWayImages } from '../../utils/whoopYourWayUtils';
import { isPack } from '../../utils/productUtils';
import { useIsEmployee } from '../../redux/hooks';
import { useIsOnForAll } from '../../utils/feature-flags';

export type LineItemProps = {
  item: ShopifyStorefront.CheckoutLineItem;
  disabled?: boolean;
  onQuantityChange?: (...args: any[]) => any;
  slim?: boolean;
  onRemove: () => void;
  message?: string;
  isWarning?: boolean;
  isExclusive?: boolean;
  hideQuantity?: boolean;
  alertLines?: string[];
  isWyw?: boolean;
};

const LineItem: React.FunctionComponent<LineItemProps> = ({
  item,
  onQuantityChange,
  onRemove,
  disabled,
  slim,
  message,
  isWarning,
  isExclusive,
  hideQuantity,
  isWyw,
  alertLines,
  ...props
}) => {
  const { t } = useTranslation(['cart', 'price', 'product']);
  const isEmployee = useIsEmployee();
  const transformedProps = itemToLineItemProps(item);
  const {
    title,
    variantTitle,
    imageSrc,
    productUrl,
    quantity,
    discountedTotal,
    originalTotal,
    discountedUnitPrice,
    originalUnitPrice,
    customAttributes = [],
    discounts = [],
    isProDiscount,
  } = transformedProps;
  const { variant, tags } = useShopifyProductData(item?.variant?.sku);
  const isNew = isNewProduct(tags);
  const isOnSale =
    useIsDiscounted(variant, tags || []) && !isPack(item?.variant?.sku);
  let badge: string | undefined;
  let badgeColor = BadgeColors.BLACK;
  const is3for2SaleOnForAll = useIsOnForAll('storefront-3for2-sale');
  const isOn3for2Sale = is3for2Sale(tags) && is3for2SaleOnForAll;
  if (isExclusive) {
    badge = t('exclusive');
    badgeColor = BadgeColors.PRO;
  } else if (isOn3for2Sale) {
    badge = t('sale');
  } else if (isOnSale) {
    badge = t('onSale');
  } else if (isNew) {
    badge = t('new');
  }

  const disclaimer =
    isEmployee && !isProDiscount ? t('notEligible') : undefined;

  // memoized WYW sku (or undefined if this is not WYW)
  const wywSku = useMemo(
    () =>
      isWyw
        ? customAttributes?.find(({ key }) =>
            key.toLowerCase().startsWith('sku'),
          )?.value
        : undefined,
    [isWyw, customAttributes],
  );
  // if this is a WYW item
  const wywImages = useWhoopYourWayImages(wywSku);

  const discountTags = discounts.map(({ title, amount }) => (
    <li key={`${title}${amount}`}>
      {title}: - {formatPrice(amount)}
    </li>
  ));

  // For WYW, Packs, etc we need to send SKUs to the back-end, but do not want to
  // show these to the user. Filter out any properties whose key starts with 'sku'.
  //
  // Additionally, the custom attributes retrieved as part of the cart items do not
  // preserve the order of the attributes as initially specified. To enforce consistency,
  // sort the items by the name of the key.
  const customAttributeListItems = customAttributes
    .filter(({ key }) => !key.toLowerCase().startsWith('sku'))
    .sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()))
    .map(({ key, value }) => (
      <li key={key}>
        {key}: {value}
      </li>
    ));

  const totalPriceTag = isProDiscount
    ? generatePriceTag(
        originalTotal,
        null,
        null,
        discountedTotal,
        true,
        t('free'),
      )
    : generatePriceTag(
        discountedTotal,
        originalTotal,
        null,
        originalTotal,
        isExclusive,
        t('free'),
      );

  const priceTag = hideQuantity
    ? totalPriceTag
    : isProDiscount
    ? generatePriceTag(
        originalUnitPrice,
        null,
        null,
        discountedUnitPrice,
        true,
        t('free'),
      )
    : generatePriceTag(
        discountedUnitPrice,
        originalUnitPrice,
        null,
        originalUnitPrice,
        isExclusive,
        t('free'),
      );

  return (
    <>
      {alertLines && alertLines.length > 0 && (
        <Alert type={Alerts.INFO} className={styles.alert} iconName='education'>
          {alertLines[0]}
          {alertLines.length > 1 && (
            <>
              <br />
              <span>{alertLines.slice(1).join(' ')}</span>
            </>
          )}
        </Alert>
      )}
      <div
        className={classes(
          styles.fullCartItem,
          slim && styles.slim,
          alertLines && alertLines.length > 0 && styles.withAlert,
        )}
        data-test-id='cart-line-item'
        {...props}
      >
        <div className={styles.thumb}>
          {badge && slim && <Badge color={badgeColor}>{badge}</Badge>}
          <Link to={productUrl || ''}>
            {isWyw ? (
              <LayeredImage>
                {wywImages?.map((url) => (
                  <img key={url} src={url} alt={title} />
                ))}
              </LayeredImage>
            ) : (
              imageSrc && <img src={imageSrc} alt={title} />
            )}
          </Link>
        </div>

        <div className={styles.info}>
          {badge && !slim && <Badge color={badgeColor}>{badge}</Badge>}
          <h4 className={styles.title} data-test-id='product-title'>
            <Link to={productUrl || ''}>{title}</Link>
          </h4>

          <div className={styles.meta}>
            <div className={styles.metaHeader}>
              {variantTitle && (
                <h5 data-test-id='variant-title'>{variantTitle}</h5>
              )}
              <h5
                className={classes(styles.message, isWarning && styles.warn)}
                data-test-id='message'
              >
                {message}
              </h5>
            </div>

            {customAttributeListItems.length > 0 && (
              <ul
                className={styles.customAttributesList}
                data-test-id='custom-attributes'
              >
                {customAttributeListItems}
              </ul>
            )}
            <div className={styles.priceRow}>
              <Price {...priceTag} data-test-id='price' />
              {disclaimer && (
                <h5 className={classes(styles.message, styles.disclaimer)}>
                  {disclaimer}
                </h5>
              )}
            </div>
            <div className={styles.actions}>
              {!hideQuantity && (
                <NumberInput
                  value={quantity}
                  min={0}
                  onChange={onQuantityChange}
                  disabled={disabled}
                  style={{ whiteSpace: 'nowrap' }}
                />
              )}
              <button className={styles.removeBtn} onClick={onRemove}>
                {t('remove')}
              </button>
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          {!hideQuantity && (
            <NumberInput
              value={quantity}
              min={0}
              onChange={onQuantityChange}
              disabled={disabled}
              data-test-id='quantity'
            />
          )}
          <br />
          <button
            className={styles.removeBtn}
            onClick={onRemove}
            data-test-id='remove'
          >
            {t('remove')}
          </button>
        </div>

        <div className={styles.lineTotal}>
          <Price {...totalPriceTag} data-test-id='total-price' />
          {discountTags.length > 0 && !isProDiscount && (
            <ul className={styles.discounts}>{discountTags}</ul>
          )}
        </div>
      </div>
      <hr />
    </>
  );
};

LineItem.defaultProps = {
  slim: false,
  disabled: false,
};

export default LineItem;
