import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import analytics from './middleware/analytics';
import thunk, { ThunkDispatch } from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { configureStore } from '@reduxjs/toolkit';

const preloadedState = {
  mutatingCheckout: false,
  checkout: {
    lineItems: [],
    discountApplications: [],
  },
  showCart: false,
  showSideNav: false,
  upsellModal: {
    isVisisble: false,
    node: {},
    priceTag: {},
  },
  error: null,
  search: {
    results: [],
    query: '',
  },
  user: null,
  whoopProStatus: null,
  whoopProLandingContent: null,
  whoopProClaimReward: false,
  wywAttributes: {},
  wywConfiguration: {},
  emailMeWhen: {
    skusOfInterest: [],
  },
};

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: [thunk, analytics],
  preloadedState,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type ReduxDispatch = ThunkDispatch<RootState, any, Action>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
export type AppDispatch = typeof store.dispatch;
