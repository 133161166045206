import { Optional } from '@whoop/web-components/dist/types';
import { useEffect, useState } from 'react';
import { useIsOnForAll } from './feature-flags';
import { startExperiment } from './experiments';
import { useSelector } from 'react-redux';
import { Store } from '../types';

/**
 * Hook to start an experiment and retrieve it's variant given a feature flag
 * and experiment name.
 *
 * @param experimentName name of the experiment
 * @param featureFlagName feature flag to check if on
 * @param isOn optional boolean to control when the experiment starts
 */
export function useExperimentVariant(
  experimentName: string,
  featureFlagName: string,
  isOn = true,
): Optional<string> {
  const [variant, setVariant] = useState<string | null>(null);
  const isFeatureFlagOn = useIsOnForAll(featureFlagName);

  useEffect(() => {
    if (isFeatureFlagOn && isOn && !variant) {
      setVariant(startExperiment(experimentName));
    }
  }, [isFeatureFlagOn, isOn]);

  return variant;
}
export const useIsPackUpsell = () => {
  const showCart = useSelector((state: Store) => state.showCart);
  const variant = useExperimentVariant(
    'experiment_pack_upsell',
    'storefront-pack-upsell',
    showCart,
  );
  return variant && variant !== 'control';
};

export const useShowIntercom = () => {
  const variant = useExperimentVariant(
    'experiment_live_chat',
    'storefront-live-chat',
    process.env.SHOP_REGION === 'US' || process.env.ENV === 'DEV',
  );
  return variant && variant !== 'control';
};

export const useNavigationVariant = () => {
  const variant = useExperimentVariant(
    'experiment_navigation',
    'storefront-navigation-test',
  );
  return variant && variant !== 'control';
};
