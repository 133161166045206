import {
  WhoopPage,
  WhoopPageConnection,
  WhoopPageEdge,
} from '../types/generated';

const selectPage = (
  handle: string,
  edges: WhoopPageEdge[] | undefined,
): WhoopPage => {
  const regionPage = edges?.find(
    (edge) =>
      edge?.node?.handle ===
      `${handle}-${process.env.SHOP_REGION?.toLowerCase() || ''}`,
  )?.node;
  const localizedPage = edges?.find(
    (edge) =>
      edge?.node?.handle === `${handle}-${process.env.SHOP_LANGUAGE || ''}`,
  )?.node;
  const defaultPage = edges?.find(
    (edge) => edge?.node?.handle === handle,
  )?.node;
  const firstPage = edges?.[0]?.node;
  return localizedPage || regionPage || defaultPage || firstPage!;
};

const selectLocalePage = (
  handle: string,
  allShopifyPage: WhoopPageConnection,
  allWhoopPage: WhoopPageConnection,
): WhoopPage => {
  const shopifyPage = selectPage(handle, allShopifyPage?.edges);
  const whoopPage = selectPage(handle, allWhoopPage?.edges);

  if (whoopPage) {
    return whoopPage;
  }
  return shopifyPage;
};
export default selectLocalePage;
