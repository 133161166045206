/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Optional } from '@whoop/web-components';
import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { trackWhoopAnalyticsEvent } from '../utils/analytics';

type MaybeMutationObserver = MutationObserver | null;

const DEFAULT_OPTIONS = {
  attributes: true,
  childList: true,
  subtree: true,
};

interface OkendoMetafieldData extends JSON {
  averageRating: string;
  reviewCount: number;
}

function useMutationObservable(
  node: Node | RefObject<HTMLElement> | null,
  cb: MutationCallback,
  options: MutationObserverInit = DEFAULT_OPTIONS,
) {
  const [observer, setObserver] = useState(null as MaybeMutationObserver);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, setObserver]);

  useEffect(() => {
    if (!observer || !node) return;
    if ('current' in node) {
      observer.observe(node.current as Node, options);
    } else {
      observer.observe(node, options);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, node, options]);
}

function useOkendoReviews(ref: RefObject<HTMLDivElement>): number {
  const [reviewCount, setReviewCount] = useState<number>(0);
  const initialiseReviewsWidget = () => {
    // @ts-ignore
    if (window.okeWidgetApi) {
      // @ts-ignore
      window.okeWidgetApi.initWidget(ref.current);
      return true;
    } else {
      // keep trying until the okeWidgetApi object is available
      setTimeout(initialiseReviewsWidget, 100);
    }
  };
  const trackWidgetEvents = (e: Event) => {
    if (e.target instanceof HTMLElement) {
      if (e.target.matches('.oke-helpful-vote-button--positive')) {
        trackWhoopAnalyticsEvent('Reviews Interaction: Thumbs Up');
      }
      if (e.target.matches('.oke-helpful-vote-button--negative')) {
        trackWhoopAnalyticsEvent('Reviews Interaction: Thumbs Down');
      }
      if (e.target.matches('.oke-w-writeReview')) {
        trackWhoopAnalyticsEvent('Reviews Interaction: Write a Review');
      }
      if (e.target.matches('.oke-media-link')) {
        trackWhoopAnalyticsEvent('Reviews Modal: Open');
      }
      if (e.target.matches('.oke-showMore-button')) {
        trackWhoopAnalyticsEvent('Reviews Interaction: Show More');
      }
    }
  };

  const trackModalEvents = (e: Event) => {
    if (e.target instanceof HTMLElement) {
      const selectedImageId = document.querySelector(
        '.oke-mediaGallery-nav-button.is-oke-selected',
      )?.id;
      if (selectedImageId) {
        // get current image slide index
        const imageIndex =
          parseInt(selectedImageId.split('-').slice(-1)[0]) + 1;
        if (e.target.matches('.oke-helpful-vote-button--positive')) {
          trackWhoopAnalyticsEvent('Reviews Modal: Thumbs Up', {
            image_index: imageIndex,
          });
        }
        if (e.target.matches('.oke-helpful-vote-button--negative')) {
          trackWhoopAnalyticsEvent('Reviews Modal: Thumbs Down', {
            image_index: imageIndex,
          });
        }
        if (e.target.matches('.oke-mediaGallery-nav-next')) {
          trackWhoopAnalyticsEvent('Reviews Modal: Right Arrow', {
            image_index: imageIndex,
          });
        }
        if (e.target.matches('.oke-mediaGallery-nav-prev')) {
          trackWhoopAnalyticsEvent('Reviews Modal: Left Arrow', {
            image_index: imageIndex,
          });
        }
        if (e.target.matches('.oke-mediaGallery-nav-button')) {
          trackWhoopAnalyticsEvent('Reviews Modal: Image', {
            image_index: imageIndex,
          });
        }
      }
    }
  };

  const trackFilterEvent = (e: Event) => {
    if (e.target instanceof HTMLInputElement) {
      const filterValue = `${e.target.getAttribute('name') ?? ''} ${
        e.target.getAttribute('value') ?? ''
      }`;
      if (e.target.checked) {
        trackWhoopAnalyticsEvent('Reviews Interaction: Filter Added', {
          filter_selection: filterValue,
        });
      } else {
        trackWhoopAnalyticsEvent('Reviews Interaction: Filter Removed', {
          filter_selection: filterValue,
        });
      }
    }
  };
  // mutation observer callback to listen to modal events
  const onMutation = useCallback((mutations: MutationRecord[]) => {
    const modal = document.querySelector(
      '.oke-modal-content',
    ) as HTMLDivElement;
    if (modal) {
      modal.removeEventListener('click', trackModalEvents);
      modal.addEventListener('click', trackModalEvents);
    }
    for (const mutation of mutations) {
      mutation.removedNodes.forEach((removedNode) => {
        if (
          removedNode instanceof HTMLElement &&
          removedNode.id === 'oke-modalContainer'
        ) {
          trackWhoopAnalyticsEvent('Reviews Modal: Close');
        }
      });
    }
  }, []);
  /**
   * Initialize main event listeners for widget
   */
  const initializeEventListeners = () => {
    const okendoWidget = document.querySelector(
      '[data-oke-widget]',
    ) as HTMLDivElement;
    if (okendoWidget && okendoWidget.dataset.okeRendered === '') {
      okendoWidget.removeEventListener('click', trackWidgetEvents);
      okendoWidget.addEventListener('click', trackWidgetEvents);

      const showMoreButton = document.querySelector('.oke-showMore-button');
      const sortElement = document.getElementById(
        'oke-sortSelect--reviews',
      ) as HTMLSelectElement;
      const filterButton = document.querySelector(
        '.oke-w-reviews-filterToggle',
      );
      const mediaButtons = document.querySelectorAll('.oke-media-link');
      if (showMoreButton) {
        showMoreButton.addEventListener('click', function () {
          trackWhoopAnalyticsEvent('Reviews Interaction: Show More');
        });
      }
      if (sortElement) {
        sortElement.addEventListener('change', function () {
          trackWhoopAnalyticsEvent('Reviews Interaction: Sort', {
            label: sortElement.options[sortElement.selectedIndex].text,
          });
        });
      }
      if (filterButton) {
        filterButton.addEventListener('click', function () {
          trackWhoopAnalyticsEvent('Reviews Interaction: Filter');
          setTimeout(function () {
            const filterElements = document.querySelectorAll(
              '.oke-w-filterOption-checkbox',
            );
            filterElements.forEach((filter) => {
              filter.removeEventListener('change', trackFilterEvent);
              filter.addEventListener('change', trackFilterEvent);
            });
          }, 100);
        });
      }
      if (mediaButtons.length > 0) {
        mediaButtons.forEach((mediaButton) => {
          mediaButton.addEventListener('click', function () {
            trackWhoopAnalyticsEvent('Reviews Modal: Open');
          });
        });
      }
      return true;
    }
    // keep trying until "oke-rendered" attribute is rendered in DOM
    setTimeout(initializeEventListeners, 500);
  };

  // initialize mutation observer on the <body> to watch for added modal
  useMutationObservable(document.body, onMutation, {
    childList: true,
    attributes: true,
  });

  // Okendo fires a custom event with a detail: { widget } property. When it is equal to "reviews widget", initialize the event listeners
  const initEventTracking = (e: Event) => {
    // @ts-ignore
    if (e.detail.widget === 'reviews-widget') {
      initializeEventListeners();
      const dataScriptElement = (
        document.querySelector('[data-oke-metafield-data]') as HTMLScriptElement
      ).textContent;
      if (dataScriptElement) {
        const data = JSON.parse(dataScriptElement) as OkendoMetafieldData;
        setReviewCount(data.reviewCount);
      }
    }
  };
  // fire initializers on render
  useLayoutEffect(() => {
    initialiseReviewsWidget();
    document.addEventListener('oke-rendered', initEventTracking);
    return () => {
      document.removeEventListener('oke-rendered', initEventTracking);
    };
  }, []);

  return reviewCount;
}

export const useOkendoStarReviews = (
  starsRef: RefObject<HTMLElement>,
  productId: Optional<string> | undefined,
): void => {
  const initialiseReviewsWidget = () => {
    // @ts-ignore
    if (window.okeWidgetApi) {
      // @ts-ignore
      window.okeWidgetApi.initWidget(starsRef.current);
      return true;
    } else {
      // keep trying until the okeWidgetApi object is available
      setTimeout(initialiseReviewsWidget, 100);
    }
  };
  const handleClick = useCallback(() => {
    trackWhoopAnalyticsEvent('Reviews Interaction: Click Star Ratings');
  }, []);
  const onMutation = useCallback(() => {
    const starRatingButton = document.querySelector(
      '[data-oke-star-rating] .oke-is-clickable',
    ) as HTMLDivElement;
    if (starRatingButton) {
      starRatingButton.removeEventListener('click', handleClick);
      starRatingButton.addEventListener('click', handleClick);
    }
  }, []);
  useMutationObservable(starsRef.current, onMutation, {
    childList: true,
    attributes: true,
  });
  useLayoutEffect(() => {
    initialiseReviewsWidget();
  }, [productId]);
};

export default useOkendoReviews;
