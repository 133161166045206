/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect } from 'react';
import { IS_LOCAL_DEV, setupAnalytics } from './index';
import { startAllTrafficExperiments } from './experiments';
import { useReduxDispatch } from '../redux/create-store';
import { loadCart } from '../redux/reducers/cartReducer';
import { useProLandingContent } from '../redux/hooks';
import { useLocation } from '@reach/router';
import { loadUser } from '../redux/reducers/userReducer';
import whoopAnalytics from '@whoop/web-analytics';

function useInitI18n() {
  const { i18n } = useTranslation();
  i18n.on('missingKey', (lang, ns, key) => {
    const message = `i18n: Missing Key: ${key}, in NS: ${ns}`;
    // @ts-ignore: Cypress can be undefined
    if (!IS_LOCAL_DEV || (typeof window !== 'undefined' && window.Cypress)) {
      throw new Error(message);
    } else {
      console.error(message);
    }
  });
}

function useInitAnalytics() {
  const isInitialized = !!useProLandingContent();
  const location = useLocation();

  useEffect(() => {
    setupAnalytics();
    startAllTrafficExperiments();
  }, []);

  // Track the page view once the Redux store has finished loading the user
  // and WHOOP Pro status information.
  useEffect(() => {
    if (isInitialized) {
      whoopAnalytics &&
        whoopAnalytics.notifyRouteChange &&
        whoopAnalytics.notifyRouteChange({ eventName: 'Viewed Page' });
    }
  }, [location, isInitialized]);
}

/**
 * To ensure consistency, we want to load the user before we load the cart.
 * This does make the cart loading a bit delayed the first time.
 * but subsequent times will be faster since the auth tokens will be cached
 * one potential speed improvement:
 *    The fetchMemberWhoopProLandingContent() part of the loadUser() action
 *    is not needed before fetching the cart. If we want to improve speeds
 *    we could load the cart and the landing content at the same time.
 */
function useInitUserAndUser() {
  const dispatch = useReduxDispatch();

  useEffect(() => {
    (async () => {
      if (dispatch) {
        await dispatch(loadUser());
        await dispatch(loadCart());
      }
    })();
  }, [dispatch]);
}

/**
 * Called every time a new page loads. Use this hook for initialization logic.
 */
export function useInitialization() {
  useInitI18n();
  useInitAnalytics();
  useInitUserAndUser();
}
