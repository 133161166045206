import React from 'react';
import SEO from '~/components/Seo';
import '@whoop/web-components/dist/index.css';
import Homepage from '~/components/Homepage';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const IndexPage = () => {
  const { t } = useTranslation('homepage');

  return (
    <>
      <SEO
        title={t('seo.title')}
        description={t('seo.description')}
        keywords={[t('seo.whoop')]}
      />
      <Homepage />
    </>
  );
};

export const translationsQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default IndexPage;
