/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable array-callback-return */
import { useSelector } from 'react-redux';
import { Store, upsellModalType, User } from '../types';
import {
  WhoopProInfo,
  WhoopProLandingContent,
} from '../utils/membership-service';
import reduce from 'lodash/reduce';
import { ShopifyStorefront } from '../types/shopify-storefront';
import {
  BILLING_REGION,
  BillingRegionCode,
  FREE_SHIPPING_FEATURE_FLAG,
  FREE_SHIPPING_THRESHOLD,
} from '../utils/regions';
import useShopifyProductsByVariant from '../hooks/useShopifyProductsByVariant';
import { isWhoopProExclusive } from '../utils/products';
import useProMembershipVariant from '../hooks/useProMembershipVariant';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useProductServiceSkuMapping from '../hooks/useProductServiceSkuMapping';
import { formatPrice, isEmployeeDiscountEligible } from '../utils/priceUtils';
import { Optional } from '@whoop/web-components/dist/types';
import { useIsOnForAll } from '../utils/feature-flags';
import { formatDate, skuWithoutSuffix } from '../utils';
const PRO_FREE_ELIGIBLE_TAG = '__pro:free-reward';
const PRO_FREE_PRICE_TAG = '__pro:free-price:';
const WHOOP_PRO_SKU = '940-000004-021';

export const useCheckout = () =>
  useSelector<Store, ShopifyStorefront.Checkout | undefined>(
    (state) => state.checkout,
  );

/**
 * The entire user object.
 */
export const useUser = () =>
  useSelector<Store, Optional<User>>((state) => state?.user);

export const useIsUserLoaded = (): boolean =>
  useSelector<Store, boolean>((state) => !!state.userLoaded);

export const useIsSignedIn = (): boolean => !!useUser();

export const useIsEmployee = () => !!useUser()?.isEmployee;

export const useLoginError = () =>
  useSelector<Store, Optional<string>>((state) => state?.loginError);

/**
 * The entire whoop pro status object
 */
export const useProStatus = () =>
  useSelector<Store, Optional<WhoopProInfo>>((state) => state?.whoopProStatus);

/**
 * The whoop pro landing content
 */
export const useProLandingContent = () =>
  useSelector<Store, Optional<WhoopProLandingContent>>(
    (state) => state?.whoopProLandingContent,
  ) as WhoopProLandingContent;

/**
 * If the cart is loaded
 */
export const useIsCartLoaded = (): boolean => !!useCheckout()?.id;

/**
 * If the cart is currently loading
 */
export const useIsCartMutating = (): boolean =>
  useSelector<Store, boolean>((state) => !!state.mutatingCheckout);

/**
 * If the user has a Battery in cart
 */
export const useHasSkuInCart = (sku?: string) =>
  useSelector<Store, boolean>(
    (state: Store) =>
      !!Array.from(state.cartSkus || []).find((s) => sku && s.startsWith(sku)),
  );

/**
 * If the user has a WHOOP Pro membership in cart
 */
export const useHasProInCart = () => useHasSkuInCart(WHOOP_PRO_SKU);

export const useCartItemCount = (): number | undefined => {
  const checkout = useCheckout();
  if (checkout?.id) {
    return reduce(
      checkout.lineItems,
      (acc, item) => {
        if (item.variant?.sku?.startsWith(WHOOP_PRO_SKU)) {
          return acc + 1; // only count as 1 item
        }
        return acc + item.quantity;
      },
      0,
    );
  }
};

/**
 * If the user is currently claiming a free reward
 */
export const useIsFreeRewardApplied = () =>
  useSelector<Store, boolean>((state) => !!state.whoopProClaimReward);

/**
 * The line item of the current free reward
 */
export const useCurrentFreeRewardLineItem = () =>
  useSelector<Store, ShopifyStorefront.CheckoutLineItem | undefined>(
    (state) => state.freeRewardLineItem,
  );

/**
 * If the user has a free reward available
 */
export const useFreeRewardAvailable = (): boolean => {
  const user = useUser();
  const whoopProStatus = useProStatus();

  return (
    !!user &&
    !!whoopProStatus &&
    (whoopProStatus.status === 'active' ||
      whoopProStatus.status === 'expiring') &&
    (whoopProStatus.reward_status === 'available' ||
      whoopProStatus.welcome_reward_status === 'available')
  );
};

/**
 * If the user's account is WHOOP Pro
 */
export const useIsPro = (): boolean => {
  const whoopProStatus = useProStatus();
  if (whoopProStatus) {
    return (
      whoopProStatus?.status === 'active' ||
      whoopProStatus?.status === 'expiring' ||
      whoopProStatus?.status === 'pending'
    );
  }
  return false;
};
/**
 * Base percent discount for this WHOOP Pro member
 */
export const useBaseProDiscount = () => {
  const landingContent = useProLandingContent();
  return landingContent ? landingContent.store_discount : 20;
};

const isFreeEligible = (
  user?: User | null,
  tags?: string[],
  price?: number,
): boolean => {
  const hasFreeEligibleTag = !!tags?.find(
    (tag) => tag === PRO_FREE_ELIGIBLE_TAG,
  );
  const freePrice = tags
    ?.filter((tag) => tag?.startsWith(PRO_FREE_PRICE_TAG))
    ?.map((tag) => parseInt(tag?.split(':')[2] || '0'))
    ?.reduce((max, n) => Math.max(max, n), 0);
  if (freePrice) {
    return (price || 0) <= freePrice;
  }

  if (hasFreeEligibleTag && user?.isEmployee) {
    return isEmployeeDiscountEligible(tags);
  }
  return hasFreeEligibleTag;
};

/**
 * If the given product tags are eligible to be free.
 */
export const useIsFreeEligible = (tags?: string[], price?: number): boolean => {
  const user = useUser();
  return isFreeEligible(user, tags, price);
};

export const useFreeEligiblePrice = (tags?: string[]) => {
  const freePrice = tags
    ?.filter((tag) => tag?.startsWith('__pro:free-price:'))
    ?.map((tag) => parseInt(tag?.split(':')[2] || '0'))
    ?.reduce((max, n) => Math.max(max, n), 0);
  return freePrice;
};

/**
 * If the user has free eligible items in cart
 */
export const useHasFreeEligibleItemsInCart = () => {
  const user = useUser();
  const checkout = useCheckout();
  const productVariantMap = useShopifyProductsByVariant();

  return checkout?.lineItems?.find((lineItem) => {
    const shopifyProduct = productVariantMap[lineItem?.variant?.id || ''];
    const tags: string[] = shopifyProduct?.tags?.map((_) => _!) || [];
    return isFreeEligible(user, tags, lineItem?.variant?.priceV2?.amount);
  });
};

export const useShowAsFree = (tags: string[], price: number) => {
  const isFreeEligible = useIsFreeEligible(tags, price);
  const proInCart = useHasProInCart();
  const freeRewardAvailable = useFreeRewardAvailable();
  const currentFreeRewardLineItem = useCurrentFreeRewardLineItem();
  const freeFloorPrice = Math.max(
    0,
    parseFloat(currentFreeRewardLineItem?.variant?.priceV2?.amount || '0'),
  );
  return (
    isFreeEligible &&
    (proInCart || freeRewardAvailable) &&
    price > freeFloorPrice
  );
};

export const useProMonthlyPrice = (): string => {
  const landingContent = useProLandingContent();
  return formatPrice(landingContent?.unformatted_monthly_price);
};

export const useProNMonthsPrice = (n: number): string | undefined => {
  const monthlyPrice = useProMonthlyPrice();
  if (monthlyPrice) {
    const monthlyPriceNum = parseInt(monthlyPrice.replace(/[^0-9]/, ''));
    return formatPrice(n * monthlyPriceNum);
  }
};

/**
 * If the user is eligible for pro benefits
 */
export const useHasProBenefits = () => {
  const isPro = useIsPro();
  const proInCart = useHasProInCart();
  // This needs to be done like this since React counts the times hooks are run
  // and code like `useHook1() || useHook2()` can short circuit with useHook1 and never call useHook2
  return isPro || proInCart;
};

/**
 * If the pro modal should be shown
 */
export const useShowProModal = () =>
  useSelector<Store, boolean>((state: Store) => !!state.showProModal);

/**
 * If the user has not paid for WHOOP Pro
 */
export const useIsProPending = () =>
  useSelector<Store, boolean>(
    (state) => state.whoopProStatus?.status === 'pending',
  );

export type FreeRewardStatus =
  | 'eligible'
  | 'will-be-eligible'
  | 'ineligible'
  | 'pending';
/**
 * Status of the user's free reward
 */
export const useFreeRewardStatus = (): FreeRewardStatus => {
  const isProPending = useIsProPending();
  const isPro = useIsPro();
  const proInCart = useHasProInCart();
  const hasFreeRewardAvailable = useFreeRewardAvailable();

  if (isProPending) {
    return 'pending';
  }
  if (proInCart) {
    return 'eligible';
  }
  if (isPro) {
    if (hasFreeRewardAvailable) {
      return 'eligible';
    }
    return 'will-be-eligible';
  }
  return 'ineligible';
};

/**
 * The users next reward date as a string
 */
export const useNextRewardDate = () =>
  useSelector<Store, string>((state) => {
    const rawDate = state.whoopProStatus?.next_reward_date;
    if (!rawDate) {
      return '3 months after activation';
    }
    const [year, month, day] = rawDate.split('-');
    // @ts-ignore
    const date = new Date(year, month - 1, day);
    return formatDate(date);
  });

/**
 * The user's next reward date as a string, or undefined if the member doesn't
 * have a next reward date yet.
 */
export const useNextRewardDateV2 = (): string | undefined =>
  useSelector<Store, string | undefined>((state) => {
    const rawDate = state.whoopProStatus?.next_reward_date;
    if (!rawDate) {
      return undefined;
    }
    const [year, month, day] = rawDate.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return formatDate(date);
  });

/**
 * User's prorated months, if eligible
 */
export const useProProratedMonths = (): number | undefined =>
  useUser()?.upgradeProRatedMonths;

/**
 * Determines if the user's pro status is indeterminate
 */
export const useIsProStatusLoaded = () => {
  const landingContentLoaded = !!useProLandingContent();
  const checkoutLoaded = useIsCartLoaded();
  return landingContentLoaded && checkoutLoaded;
};

/**
 * Returns the user's billing region
 */
export const useUsersBillingRegion = () =>
  useUser()?.billingRegion as BillingRegionCode;

/**
 * If the user is in their own billing region
 */
export const useUserInOwnBillingRegion = () => {
  const billingRegion = useUsersBillingRegion();
  return billingRegion === BILLING_REGION;
};

/**
 * If the user is eligible for prorated months
 */
export const useIsEligibleForProCheckout = (): boolean => {
  const upgradeProratedMonths = useProProratedMonths();
  const userInOwnBillingRegion = useUserInOwnBillingRegion();
  return (
    userInOwnBillingRegion &&
    !!upgradeProratedMonths &&
    upgradeProratedMonths > 1
  );
};

export const useProCtaButtonContent = () => {
  const { t } = useTranslation('whoopPro');
  const isEligibleForProCheckout = useIsEligibleForProCheckout();
  const landingContent = useProLandingContent();

  if (isEligibleForProCheckout) {
    return t('addWhoopProToCart');
  } else if (landingContent?.cta?.button_text) {
    return landingContent?.cta?.button_text;
  }
  return t('joinWhoopPro');
};

export const useLineItems = () => {
  const { t } = useTranslation('cart');
  const proMembershipVariant = useProMembershipVariant();
  const productVariantMap = useShopifyProductsByVariant();
  const checkout = useSelector((state: Store) => state.checkout);
  const productServiceSkuMapping = useProductServiceSkuMapping();
  return checkout?.lineItems?.map((lineItem) => {
    const variant = lineItem.variant;
    const shopifyProduct = productVariantMap[variant?.id || ''];
    const whoopProductSku =
      productServiceSkuMapping[skuWithoutSuffix(variant?.sku) || ''];
    const tags: string[] = shopifyProduct?.tags?.map((_) => _!) || [];
    const isWyw = !!tags?.find((tag) => tag === 'wyw');
    const isExclusive =
      isWhoopProExclusive(tags, variant?.sku) ||
      !!lineItem?.customAttributes?.find(({ key }) => key === '_pro_exclusive');
    const isProSubscription =
      lineItem.variant?.id === proMembershipVariant?.shopifyId;
    const lineItemMessage = isProSubscription
      ? t('quantityMonths', { quantity: lineItem?.quantity })
      : undefined;
    const messageMetafield = shopifyProduct?.metafields?.find(
      (metafield) => metafield?.key === 'message',
    );
    const alertLines: string[] =
      messageMetafield?.value?.split('\n').filter((line: string) => !!line) ||
      [];
    const title = whoopProductSku?.title || lineItem.title;
    const variantTitle =
      lineItem?.variant?.title?.toLowerCase() === 'default title' ||
      isWyw ||
      shopifyProduct?.productType?.toLowerCase() === 'gift cards'
        ? ''
        : whoopProductSku?.variantTitle || lineItem?.variant?.title;
    const item = {
      ...lineItem,
      title,
      variant: {
        ...lineItem.variant,
        title: variantTitle,
      },
    };

    return {
      item,
      isWyw,
      isExclusive,
      isProSubscription,
      alertLines,
      lineItemMessage,
    };
  });
};

/**
 * User's feature flags
 */
export const useFeatureFlags = (): Array<string> | undefined =>
  useUser()?.featureFlags;

/**
 * Upsell Modal State
 */
export const useUpsellModal = (): upsellModalType | undefined => {
  return useSelector<Store, upsellModalType | undefined>(
    (state) => state.upsellModal,
  );
};

export const useSkusOfInterest = (): { [sku: string]: string } => {
  return useSelector<Store, { [sku: string]: string }>(
    (state) => state.emailMeWhen.skusOfInterest,
  );
};

export const useShowFreeShipping = (): boolean => {
  const isFreeShippingOn = useIsOnForAll(FREE_SHIPPING_FEATURE_FLAG);
  const isSignedIn = useIsSignedIn();
  // Note: If the WHOOP Pro status is still loading, where useProStatus === undefined, useIsPro() will return 'false'.
  // We won't know whether to show the Free Shipping banner during this timeframe. To avoid briefly showing
  // the banner to WHOOP Pro members during the time when the status is being loaded, include a check for whether
  // the status is ready, before checking the useIsPro() data:
  const proStatusLoaded = !!useProStatus();
  const isPro = useIsPro();
  return (
    !!isFreeShippingOn &&
    !!FREE_SHIPPING_THRESHOLD &&
    (!isSignedIn || (proStatusLoaded && !isPro))
  );
};
