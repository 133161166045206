/* eslint-disable  @typescript-eslint/ban-ts-comment  */
/* eslint-disable  @typescript-eslint/prefer-regexp-exec */
import { JOIN_URL, SKU_SUFFIX } from './regions';
import { trackWhoopAnalyticsEvent } from './analytics';
import { useEffect, useMemo, useState } from 'react';
import { startExperiment } from './experiments';
import { navigate } from 'gatsby';
import whoopAnalytics from '@whoop/web-analytics';

export const IS_LOCAL_DEV = process.env.COMMIT !== '000000';

export const classes = (...arr: Array<string | undefined | false>): string =>
  arr.filter((c) => c).join(' ');

/**
 * Formats a string to a handleized string
 * @param {} value
 * @returns
 */
export function handleize(value: string): string {
  return value
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/-$/, '')
    .replace(/^-/, '');
}

// Cleans a string that was parsed from html
export function unescapeHtml(htmlString: string): string {
  if (!htmlString || typeof htmlString !== 'string') {
    return '';
  }
  return htmlString
    .replace('&amp;', `&`)
    .replace('&lt;', `<`)
    .replace('&gt;', `>`)
    .replace('&quot;', `"`)
    .replace('&#039;', `'`);
}

export function isMarkedDown(
  originalPrice?: string | number | null,
  price?: string | number | null,
): boolean {
  // eslint-disable-next-line eqeqeq
  return !!(originalPrice && originalPrice != price);
}

export const compareStrings = (str1: string, str2: string) => {
  if (str1 < str2) {
    return -1;
  }
  if (str1 > str2) {
    return 1;
  }
  return 0;
};

const VALID_PATH_URL = /^[/a-zA-Z0-9-_]+([/?].*)?$/;
const VALID_WHOOP_URL = /^(https?:\/\/)?([A-z0-9-_.]*\.)?whoop\.com([/?].*)?$/;
const VALID_SHOPIFY_URL =
  /^(https?:\/\/)?whoop-(staging|inc|canada|europe|staging-europe|great-britain|staging-uk|uae|australia)\.myshopify\.com([/?].*)?$/;
/**
 * Opens a window safely preventing XSS.
 *
 * @param href if it includes "javascript:" it will not execute
 * @param target window target
 */
export function safeOpen(href: string, target: string): void {
  if (decodeURIComponent(href).includes('javascript:')) {
    throw new Error(`Cannot run javascript in href: ${href}`);
  }
  if (
    !href.match(VALID_PATH_URL) &&
    !href.match(VALID_WHOOP_URL) &&
    !href.match(VALID_SHOPIFY_URL)
  ) {
    throw new Error(`Cannot open that location: ${href}`);
  }
  window.open(href, target);
}

export const openJoin = (event: MouseEvent) => {
  event?.preventDefault();
  safeOpen(JOIN_URL, '_blank');
};

const defaultDateTimeOptions: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};

// Intl.DateTimeFormat is what i18n is using under the hood...
export function formatDate(
  date: Date,
  language = process.env.SHOP_LANGUAGE,
  formatOptions = defaultDateTimeOptions,
): string {
  return Intl.DateTimeFormat(language, formatOptions).format(date);
}

export function isValidDate(date: string): boolean {
  if (date === null || date === undefined) {
    return false;
  } else {
    // @ts-ignore
    return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
  }
}

export function loginLink(): string {
  if (window) {
    return `/account/login?redirect=${window.location.href}`;
  }
  return `/account/login`;
}

/**
 * Safely convert to base64 (either in SSR or browser)
 * @param text text to convert
 */
export function toBase64(text: string): string {
  if (typeof window !== 'undefined') {
    return btoa(unescape(encodeURIComponent(text)));
  } else {
    return Buffer.from(text).toString('base64');
  }
}

export function fromBase64(base64text: string): string {
  if (typeof window !== 'undefined') {
    return decodeURIComponent(escape(atob(base64text)));
  } else {
    return Buffer.from(base64text, 'base64').toString();
  }
}

/**
 * Pair this with Array.reduce to get the max of a list of objects.
 * Sample:
 *  list.reduce(maxOf(obj => obj.value))
 * @param fn function to get number from each element
 */
export function maxOf<T>(fn: (t: T) => number = (_) => Number(_)) {
  return (max: T, t: T) => {
    if (fn(t) > fn(max)) return t;
    return max;
  };
}

/**
 * Pair this with Array.reduce to get the min of a list of objects.
 * Sample:
 *  list.reduce(maxOf(obj => obj.value))
 * @param fn function to get number from each element
 */
export function minOf<T>(fn: (t: T) => number = (_) => Number(_)) {
  return (min: T, t: T) => {
    if (fn(t) < fn(min)) return t;
    return min;
  };
}

export function setupAnalytics(): void {
  if (window.DD_RUM) {
    window.DD_RUM.startSessionReplayRecording();
  }
  whoopAnalytics.initialize({
    segment: {
      key: process.env.SEGMENT_KEY,
    },
  });
  whoopAnalytics.registerUserEventTracker({
    callbackFn: trackWhoopAnalyticsEvent,
    trackClicks: true,
    trackScroll: true,
  });
}

export function preloadImage(url: string) {
  if (Image) {
    new Image().src = url;
  }
}

export function pickRandomItem<T>(array: T[]): T | undefined {
  if (array && array.length > 0) {
    return array[Math.floor(Math.random() * array.length)];
  }
}

/*
  typescript needs this function to ensure type safety when filtering an array like:
      Array<T|null|undefined> => Array<T>
   Use like:
       array?.filter(nonNull)
*/
export function nonNull<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

const containsEncodedComponents = function (x: string): boolean {
  return x !== decodeURIComponent(x);
};

export const decodeToken = function (inputToken: string): string {
  if (containsEncodedComponents(inputToken)) {
    return decodeToken(decodeURIComponent(inputToken));
  }
  return inputToken;
};

export type PluralHook<I, O> = (inputs: I[]) => O[];
export type SingularHook<I, O> = (input: I) => O;

/**
 * Turns a PluralHook into a SingularHook
 * note: assume the outputs of these hooks are Optional
 * @param hook plural hook
 */
export const singuralizeHook =
  <I, O>(hook: PluralHook<I, O>): SingularHook<I, O> =>
  (input: I) => {
    const oneInput = useMemo(() => [input], [input]); // memoized so we don't create a new array each time
    return hook(oneInput)[0];
  };

/**
 * Idempotent function strip the regional suffix from a sku
 * @param sku string
 */
export function skuWithoutSuffix(sku?: string) {
  return sku?.replace(RegExp(`${SKU_SUFFIX}$`), '');
}

/**
 * Idempotent function add suffix to sku
 * @param sku string
 */
export function skuWithSuffix(sku?: string) {
  return `${skuWithoutSuffix(sku) || ''}${SKU_SUFFIX}`;
}

/**
 * Hook to manage starting an experiment and retrieving the variant
 * @param experimentId
 */
export function useExperimentVariant(experimentId: string) {
  const [variant, setVariant] = useState<string>();

  useEffect(() => {
    setVariant(startExperiment(experimentId));
  }, [experimentId]);

  return variant;
}

/**
 * Calls the navigate function if the next link is not in the same path as
 * this page, otherwise uses safeOpen().
 * This is used in the case where query parameters may change the state of a page.
 *  If we only called Gatsby navigate on it, the query param hook would not be
 *  notified of a change
 *
 * @param url to navigate to
 */
export function clickNavigate(url: string) {
  const path = url?.split('?')?.[0] || '';
  if (window?.location?.pathname === path) {
    safeOpen(url, '_self');
  } else {
    navigate(url);
  }
}
