/* eslint-disable  @typescript-eslint/no-unsafe-return */

let promiseCache: { [key: string]: Promise<any> } = {};
/**
 * Generic promise cache
 */
export const cachedPromise = (
  key: string,
  promiseFn: () => Promise<any>,
): Promise<any> => {
  if (key in promiseCache) {
    return promiseCache[key];
  }
  const promise = promiseFn().then((res) => {
    promiseCache[key] = Promise.resolve(res);
    return res;
  });
  promiseCache[key] = promise;
  return promise;
};

export const clearPromiseCache = () => {
  promiseCache = {};
};

/**
 * Cache a fetch GET request.
 * Only works with get because why would you want to cache a POST or PUT ¯\_(ツ)_/¯
 */
export const cachedFetch = (url: string): Promise<any> => {
  return cachedPromise(url, () => fetch(url).then((res) => res.json()));
};
