import { graphql, useStaticQuery } from 'gatsby';
import { ShopifyProductVariant } from '../types/generated';

const ProMembershipVariantQuery = graphql`
  query ProMembershipVariantQuery {
    shopifyProductVariant(product: { handle: { eq: "whoop-pro" } }) {
      shopifyId
      sku
    }
  }
`;

function useProMembershipVariant(): ShopifyProductVariant {
  const queryResult = useStaticQuery<{
    shopifyProductVariant: ShopifyProductVariant;
  }>(ProMembershipVariantQuery);

  return queryResult.shopifyProductVariant;
}

export default useProMembershipVariant;
