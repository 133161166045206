import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FullProduct } from '../../hooks/useAllProducts';

export const FILTER_BY_URL_PARAM = 'filter_by';

type Filter = {
  label: string;
  tag: string;
  enabled?: boolean;
};

export function useCollectionFilters(): { [key: string]: Filter } {
  const { t } = useTranslation('collection');
  return {
    battery: { label: t('battery'), tag: 'battery' },
    sleeve: { label: t('sleeve'), tag: 'sleeve' },
    band: { label: t('band'), tag: 'band' },
    kit: { label: t('kit'), tag: 'kit' },
    apparel: { label: t('apparel'), tag: 'Apparel' },
    body: { label: t('bodyFilter'), tag: 'body' },
    arm: { label: t('arm'), tag: 'arm' },
    wrist: { label: t('wrist'), tag: 'wrist' },
    lux: { label: t('lux'), tag: 'Lux Kit' },
    baseknit: { label: t('baseknit'), tag: 'baseknit' },
    proknit: { label: t('proknit'), tag: 'proknit' },
    hydrobands: { label: t('hydrobands'), tag: 'hydroband' },
    bicep_bands: { label: t('bicepbands'), tag: 'bicep' },
    impact_sleeves: { label: t('impactSleeves'), tag: 'impact' },
    collaborations: { label: t('collaborations'), tag: 'collaborations' },
    mens: { label: t('mens'), tag: 'mens' },
    womens: { label: t('womens'), tag: 'womens' },
    unisex: { label: t('unisex'), tag: 'unisex' },
    accessory: { label: t('accessory'), tag: 'accessory' },
    performance: { label: t('performance'), tag: 'performance' },
    everyday: { label: t('everyday'), tag: 'everyday' },
    any_wear: { label: t('anywear'), tag: 'any-wear' },
  };
}

export type FilterGroup = {
  label: string;
  children: Array<string>;
};

export function useCollectionFilterGroups(): Array<FilterGroup> {
  const { t } = useTranslation('collection');
  return [
    {
      label: t('type'),
      children: [
        'battery',
        'sleeve',
        'band',
        'kit',
        'apparel',
        'mens',
        'womens',
        'unisex',
        'accessory',
      ],
    },
    {
      label: t('placement'),
      children: ['body', 'arm', 'wrist'],
    },
    {
      label: t('collections'),
      children: [
        'baseknit',
        'proknit',
        'hydrobands',
        'bicep_bands',
        'impact_sleeves',
        'collaborations',
        'performance',
        'everyday',
        'any_wear',
      ],
    },
  ];
}

// Filters FILTER_GROUPS to only include filters that apply to a collection
export const getRelevantFilterGroups = function (
  productList: Array<FullProduct>,
): Array<FilterGroup> {
  const FILTERS = useCollectionFilters();
  const FILTER_GROUPS = useCollectionFilterGroups();
  const relevantTagSet: { [key: string]: true } = {};
  productList?.forEach((p: FullProduct) =>
    p.tags?.forEach((t?: string | null) => t && (relevantTagSet[t] = true)),
  );

  const newGroups: Array<FilterGroup> = [];
  FILTER_GROUPS.forEach((fg: FilterGroup) => {
    const filteredChildren = fg.children.filter(
      (f: string) => relevantTagSet[FILTERS[f].tag],
    );
    if (filteredChildren) {
      newGroups.push({
        ...fg,
        children: filteredChildren,
      });
    }
  });
  return newGroups;
};
