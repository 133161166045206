import React from 'react';
import styles from './product-highlights.module.scss';
import { ProLogo, Icon } from '@whoop/web-components';
import InfoButton from '../InfoButton';
import { navigate } from 'gatsby';
import UserAvatar from '../UserAvatar';
import { loginLink, formatDate } from '../../utils';
import { useToggleProModal } from '../../redux/action-hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PATH_PREFIX } from '../../utils/regions';
import { useIsPro } from '../../redux/hooks';

const GEN3_TAG = 'gen3';
const GEN4_TAG = 'gen4';

interface ProductHighlightsProps {
  tags: string[];
  userStrap?: string;
  userAvatar?: string;
  isExclusive?: boolean | null;
  earlyAccess?: Date;
}

type IconDetails = {
  icon: string;
  rank: number;
};

// prettier-ignore
export const ICON_MAP: { [icon: string]: IconDetails } = {
  adjustable_strap:         { rank: 4, icon: 'adjustable_strap',         },
  benefits_charity:         { rank: 2, icon: 'benefits_charity',         },
  bonded_seams:             { rank: 3, icon: 'bonded_seams',             },
  breathable:               { rank: 3, icon: 'breathable',               },
  collaboration:            { rank: 2, icon: 'collaboration',            },
  comfort_all_day_wear:     { rank: 4, icon: 'comfort_all_day_wear',     },
  compression:              { rank: 2, icon: 'compression',              },
  ease_of_motion:           { rank: 4, icon: 'ease_of_motion',           },
  fast_drying:              { rank: 4, icon: 'fast_drying',              },
  high_performance:         { rank: 3, icon: 'high_performance',         },
  lux:                      { rank: 5, icon: 'lux',                      },
  machine_washable:         { rank: 3, icon: 'machine_washable',         },
  pocket:                   { rank: 2, icon: 'pocket',                   },
  pod:                      { rank: 1, icon: 'pod',                      },
  premium_construction:     { rank: 3, icon: 'premium_construction',     },
  gen3:                     { rank: 0, icon: 'strap_gen3',               },
  gen4:                     { rank: 0, icon: 'strap_gen4',               },
  supportive:               { rank: 3, icon: 'supportive',               },
  water_proof:              { rank: 4, icon: 'waterproof',               },
  microfilament:            { rank: 3, icon: 'yarn',                     },
  stainless_steel:          { rank: 3, icon: 'stainless_steel',          },
  precious_metals:          { rank: 3, icon: 'precious_metals',          },
  reflective:               { rank: 3, icon: 'reflective',               },
  packs:                    { rank: 3, icon: 'packs',                    },
  shop_only:                { rank: 3, icon: 'shop_only',                },
  transferable:             { rank: 4, icon: 'transferable',             },
  no_expiration:            { rank: 4, icon: 'no_expiration',            },
  blue_light_blocking:      { rank: 0, icon: 'blue_light_blocking',      },
  recovery_score:           { rank: 1, icon: 'recovery_score',           },
  sleep:                    { rank: 2, icon: 'sleep_outline',            },
  hrv:                      { rank: 3, icon: 'hrv_outline',              },
  rhr:                      { rank: 4, icon: 'rhr_outline',              },
  stainless_steel_v2:       { rank: 4, icon: 'stainless_steel',          },
  titanium:                 { rank: 4, icon: 'titanium',                 },
  high_performance_glasses: { rank: 4, icon: 'high_performance_glasses', },
  microfiber_cloth:         { rank: 4, icon: 'microfiber_cloth',         },
  anti_glare:               { rank: 4, icon: 'anti_glare',               },
  chlorine_resistant:       { rank: 4, icon: 'chlorine_resistant',       },
  antimicrobial:            { rank: 4, icon: 'antimicrobial',            },
  hours_of_performance:     { rank: 3, icon: 'stopwatch',                },
  inlayed_crystals:         { rank: 4, icon: 'lux',                      },
  no_light_leak:            { rank: 2, icon: 'no_light_leak',            },
  more_rem:                 { rank: 1, icon: 'more_rem',                 },
  weight_training:          { rank: 1, icon: 'box_fitness',              },
  compression_sleeves:      { rank: 3, icon: 'compression',              },
  built_in_bra:             { rank: 3, icon: 'adjustable_strap',         },
  running:                  { rank: 2, icon: 'running',                  },
  reflective_details:       { rank: 3, icon: 'reflective',               },
  premium_fabric:           { rank: 4, icon: 'premium_construction',     },
  strength_trainer:         { rank: 4, icon: 'tonnage'                   },
  quick_drying:             { rank: 2, icon: 'quick_drying'              },
  cooling_fabric:           { rank: 2, icon: 'cooling_fabric'            },
  premium_comfortable_fabric: { rank: 2, icon: 'premium_comfortable_fabric' },
  x_frame:                  { rank: 2, icon: 'x_frame'                   },
  zero_pressure:            { rank: 3, icon: 'zero_pressure'             },
  rx_evo:                   { rank: 4, icon: 'rx_evo'                    },
  silicon:                  { rank: 1, icon: 'silicon'                   },
  adjustable:               { rank: 2, icon: "adjustable",               },
  sweat_proof:               { rank: 3, icon: "sweatproof",               },
  stain_proof:               { rank: 5, icon: "stainproof",               },
  cashmere:                  { rank: 2, icon: "cashmere",                 },
}; // Title comes from translations

const GENERATION_MATCH: { [key: string]: string } = {
  strap_gen3: 'copley',
  strap_gen4: 'harvard',
};

export default function ProductHighlights(
  props: ProductHighlightsProps,
): JSX.Element | null {
  const { tags, userStrap, userAvatar, isExclusive, earlyAccess } = props;
  const toggleModal = useToggleProModal('product-highlights');
  const { t } = useTranslation(['product', 'whoopPro']);

  let gen3Compatible = false;
  let gen4Compatible = false;
  const highlights = (tags || [])
    .filter((tag) => tag.includes('__detail:') || tag.includes('gen'))
    .map((tag) => tag.replace('__detail:', ''))
    .filter((icon) => {
      if (icon === GEN3_TAG) {
        gen3Compatible = true;
      } else if (icon === GEN4_TAG) {
        gen4Compatible = true;
      }
      return ICON_MAP[icon];
    })
    // if both are compatible we combine them, so we filter them from here
    .filter((icon) => {
      if (gen3Compatible && gen4Compatible) {
        return icon !== GEN3_TAG && icon !== GEN4_TAG;
      }
      return true;
    })
    .sort(
      (left: string, right: string) =>
        ICON_MAP[left].rank - ICON_MAP[right].rank,
    );
  const isPro = useIsPro();
  const isFreeWithPro =
    (tags || []).find((tag) => tag === `__pro:free-reward`) && isPro;

  if (
    highlights.length < 1 &&
    !isFreeWithPro &&
    !gen3Compatible &&
    !gen4Compatible &&
    !isExclusive
  ) {
    return null;
  }

  const getCompatibilityHint = (strapGen: string) => {
    const generation: string = GENERATION_MATCH[strapGen];
    if (!userStrap) {
      return (
        <div className={styles.compatibilityHint}>
          <p>
            {t('visitStrapSettings')}
            <br />
            <a onClick={() => navigate(loginLink())}>
              {t('signInToSeeWhichBand')}
            </a>
          </p>
        </div>
      );
    }
    if (generation === userStrap) {
      return (
        <div className={styles.compatibilityHint}>
          <UserAvatar avatarUrl={userAvatar} status='success' />
          <p>{t('fitsYourWhoop')}</p>
        </div>
      );
    }
    const generationNumber = userStrap === 'harvard' ? '4.0' : '3.0';
    return (
      <div className={styles.compatibilityHint}>
        <UserAvatar avatarUrl={userAvatar} status='warning' />
        <p>
          {t('doesNotFitYourWhoop')} <br />
          <a
            href={`/${PATH_PREFIX}/collections/${
              userStrap === 'harvard' ? 'whoop4-0' : 'whoop3-0'
            }`}
          >
            {t('exploreCompatibleBands', { gen: generationNumber })}
          </a>
        </p>
      </div>
    );
  };

  const whoopProExclusiveHint = (earlyAccess: Date | undefined) => {
    const message = earlyAccess
      ? t('exclusiveUntil', {
          date: formatDate(earlyAccess),
        })
      : t('exclusiveToWP');
    return (
      <div className={styles.compatibilityHint}>
        <p>
          {`${message} `}
          <a onClick={toggleModal}>{t('learnMoreAbout')}</a>
        </p>
        <ProLogo className={styles.proLogo} height={40} width={40} />
      </div>
    );
  };

  return (
    <div className={styles.productHighlights} data-test-id='product-highlights'>
      <h3>{t('productDetails')}</h3>
      {gen3Compatible && gen4Compatible && (
        <div className={styles.detailContainer}>
          <Icon name='strap' className={styles.detailIcon} />
          <h4 className={styles.detailTitle}>{t('3and4Compatible')}</h4>
        </div>
      )}
      {highlights.map((key: string) => {
        const icon = ICON_MAP[key]?.icon;
        return (
          <div
            key={icon}
            className={styles.detailContainer}
            data-test-id='compatibility'
          >
            <Icon name={icon} className={styles.detailIcon} />
            <h4 className={styles.detailTitle}>{t(`highlights.${key}`)}</h4>
            {icon.includes('gen') && (
              <InfoButton>{getCompatibilityHint(icon)}</InfoButton>
            )}
          </div>
        );
      })}
      {isExclusive && (
        <div
          key={'locked'}
          className={styles.detailContainer}
          data-test-id='pro-exclusive'
        >
          <Icon name={'locked'} className={styles.detailIcon} />
          <h4 className={styles.detailTitle}>{t('membersOnly')}</h4>
          <InfoButton>{whoopProExclusiveHint(earlyAccess)}</InfoButton>
        </div>
      )}
      {isFreeWithPro && (
        <div className={styles.detailContainer} data-test-id='free-eligible'>
          <ProLogo className={styles.detailIcon} onClick={toggleModal} />
          <h4 className={styles.detailTitle}>{t('wpFreeEligable')}</h4>
        </div>
      )}
    </div>
  );
}
