/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styles from './price.module.scss';
import { ProLogo } from '@whoop/web-components';
import { classes } from '../../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PriceTag } from '../../utils/priceUtils';

export default function Price({
  price,
  originalPrice,
  isProDiscount,
  whoopProPrice,
  className,
  ariaLabel,
  ...props
}: PriceTag & { className?: string; ariaLabel?: string }): JSX.Element {
  const { t } = useTranslation('price');
  return (
    <span
      className={classes(className, styles.priceContainer)}
      aria-label={ariaLabel || t('aria.price')}
      {...props}
    >
      {originalPrice && (
        <span className={styles.originalPrice}>{originalPrice}</span>
      )}
      {originalPrice && isProDiscount && (
        <ProLogo className={styles.proLogo} style={{ marginRight: '5px' }} />
      )}
      <span className={styles.price}>{price}</span>
    </span>
  );
}
