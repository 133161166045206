/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { AnyAction } from '@reduxjs/toolkit';
export type ActionMatcher = (action: AnyAction) => boolean;

export function isPending(action: AnyAction): boolean {
  return action.type.endsWith('/pending');
}

export function isFulfilled(action: AnyAction): boolean {
  return action.type.endsWith('/fulfilled');
}

export function isRejected(action: AnyAction): boolean {
  return action.type.endsWith('/rejected');
}

export function and(...matchers: ActionMatcher[]): ActionMatcher {
  return (action: AnyAction): boolean => {
    return !!matchers?.reduce((acc, matcher) => acc && matcher(action), true);
  };
}

export function or(...matchers: ActionMatcher[]): ActionMatcher {
  return (action: AnyAction): boolean => {
    return !!matchers?.reduce((acc, matcher) => acc || matcher(action), true);
  };
}
