import { useTranslation } from 'gatsby-plugin-react-i18next';
import styles from './search-form.module.scss';
import { Input } from '@whoop/web-components';
import React from 'react';

export const SearchForm = ({ infoText }: { infoText: string }): JSX.Element => {
  const { t } = useTranslation('search');
  return (
    <form
      action='/search'
      role='search'
      method='GET'
      className={styles.searchForm}
      aria-label={t('aria.searchProducts')}
    >
      <h3>{t('search')}</h3>
      <p>{infoText}</p>
      <Input id='search-query' name='q' label={t('search')} type='text' />
    </form>
  );
};
