/* eslint-disable camelcase */
import { graphql, useStaticQuery } from 'gatsby';
import { StaticProductNode } from '../types';
import { Optional } from '@whoop/web-components/dist/types';
import { ProductNode } from '@whoop/web-components';
import { useGenerateStaticProductNode } from './staticProductHooks';
import { nonNull } from '../utils';
import { useMemo } from 'react';

const allPacksQuery = graphql`
  query AllPacksQuery {
    allWhoopProduct(filter: { pack_details: { discount: { ne: null } } }) {
      nodes {
        handle
        pack_details {
          pack_items
        }
      }
    }
  }
`;

function useStaticPacks(): StaticProductNode[] {
  const queryResult = useStaticQuery<{
    allWhoopProduct: {
      nodes: StaticProductNode[];
    };
  }>(allPacksQuery);
  return useMemo(() => queryResult?.allWhoopProduct?.nodes, [queryResult]);
}

export default function usePackData(): Optional<{
  [handle: string]: ProductNode[];
}> {
  // get a list of pack handles
  const packs = useStaticPacks();
  const generateProduct = useGenerateStaticProductNode();

  return useMemo(() => {
    // create map
    const result: { [handle: string]: ProductNode[] } = {};
    packs?.forEach(({ handle, pack_details }) => {
      const nodes = pack_details?.pack_items?.map(generateProduct);
      const filteredNodes = nodes?.filter(nonNull) as ProductNode[];

      if (filteredNodes && nodes?.length === filteredNodes?.length) {
        result[handle] = filteredNodes;
      }
    });
    return result;
  }, [packs, generateProduct]);
}
