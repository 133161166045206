import React, { HTMLAttributes } from 'react';
import styles from './product-grid.module.scss';

interface ProductGridProps {
  children: React.ReactNode;
}

export default function ProductGrid({
  children,
  ...props
}: ProductGridProps & HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div className={styles.productGrid} {...props}>
      {children !== undefined && children}
    </div>
  );
}
