/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import styles from './banner.module.scss';
import {
  useFreeRewardAvailable,
  useShowFreeShipping,
  useUsersBillingRegion,
} from '../../redux/hooks';
import { Icon } from '@whoop/web-components';
import { navigate } from 'gatsby';
import { classes } from '../../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useCountryCode from '../../hooks/useCountryCode';
import { useIsOnForAll } from '../../utils/feature-flags';
import { useQueryParam } from '../../utils/queryParamUtils';
import { Optional } from '@whoop/web-components/dist/types';
import { FREE_SHIPPING_THRESHOLD, WHOOP_URL } from '../../utils/regions';
import { useBannerExperiment } from '../../hooks/useBannerExperiment';
import { formatPrice } from '../../utils/priceUtils';

function hasEligibleCountry({
  country,
  excludes,
}: {
  country: string;
  excludes: string[];
}) {
  if (country === '') {
    return false;
  }
  return !excludes.includes(country);
}

function useIsInApp() {
  const [isInApp, setIsInApp] = useState(false);
  const [utmCampaign] = useQueryParam('utm_campaign', '');

  // must be behind a useEffect to avoid hydration issues
  useEffect(() => {
    setIsInApp(utmCampaign === 'ios_link');
  }, [utmCampaign]);

  return isInApp;
}

function BannerWithBackButton({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation('banner');
  const isInApp = useIsInApp();
  const isFreeBandBanner = useFreeRewardAvailable();

  if (isInApp || isFreeBandBanner) {
    return <div className={classes(styles.banner, 'no-color')}>{children}</div>;
  }
  return (
    <div className={classes(styles.banner, styles.additionalInfo, 'no-color')}>
      <a
        className={classes(styles.banner, styles.leftBannerElement, 'no-color')}
        href={'https://whoop.com/'}
        data-event='Clicked on "<- WHOOP.COM"'
        aria-label={t('aria.backToWhoop')}
        data-event-source='banner'
      >
        <Icon name='arrow_left' />
        {t('whoopCom')}
      </a>
      {children}
      <div className={styles.rightBannerSpacer}>
        <Icon name='arrow_left' />
        <span>{t('whoopCom')}</span>
      </div>
    </div>
  );
}

export function BannerLayout({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const showFreeBandBanner = useFreeRewardAvailable();
  const wasReferredFromWhoopCom = (() => {
    if (typeof document !== 'undefined' && document?.referrer) {
      const referrer = new URL(document.referrer);
      return referrer.hostname === 'www.whoop.com';
    }
    return false;
  })();

  const [hasClientLoaded, setHasClientLoaded] = useState(false);
  useEffect(() => {
    setHasClientLoaded(true);
  }, []);

  if (hasClientLoaded && wasReferredFromWhoopCom) {
    return <BannerWithBackButton>{children}</BannerWithBackButton>;
  }
  return (
    <div
      className={classes(styles.banner, showFreeBandBanner ? styles.pro : '')}
    >
      {children}
    </div>
  );
}

function NZBanner(): JSX.Element {
  const { t } = useTranslation('banner');
  return (
    <span
      className={styles.banner}
      data-test-id='nz-banner-combo'
      aria-label={t('aria.allPricesInAUD')}
    >
      {t('allPricesInAUD')}
    </span>
  );
}

function FreeShippingBanner(): JSX.Element {
  const { t } = useTranslation('banner');
  const price = formatPrice(FREE_SHIPPING_THRESHOLD);
  return (
    <span
      className={styles.banner}
      data-test-id='free-shipping'
      aria-label={t('aria.freeShipping', { price: price })}
    >
      {t('freeShipping', { price: price })}
    </span>
  );
}

function ProBanner(): JSX.Element {
  const { t } = useTranslation('banner');
  return (
    <a
      className={classes(styles.banner, styles.pro, 'no-color')}
      data-event='WHOOP Pro Free Item Eligible'
      data-event-source='banner'
      data-test-id='pro-banner'
      data-event-banner-experiment={useBannerExperiment()}
      aria-label={t('aria.chooseFreeItem')}
      onClick={() => navigate('/collections/whoop-pro')}
    >
      <Icon name='gift' />
      <span>
        {t('freeItemAvailable')}{' '}
        <span className={styles.cta}>{t('chooseNow')}</span>
      </span>
    </a>
  );
}

function RedirectFromCABanner(): JSX.Element {
  const { t } = useTranslation('banner');
  const ctaLink = WHOOP_URL;
  return (
    <a
      className={classes(styles.banner, 'no-color')}
      href={ctaLink}
      data-event='Clicked on Back to WHOOP.COM'
      aria-label={t('aria.backToWhoop')}
      data-event-source='banner'
    >
      {t('redirectFromCA')}
    </a>
  );
}

export function ShippingDelayBanner(): JSX.Element {
  const { t } = useTranslation('banner');
  const isErpShippingDelayOn = useIsOnForAll('storefront-erp-shipping-delays');
  return isErpShippingDelayOn ? (
    <span
      className={classes(styles.banner, styles.shippingDelay, 'no-color')}
      data-test-id='shipping-delay'
      aria-label={t('aria.erpShippingDelay')}
    >
      {t('erpShippingDelay')}
    </span>
  ) : (
    <></>
  );
}

const WithingsScaleBanner = () => {
  const { t } = useTranslation('banner');
  return (
    <span
      className={styles.banner}
      data-test-id='withings-scale-banner'
      dangerouslySetInnerHTML={{ __html: t('withingsScalePromo') }}
    />
  );
};

const SwapComponents = ({
  mode = 'rotate',
  swapDelay,
  firstComponent,
  secondComponent,
}: {
  mode: 'rotate' | 'once';
  swapDelay: number;
  firstComponent: React.FC;
  secondComponent: React.FC;
}): React.ReactElement => {
  const setTimerFn = mode === 'rotate' ? setInterval : setTimeout;
  const clearTimerFn = mode === 'rotate' ? clearInterval : clearTimeout;
  const [hasSwapped, setHasSwapped] = useState(false);
  useEffect(() => {
    const timeout = setTimerFn(() => {
      setHasSwapped((prev) => !prev);
    }, swapDelay);
    return () => clearTimerFn(timeout);
  }, [swapDelay]);
  const Component = hasSwapped ? secondComponent : firstComponent;
  return <Component />;
};

export default function Banner(): Optional<JSX.Element> {
  const showFreeBandBanner = useFreeRewardAvailable();
  const billingRegion = useUsersBillingRegion();
  const userCountry = useCountryCode();
  const billingNZShippingAU =
    (userCountry === 'NZ' || billingRegion === 'nz') &&
    (process.env.SHOP_REGION === 'AU' || process.env.ENV === 'DEV');
  const showFreeShipping = useShowFreeShipping();

  // For CA redirect - CA Warehouse Shutdown
  // Add feature flag to show banner
  const [redirectFrom] = useQueryParam('redirectFrom', '');
  const wasRedirectedFromCA = redirectFrom === 'ca';
  const showRedirectFromCA = useIsOnForAll('storefront-redirect-ca-to-us');
  const showWithingsScaleBanner =
    useIsOnForAll('storefront-show-withings-scale-banner') &&
    hasEligibleCountry({
      country: userCountry || '',
      excludes: ['HK', 'AE', 'IN', 'AU', 'JP'],
    });

  if (showRedirectFromCA && wasRedirectedFromCA) {
    return <RedirectFromCABanner />;
  }

  // else show one banner
  if (showFreeBandBanner) {
    return <ProBanner />;
  }

  if (showWithingsScaleBanner) {
    return (
      <>
        <SwapComponents
          mode='rotate'
          swapDelay={7000}
          firstComponent={FreeShippingBanner}
          secondComponent={WithingsScaleBanner}
        />
      </>
    );
  }

  if (billingNZShippingAU) {
    return <NZBanner />;
  }

  if (showFreeShipping) {
    return <FreeShippingBanner />;
  }

  // else return null
  return null;
}
