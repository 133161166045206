import { useEffect, useState } from 'react';
import { useIsOnForAll } from '../utils/feature-flags';
import { useFreeRewardAvailable } from '../redux/hooks';
import { startExperiment } from '../utils/experiments';

export enum BannerExperimentVariant {
  'one_banner' = 'one_banner',
  'both_banners' = 'both_banners',
}
export function useBannerExperiment(): BannerExperimentVariant | undefined {
  const [variant, setVariant] = useState<BannerExperimentVariant>();
  const featureIsOn = useIsOnForAll('storefront-sale-and-reward-banner');
  const hasReward = useFreeRewardAvailable();

  useEffect(() => {
    if (!variant && !!featureIsOn && hasReward) {
      setVariant(
        startExperiment(
          'experiment_sale_and_reward_banners',
        ) as BannerExperimentVariant,
      );
    }
  }, [featureIsOn, hasReward]);

  return variant;
}
