import React from 'react';
import { classes } from '../../utils';
import styles from './header-logo.module.scss';
import { Logo, ProLogo } from '@whoop/web-components';

export interface HeaderLogoProps {
  className: string;
  whoopPro: boolean;
}
/**
 * Component to display pages associated with a product
 */
export default function HeaderLogo({
  whoopPro,
  className,
  ...props
}: HeaderLogoProps): JSX.Element {
  return (
    <div className={classes(styles.logoContainer, className)} {...props}>
      <div className={classes(styles.proLogoContainer, styles.hideMobile)}>
        <Logo className={styles.whoopLogo} />
        {whoopPro && <ProLogo className={styles.proLogo} variant='slim' />}
      </div>
      {whoopPro ? (
        <ProLogo className={classes(styles.wLogo, styles.hideDesktop)} />
      ) : (
        <Logo
          className={classes(styles.wLogo, styles.hideDesktop)}
          viewBox='0 0 30 30'
          variant='circle'
        />
      )}
    </div>
  );
}
