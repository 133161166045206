import Cookies from 'universal-cookie';
import { fromBase64, toBase64 } from './index';
export const cookies = new Cookies();

const createCookieItem = function (key, cookieOptions) {
  return {
    get: () => cookies.get(key),
    set: (value) => cookies.set(key, value, cookieOptions),
    remove: () => cookies.remove(key),
  };
};

const createLocalItem = function (key, encode) {
  return {
    get: () => {
      const value = localStorage.getItem(key);
      return value && encode ? fromBase64(value, 'base64') : value;
    },
    set: (value) => {
      const encodedValue = value && encode ? toBase64(value) : value;
      localStorage.setItem(key, encodedValue);
    },
    remove: () => localStorage.removeItem(key),
  };
};

export const ShopifyCheckoutId = createLocalItem('shopify_checkout_id');

export const ShopifyAccessToken = createCookieItem(
  'shopify_customer_access_token',
  {
    maxAge: 14 * 23 * 60 * 60, // (2 weeks - 1 hour) in seconds
  },
);

export const SignInHintShown = createCookieItem('sign_in_hint_shown', {
  maxAge: 24 * 60 * 60, // 1 day in seconds
});

export const WhoopUser = createLocalItem('whoop_user', true);

export const HasActionedOnUpsell = createCookieItem('has_added_upsell', {
  maxAge: 24 * 60 * 60, // 1 day in seconds
});

// @deprecated only used to remove
const WhoopAccessToken = createLocalItem('whoop.security.accessToken');
// @deprecated only used to remove
const WhoopAccessRefreshToken = createLocalItem('whoop.security.refreshToken');

// clear deprecated values
if (typeof window !== 'undefined') {
  WhoopAccessToken.remove();
  WhoopAccessRefreshToken.remove();
}
