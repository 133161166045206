import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
export const cookies = new Cookies();

function useCountryCode(): string | undefined {
  const [countryCode, setCountryCode] = useState<string | undefined>();

  useEffect(() => {
    const locationCookieValue = cookies.get('X-Whoop-Country') as string;
    setCountryCode(locationCookieValue);
  }, []);

  return countryCode;
}

export default useCountryCode;
