import { useReduxDispatch } from './create-store';
import useProMembershipVariant from '../hooks/useProMembershipVariant';
import { addItemToCart } from './reducers/cartReducer';
import {
  toggleCart,
  toggleProModal,
  toggleSideNav,
  toggleUpsellModal,
  setUpsellData,
  addEmailMeWhenSku,
} from './reducers/viewReducer';
import {
  useIsEligibleForProCheckout,
  useProLandingContent,
  useProStatus,
  useUser,
} from './hooks';
import { trackWhoopProCta } from '../utils/analytics';
import { safeOpen } from '../utils';
import { useLocation } from '@reach/router';
import { PriceTag } from '../utils/priceUtils';
import { Optional, ProductNode } from '@whoop/web-components';
import { Maybe } from '../types/generated';

/**
 * Toggles the pro modal
 */
export const useToggleProModal = (source: string, fromCart = false) => {
  const dispatch = useReduxDispatch();
  const { pathname } = useLocation();
  return () =>
    dispatch(toggleProModal({ location: pathname, source, fromCart }));
};

/**
 * Toggles the upsell pdp modal
 */
export const useToggleUpsellModal = () => {
  const dispatch = useReduxDispatch();
  return () => {
    dispatch(toggleUpsellModal());
  };
};

/**
 * opens upsell pdp with correct data
 */
export const useOpenUpsellModal = (payload: {
  node: ProductNode;
  priceTag: PriceTag;
  cartSkus: Optional<string>[];
}) => {
  const dispatch = useReduxDispatch();
  return () => dispatch(setUpsellData(payload));
};

/**
 * Toggles the cart
 */
export const useToggleCart = () => {
  const dispatch = useReduxDispatch();
  return () => dispatch(toggleCart());
};

/**
 * Toggles the side nav
 */
export const useToggleSideNav = () => {
  const dispatch = useReduxDispatch();
  return () => dispatch(toggleSideNav());
};

/**
 * Returns function to add pro to cart
 */
export const useAddProToCart = (): (() => Promise<any>) => {
  const dispatch = useReduxDispatch();
  const proMembershipVariant = useProMembershipVariant();
  return async () =>
    dispatch(
      addItemToCart({
        variantId: proMembershipVariant.shopifyId || '',
        quantity: 1, // shopify scripts adjusts the amount
      }),
    );
};

/**
 * Lets the user join whoop pro in the quickest way.
 * either by opening a new window or adding to pro to cart.
 */
export const useJoinWhoopPro = () => {
  const user = useUser();
  const whoopProStatus = useProStatus();
  const landingContent = useProLandingContent();
  const isEligibleForProCheckout = useIsEligibleForProCheckout();

  const addProToCart = useAddProToCart();
  const openCart = useToggleCart();

  return (source?: string) => {
    if (isEligibleForProCheckout) {
      addProToCart();
      openCart();
    } else if (landingContent?.cta?.button_link) {
      trackWhoopProCta(
        landingContent.cta.button_link,
        user,
        whoopProStatus,
        source,
      );
      safeOpen(landingContent.cta.button_link, '_blank');
    }
  };
};

export const useAddEmailMeWhenSku = (
  sku: Maybe<string> | undefined,
  email: Maybe<string> | undefined,
) => {
  const dispatch = useReduxDispatch();
  return () => dispatch(addEmailMeWhenSku({ sku, email }));
};
