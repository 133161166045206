import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { WhoopPage } from '../types';

const AllWhoopPageQuery = graphql`
  query AllWhoopPage {
    allWhoopPage {
      nodes {
        body
        handle
        title
      }
    }
  }
`;

export interface PagesByHandle {
  [sku: string]: WhoopPage;
}

function useAllPages(): PagesByHandle {
  const queryResult = useStaticQuery<{
    allWhoopPage: {
      nodes: WhoopPage[];
    };
  }>(AllWhoopPageQuery);
  return useMemo<PagesByHandle>(() => {
    const pagesByHandle: PagesByHandle = {};
    queryResult?.allWhoopPage?.nodes?.forEach((page) => {
      if (page && page?.handle) {
        pagesByHandle[page.handle] = page;
      }
    });
    return pagesByHandle;
  }, [queryResult]);
}

export default useAllPages;
