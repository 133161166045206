/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewState } from '../../types';
import { ProductNode } from '@whoop/web-components/dist/types/Products';
import { PriceTag } from '../../utils/priceUtils';
import { Optional } from '@whoop/web-components';
import { Maybe } from '../../types/generated';

export interface ToggleModalPayload {
  location?: string;
  source?: string;
  fromCart?: boolean;
}

export interface ErrorPayload {
  message?: string;
}

const slice = createSlice({
  name: 'view',
  initialState: {} as ViewState,
  reducers: {
    toggleProModal(
      state,
      action: PayloadAction<ToggleModalPayload | undefined>, // need this to add types to the function
    ) {
      state.showProModal = !state.showProModal;
    },
    toggleSideNav(state) {
      state.showSideNav = !state.showSideNav;
    },
    toggleCart(state) {
      state.showCart = !state.showCart;
    },
    hideCart(state) {
      state.showCart = false;
    },
    showGlobalError(state, action: PayloadAction<ErrorPayload>) {
      state.error = action.payload;
    },
    dismissGlobalError(state) {
      state.error = undefined;
    },
    toggleUpsellModal(state) {
      state.upsellModal.isVisisble = !state?.upsellModal?.isVisisble;
    },
    setUpsellData(
      state,
      action: PayloadAction<{
        node: ProductNode;
        priceTag: PriceTag;
        cartSkus: Optional<string>[];
      }>,
    ) {
      state.upsellModal.node = action.payload.node;
      state.upsellModal.priceTag = action.payload.priceTag;
      state.upsellModal.cartSkus = action.payload.cartSkus;
    },
    addEmailMeWhenSku(
      state,
      action: PayloadAction<{
        sku: Maybe<string> | undefined;
        email: Maybe<string> | undefined;
      }>,
    ) {
      if (action.payload.sku && action.payload.email) {
        const sku = action.payload.sku;
        const email = action.payload.email;
        state.emailMeWhen.skusOfInterest = {
          ...state.emailMeWhen.skusOfInterest,
          [sku]: email,
        };
      }
    },
  },
});

export const {
  toggleProModal,
  toggleSideNav,
  toggleCart,
  hideCart,
  showGlobalError,
  dismissGlobalError,
  toggleUpsellModal,
  setUpsellData,
  addEmailMeWhenSku,
} = slice.actions;
export default slice.reducer;
