import {
  ProductItem,
  ProductNode,
  Optional,
  ProductInfo,
} from '@whoop/web-components';
import useAllPages from '../hooks/useAllPages';
import { AccordionSection } from '@whoop/web-components/dist/components/Accordion';
import { nonNull } from './index';
import React from 'react';
import { Purchasable } from './purchasableUtils';

const getProductPagesFromSelection = (
  selection?: ProductItem,
  node?: ProductNode,
  fallback?: string[],
): string[] | undefined => {
  const isItemInThisNode = !!node?.product_info?.items?.find(
    ({ sku }) => selection?.sku && selection?.sku === sku,
  );
  const nodePages =
    (node?.product_info?.product_pages?.length || 0) > 0
      ? node?.product_info?.product_pages
      : fallback;
  return isItemInThisNode
    ? nodePages
    : (node?.children
        ?.map((n) => getProductPagesFromSelection(selection, n, nodePages))
        ?.find((pages) => (pages?.length || 0) > 0) as string[]);
};

/**
 * Gets the product accordions for a given selection, Can be used statically
 * @param selection currently selected ProductItem
 * @param node product node
 */
export function useProductAccordions(
  selection: Optional<ProductItem>,
  node: ProductNode | undefined,
): AccordionSection[] | undefined {
  if (!node) {
    return;
  }
  const pages = useAllPages();
  const selectedPages = getProductPagesFromSelection(selection!, node);
  return selectedPages
    ?.map((handle) => pages?.[handle])
    ?.filter(nonNull)
    ?.map(({ handle, title, body }) => ({
      id: handle,
      title,
      content: React.createElement('div', {
        dangerouslySetInnerHTML: { __html: body },
      }),
    }));
}

export const findProductItemBySku = (
  sku: string,
  node: ProductNode,
): ProductItem | undefined =>
  node?.product_info?.items?.find((item) => item?.sku === sku) ||
  node?.children?.map((n) => findProductItemBySku(sku, n))?.find(nonNull); // first non-null

export const findFirstProductItem = (
  node: ProductNode,
): ProductItem | undefined =>
  node?.product_info?.items?.[0] ||
  node?.children?.map((n) => findFirstProductItem(n))?.find(nonNull); // first non-null

export function findProductInfoBySku(
  sku: string,
  node: ProductNode,
): ProductInfo | undefined {
  if (node?.product_info?.items?.find((item) => item?.sku === sku)) {
    return node.product_info;
  } else {
    return node?.children
      .map((n) => findProductInfoBySku(sku, n))
      ?.find(nonNull);
  }
}

/**
 * If a particular SKU is a membership extension
 * @param sku sku to check
 */
export function isMemberExtension(sku?: string | null): boolean {
  return !!sku && sku?.startsWith('940-000001');
}
/**
 * If a particular SKU is a Pack
 * @param sku sku to check
 */
export function isPack(sku?: string | null): boolean {
  return !!sku && sku?.startsWith('PCK-');
}
/**
 * If a particular SKU is a Gift card
 * @param sku sku to check
 */
export function isGiftCard(sku?: string | null): boolean {
  return !!sku && sku?.startsWith('GFT-');
}

/**
 * If this product has pattern may vary
 */
export function hasVaryingPatterns(tags: string[] | undefined): boolean {
  return !!tags?.find((tag) => tag === 'varying-pattern');
}

export function isEngraveable(node?: ProductNode) {
  return (
    node?.product_info?.engraving_gen4_id_icon ||
    node?.product_info?.engraving_gen4_id_text ||
    node?.product_info?.engraving_gen4_strap_icon ||
    node?.product_info?.engraving_gen4_strap_text
  );
}

// all the key value of ProductItem that is currently used - extend the list when checking a different product tag
/* eslint-disable camelcase */
export enum ProductItemKey {
  pro_exclusive = 'pro_exclusive',
  pro_exclude_discount = 'pro_exclude_discount',
  klaviyo_event = 'klaviyo_event',
  email_when_oos = 'email_when_oos',
  gen3 = 'gen3',
  gen4 = 'gen4',
  member_only = 'member_only',
}

/**
 * Determines if the product has an exclusive product tag
 * @param tag product tag which needs to be checked for exclusivity
 */
export function isProductItemExclusive(
  purchasable: Optional<Purchasable>,
  tag: keyof ProductItem,
): boolean {
  if (purchasable && 'packItems' in purchasable) {
    return (
      !!purchasable?.item[tag] ||
      !!purchasable.packItems.find((item) => (item as ProductItem)[tag])
    );
  }
  return !!purchasable?.item[tag];
}
