import parse from 'url-parse';
import React, { useMemo, useState } from 'react';

export function addQueryParams(
  location: Location,
  queryParams: QueryParams,
): void {
  const url = parse(location.href, true);

  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key]) {
      url.query[key] = queryParams[key];
    } else {
      delete url.query[key];
    }
  });

  const params = Object.keys(url.query);
  let queryString = '';
  if (params.length > 0) {
    queryString =
      '?' +
      params.map((q) => `${q}=${url.query[q]!}`).join('&') +
      (url.hash ? url.hash : '');
  }
  // DO NOT use navigate here. It will break regions redirects
  window.history.replaceState(null, '', url.pathname + queryString);
}

/**
 * Adds a new parameter to the current query.
 * If the param already exists it will be replaced.
 *
 * @param location current location
 * @param param query param
 * @param value new value
 */
export function addQueryParam(
  location: Location,
  param: string,
  value: string,
): void {
  addQueryParams(location, { [param]: value });
}

export const getUrl = () => {
  if (typeof window !== 'undefined') {
    return parse(window.location.href, true);
  } else {
    return parse('/', true);
  }
};
const getQueryStringVal = (key: string): string | undefined => {
  const query = getUrl().query;
  return query[key];
};

export const useQueryParam = (
  key: string,
  defaultVal: string,
): [string, (val: string) => void] => {
  const [query, setQuery] = useState<string>(
    getQueryStringVal(key) || defaultVal,
  );
  const updateUrl = (newVal: string) => {
    setQuery(newVal);
    addQueryParam(window.location, key, newVal);
  };
  return [query, updateUrl];
};

export type QueryParams = { [param: string]: string | undefined };
export const useQueryParams = (
  params: QueryParams,
): [QueryParams, React.Dispatch<QueryParams>] => {
  const initialParams = useMemo(() => {
    const initialParams: QueryParams = {};
    const initialUrl = getUrl();
    Object.keys(initialUrl?.query).forEach((key) => {
      initialParams[key] = initialUrl?.query[key] || params[key];
    });
    return initialParams;
  }, []);
  const [queryParams, setQueryParams] = useState<QueryParams>(initialParams);

  return [
    queryParams,
    (params: QueryParams) => {
      addQueryParams(window.location, params);
      setQueryParams(params);
    },
  ];
};
