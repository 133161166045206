import React, { useEffect, useState } from 'react';
import { Button, Input, Icon } from '@whoop/web-components';
import styles from './email-me-when.module.scss';
import { segmentTrackSubscribedToOOS } from '../../utils/analytics';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Maybe } from '../../types/generated';
import { useSkusOfInterest, useUser } from '../../redux/hooks';
import { useAddEmailMeWhenSku } from '../../redux/action-hooks';
import { PriceTag } from '../../utils/priceUtils';

type EmailMeWhenProps = {
  outOfStock: boolean;
  isComingSoon: boolean;
  productTitle: string;
  itemTitle: string | undefined;
  sku: Maybe<string> | undefined;
  price: PriceTag;
  handle: string | undefined;
  imageUrl: string | undefined;
};

export default function EmailMeWhen({
  outOfStock,
  isComingSoon,
  productTitle,
  itemTitle,
  sku,
  price,
  imageUrl,
  handle,
}: EmailMeWhenProps): JSX.Element {
  const { t } = useTranslation('product');
  const user = useUser();
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const skusOfInterest = useSkusOfInterest();
  const addEmailMeWhenSku = useAddEmailMeWhenSku(sku, currentEmail);
  const shopUrl =
    handle && sku
      ? `https://shop.${
          process.env.NODE_ENV === 'development' ? 'qa.' : ''
        }whoop.com/products/${handle}/?sku=${sku}`
      : undefined;
  const [emailError, setEmailError] = useState<string>();
  const validateEmail = () => {
    if (!currentEmail || currentEmail.length === 0) {
      setEmailError(t('emailEmpty'));
      return false;
    }
    if (!/^.*@.*$/.test(currentEmail)) {
      setEmailError(t('emailInvalid'));
      return false;
    }
    setEmailError(undefined);
    return true;
  };

  useEffect(() => {
    if (user && !currentEmail) {
      setCurrentEmail(user?.email || '');
    }
  }, [user]);

  useEffect(() => {
    if (sku) {
      setEmailSent(Object.keys(skusOfInterest).includes(sku));
      if (skusOfInterest[sku]) {
        setCurrentEmail(skusOfInterest[sku]);
      }
    }
  }, [sku, skusOfInterest]);

  const registerSku = () => {
    addEmailMeWhenSku();
  };

  return (
    <div>
      {!emailSent && (
        <div data-test-id='email-me-when' className={styles.emailMeWhen}>
          <div className={styles.upperText}>
            {t(
              outOfStock && !isComingSoon
                ? 'notifyWhenInStock'
                : 'notifyWhenAvailable',
            )}
          </div>
          <div className={styles.lowerText}>{t('letYouKnow')}</div>
          <div>
            <label className={styles.groupLabel}>{t('email')}</label>
            <Input
              className={styles.groupInput}
              placeholder={t('enterEmail')}
              onChange={setCurrentEmail}
              value={currentEmail}
              error={emailError}
              subText={emailError}
            />
          </div>

          <div className={styles.emailMeWhenButtonWrapper}>
            <Button
              label={t('notifyMe')}
              aria-label={t('aria.soldOut')}
              variant='primary'
              onClick={() => {
                if (validateEmail()) {
                  segmentTrackSubscribedToOOS(
                    productTitle,
                    itemTitle,
                    currentEmail,
                    sku,
                    price,
                    imageUrl,
                    shopUrl,
                    outOfStock && !isComingSoon ? 'OOS' : 'comingSoon',
                  );
                  registerSku();
                }
              }}
              className={styles.emailMeWhenButton}
            />
          </div>
        </div>
      )}
      {emailSent && (
        <div data-test-id='email-me-when' className={styles.emailMeWhen}>
          <div className={styles.upperText}>
            <div>
              <Icon name='check' className={styles.check} />
              &nbsp;{t('beNotified')}
            </div>
          </div>
          <div className={styles.lowerText}>
            {t('receiveEmail')}{' '}
            <span className={styles.selectedEmail}>{currentEmail} </span>
            {t('whenAvailable')}
          </div>
        </div>
      )}
    </div>
  );
}
