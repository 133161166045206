/* @typescript-eslint no-unsafe-member-access */
/* @typescript-eslint no-unsafe-assignment */

import { useMemo } from 'react';
import { Optional } from '@whoop/web-components';
import { ProductNode } from '@whoop/web-components/dist/types/Products';
import useShopifyVariantsBySku, {
  ShopifyVariantsBySku,
} from './useShopifyVariantsBySku';
import { ShopifyProductVariant } from '../types/generated';
import { useIsPro } from '../redux/hooks';

const packUpsellPriorityList = [
  'whoop-x-athletic-brewing-company-pack',
  'resolution-bandspack',
  'sleep-better-pack',
  'camo-pack',
  'outdoor-pack',
  'pride-pack',
  'luxe-band-pack',
  'superknit-pack',
  'starter-pack',
  'basics-pack',
  'premium-starter-pack',
  'mens-training-pack',
  'womens-training-pack',
  'off-the-wrist-pack',
  'any-wear-boxer-2-pack-new',
  'any-wear-boxer-4-pack',
];

export interface Upsell {
  packHandle: string;
  isStatic: boolean;
}

const BATTERY_PACK: Upsell = {
  packHandle: 'battery-pack',
  isStatic: true,
};

/**
 * Accepts 2 arrays and returns the matching items in the array
 * @param arr1
 * @param arr2 - this is the cart items
 */
export function diff(arr1: string[], arr2: string[]): string[] {
  const ret: string[] = [];
  arr1.sort();
  // items in cart are being sorted before this is called
  for (let i = 0; i < arr2.length; i += 1) {
    if (arr1.indexOf(arr2[i]) > -1) {
      ret.push(arr2[i]);
    }
  }
  return ret;
}

/*
 * accepts array of strings and filters it for uniqueness
 */
export function uniq(list: string[]) {
  return list.filter(function (item, pos) {
    return list.indexOf(item) === pos;
  });
}

/**
 * Takses in a list of pack handles
 * those handles are sorted by relative priority in packUpsellPriorityList
 * if a pack does not exist in the priority list return -1
 * @param packs
 */
export const getAllMatchingPacksByPriority = (packs: string[]) => {
  return packs.sort((a: string, b: string) => {
    const indexA = packUpsellPriorityList.indexOf(a);
    const indexB = packUpsellPriorityList.indexOf(b);
    // handle does not exist in the index
    if (indexA === -1 && indexB > -1) {
      return 1;
    } else if (indexB === -1 && indexA > -1) {
      return -1;
    } else {
      return indexA - indexB;
    }
  });
};

/**
 * Takes in a list of LEAF product skus and allVarients (use useShopifyVariantsBySku())
 * returns boolean
 * true if ALL of the skus in the list have a quantity under 5
 * false in all other cases
 */
export const isProductOutOfStock = (
  skus: string[],
  variants: ShopifyVariantsBySku,
) => {
  let isOOS = true;
  for (let i = 0; i < skus.length; i++) {
    // @typescript-eslint/no-unsafe-assignment
    const variant: ShopifyProductVariant | undefined = skus[i]
      ? variants?.[skus[i]]
      : undefined;
    if (variant && variant.inventoryQuantity && variant.inventoryQuantity > 5) {
      isOOS = false;
    }
  }
  return isOOS;
};

/**
 * Def of Static -> all products are leaf products and have no children
 *
 */
export const isPackStatic = (nodes: ProductNode[] | undefined) => {
  if (!nodes) {
    return false;
  }
  let isStatic = true;
  nodes.forEach((node) => {
    if ((node?.children || []).length > 0) {
      isStatic = false;
    }
    if ((node?.product_info?.items || []).length > 1) {
      isStatic = false;
    }
  });
  return isStatic;
};

interface LeafProductInfo {
  allChildHandles: string[];
  allChildSkus: string[];
  hasExclusive: boolean;
}

/**
 * Takes in ProductNode
 * returns Return Object { allChildHandles[], allChildSkus: string[]}
 */
export const getAllLeafProductInfo = (node: ProductNode): LeafProductInfo => {
  const skus: string[] = [];
  const handles = [];
  let hasExclusive = false;

  if (
    node?.children.length === 0 &&
    node?.product_info &&
    node?.product_info.items
  ) {
    handles.push(node.product_info.handle);
    node.product_info.items.forEach((item) => {
      skus.push(item.sku);
      if (item.pro_exclusive) {
        hasExclusive = true;
      }
    });
  } else {
    node.children.forEach((child) => {
      const ret = getAllLeafProductInfo(child);
      skus.push(...ret.allChildSkus);
      handles.push(...ret.allChildHandles);
    });
  }
  return {
    allChildHandles: handles,
    allChildSkus: skus,
    hasExclusive,
  };
};

/**
 * Takes in Array of ProductNodes
 * Return Object { allChildHandles[], allChildSkus: string[]}
 */
export const getAllLeafProductsInfo = (
  nodes: ProductNode[],
): LeafProductInfo => {
  const allSkus: string[] = [];
  const allHandles: string[] = [];
  let hasExclusive = false;

  nodes.forEach((node) => {
    if (!node) {
      return;
    }
    const ret: LeafProductInfo = getAllLeafProductInfo(node);
    allSkus.push(...ret.allChildSkus);
    allHandles.push(...ret.allChildHandles);
    if (ret.hasExclusive) {
      hasExclusive = true;
    }
  });

  return {
    allChildHandles: allHandles,
    allChildSkus: allSkus,
    hasExclusive,
  };
};

export const getUpsell = (
  itemsInCart: string[],
  packsObj: Optional<{ [handle: string]: ProductNode[] }>,
  isPro: boolean,
  variants: ShopifyVariantsBySku,
) => {
  // if packs have not been loaded yet return default
  if (!packsObj || itemsInCart?.length === 0) {
    return BATTERY_PACK;
  }

  // index 0 = pack-handle
  // index 1 = Array of ProductNodes
  const packs = Object.entries(packsObj);
  const allMatchesNodes = new Map<string, ProductNode[]>();
  const allMatchesItemHandles = new Map<string, string[]>();
  const allMatchesPackHandles = [];
  itemsInCart.sort();
  // get all matches
  for (let i = 0; i < packs.length; i++) {
    const leafProductInfo = getAllLeafProductsInfo(packs[i][1]);
    if (!isPro && leafProductInfo.hasExclusive) {
      continue;
    }
    const uniqueChildHandles = uniq(leafProductInfo.allChildHandles);
    const matches = diff(uniqueChildHandles, itemsInCart);
    if (matches.length >= packs[i]?.[1]?.length - 1) {
      const isOutOfStock = isProductOutOfStock(
        leafProductInfo.allChildSkus,
        variants,
      );
      if (isOutOfStock) {
        continue;
      }
      // format and add to matches list
      allMatchesPackHandles.push(packs[i][0]);
      allMatchesNodes.set(packs[i][0], packs[i][1]);
      allMatchesItemHandles.set(packs[i][0], matches);
    }
  }

  // sort all matches
  const prioritizedMatches = getAllMatchingPacksByPriority(
    allMatchesPackHandles,
  );

  // default to battery pack
  if (prioritizedMatches.length === 0) {
    return BATTERY_PACK;
  }

  // return top match that is not OOS
  return {
    packHandle: prioritizedMatches[0],
    isStatic: isPackStatic(allMatchesNodes.get(prioritizedMatches[0])),
  };
};

/**
 * Takes in a Map of pack-handles and productsNodes of pack items
 */
export const useGetUpsell = (
  itemsInCart: string[],
  packsObj: Optional<{ [handle: string]: ProductNode[] }>,
): Upsell => {
  const isPro = useIsPro();
  const varients: ShopifyVariantsBySku = useShopifyVariantsBySku();

  return useMemo(() => {
    return getUpsell(itemsInCart, packsObj, isPro, varients);
  }, [packsObj, itemsInCart]);
};
