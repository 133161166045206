import {
  Media,
  urlFromMedia,
  WhoopYourWayConfigurations,
  WhoopYourWaySelection,
} from '@whoop/web-components';
import { Optional } from '@whoop/web-components/dist/types';
import { useEffect, useMemo, useState } from 'react';
import { fetchWywProducts } from './product-service';

export function useWhoopYourWayConfigurations(): Optional<WhoopYourWayConfigurations> {
  const [wywConfigurations, setWywConfigurations] =
    useState<WhoopYourWayConfigurations>();

  useEffect(() => {
    if (!wywConfigurations) {
      fetchWywProducts().then((res) => {
        return setWywConfigurations(res);
      });
    }
  }, []);

  return wywConfigurations;
}

function findSelectionFromCompositeSku(
  compositeSku: Optional<string>,
  configurations: Optional<WhoopYourWayConfigurations>,
): Optional<WhoopYourWaySelection> {
  if (!configurations || !compositeSku) {
    return;
  }
  const split = compositeSku.split('-');
  if (split?.length !== 5) {
    return;
  }
  const [, bandCode, hookCode, claspCode, sliderCode] = split;
  const band = configurations?.band?.options?.find(
    ({ code }) => code === bandCode,
  );
  const hook = configurations?.hook?.options?.find(
    ({ code }) => code === hookCode,
  );
  const clasp = configurations?.clasp?.options?.find(
    ({ code }) => code === claspCode,
  );
  const slider = configurations?.slider?.options?.find(
    ({ code }) => code === sliderCode,
  );
  if (!band || !hook || !clasp || !slider) {
    return;
  }
  return { band, hook, clasp, slider };
}

function useWhoopYourWaySelectionFromSku(compositeSku: Optional<string>) {
  const wywConfigurations = useWhoopYourWayConfigurations();
  return useMemo(
    () => findSelectionFromCompositeSku(compositeSku, wywConfigurations),
    [compositeSku, wywConfigurations],
  );
}

function getWhoopYourWayImages(
  selection: Optional<WhoopYourWaySelection>,
  configurations: Optional<WhoopYourWayConfigurations>,
) {
  if (!selection) {
    return;
  }
  return [
    urlFromMedia(configurations?.base?.front_media as Media),
    urlFromMedia(selection?.band?.front_media),
    urlFromMedia(selection?.hook?.front_media),
    urlFromMedia(selection?.clasp?.front_media),
    urlFromMedia(selection?.slider?.front_media),
  ];
}

export function useWhoopYourWayImages(compositeSku: Optional<string>) {
  const wywConfigurations = useWhoopYourWayConfigurations();
  const selection = useWhoopYourWaySelectionFromSku(compositeSku);
  return useMemo(
    () => getWhoopYourWayImages(selection, wywConfigurations),
    [selection, wywConfigurations],
  );
}
