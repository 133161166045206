/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import styles from './subtotal.module.scss';
import Price from '../Price';
import { classes, isMarkedDown } from '../../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export interface SubtotalLine {
  label: string;
  value?: string;
  originalPrice?: string;
  whoopProPrice?: number;
  isProDiscount?: boolean;
  calculatedAtCheckout?: boolean;
}

export interface SubtotalProps {
  lines: SubtotalLine[];
  [key: string]: any; // generic props
}

const Subtotal = ({
  lines,
  className,
  ...props
}: SubtotalProps): JSX.Element => {
  const { t } = useTranslation('cart');
  return (
    <div className={classes(styles.subtotal, className)} {...props}>
      {lines?.map((line: SubtotalLine) => (
        <div key={line.label} className={styles.row} aria-label={line.label}>
          <span className={styles.label}>{line.label}</span>
          {line.calculatedAtCheckout ? (
            <span className={styles.atCheckout}>
              {t('calculatedAtCheckout')}
            </span>
          ) : (
            <Price
              className={styles.price}
              price={line.value as string}
              originalPrice={
                isMarkedDown(line.originalPrice, line.value)
                  ? line.originalPrice
                  : undefined
              }
              isProDiscount={line.isProDiscount}
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default Subtotal;
