/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import { User, UserState } from '../../types';
import { and, isFulfilled, isPending, isRejected } from '../utils';
import {
  fetchDefaultWhoopProLandingContent,
  fetchMemberWhoopProLandingContent,
  WhoopProInfo,
  WhoopProLandingContent,
} from '../../utils/membership-service';
import { Optional } from '@whoop/web-components/dist/types';
import {
  loadUser as loadWhoopUser,
  logoutUser as logoutWhoopUser,
} from '../../utils/authentication';
import { Credentials, login } from '@whoop/web-auth-client';

function isUserAction(action: AnyAction): boolean {
  return action.type.startsWith('user/');
}

export interface LoadUserPayload {
  user?: Optional<User>;
  whoopProStatus?: Optional<WhoopProInfo>;
  whoopProLandingContent?: Optional<WhoopProLandingContent>;
}

export const loadUserData = async (): Promise<LoadUserPayload | undefined> => {
  try {
    const user = await loadWhoopUser();
    if (user) {
      const res = await fetchMemberWhoopProLandingContent();
      return {
        user,
        whoopProStatus: res.whoopProInfo,
        whoopProLandingContent: res.landingContent,
      };
    }
  } catch (e) {
    console.error(e);
  }

  const landingContent = await fetchDefaultWhoopProLandingContent();
  return {
    user: null,
    whoopProStatus: null,
    whoopProLandingContent: landingContent,
  };
};

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (): Promise<LoadUserPayload | undefined> => {
    try {
      await logoutWhoopUser();
      const landingContent = await fetchDefaultWhoopProLandingContent();
      return {
        user: null,
        whoopProStatus: null,
        whoopProLandingContent: landingContent,
      };
    } catch (e) {
      console.error(e);
    }
    return {
      user: null,
      whoopProStatus: null,
      whoopProLandingContent: null,
    }; // will cause the user to be removed
  },
);

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (payload: Credentials): Promise<LoadUserPayload | undefined> => {
    await login(payload);
    return loadUserData();
  },
);

export const loadUser = createAsyncThunk(
  'user/loadUser',
  async (): Promise<LoadUserPayload | undefined> => {
    return loadUserData();
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: {} as UserState,
  reducers: {
    removeUser(state: UserState) {
      state.user = null;
      state.whoopProStatus = null;
      state.loginError = null;
      // @ts-ignore
      state.whoopProClaimReward = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(and(isUserAction, isPending), (state) => {
        state.userLoaded = false;
        state.loginError = null;
      })
      .addMatcher(
        and(isUserAction, isFulfilled),
        (state, action: PayloadAction<LoadUserPayload>) => {
          state.user = action?.payload?.user;
          state.whoopProStatus = action?.payload?.whoopProStatus;
          state.whoopProLandingContent =
            action?.payload?.whoopProLandingContent;
          state.userLoaded = true;
          state.loginError = null;
        },
      )
      .addMatcher(and(isUserAction, isRejected), (state, action) => {
        state.userLoaded = true;
        state.user = null;
        state.whoopProStatus = null;
        // @ts-ignore
        state.whoopProClaimReward = false;

        if (action?.error?.message === 'Authentication Failed') {
          state.loginError = 'invalidUserPass'; // these are translation keys
        } else {
          state.loginError = 'signinProblem'; // these are translation keys
        }
      });
  },
});

export const redirectUserToAssignedBillingRegionAction = createAction<{
  from: string;
  to: string;
}>('user/redirectToAssignedBillingRegion');

export const { removeUser } = userSlice.actions;
export default userSlice.reducer;
