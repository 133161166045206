import React from 'react';
import { navigate } from 'gatsby';
import styles from './pro-modal.module.scss';
import { Button, Icon, ProLogo } from '@whoop/web-components';
import Accordion from '../Accordion';
import { WhoopProPerkIF } from '../../utils/membership-service';
import { classes, loginLink } from '../../utils';
import IconButton from '../IconButton';
import {
  useIsEligibleForProCheckout,
  useIsSignedIn,
  useProCtaButtonContent,
  useProLandingContent,
  useProMonthlyPrice,
  useProNMonthsPrice,
  useShowProModal,
} from '../../redux/hooks';
import { useJoinWhoopPro, useToggleProModal } from '../../redux/action-hooks';
import MessageBox from '../MessageBox';
import { MessageBoxAlertType } from '../MessageBox/MessageBox';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Page } from '../Accordion/Accordion';
import { getSupportUrl } from '../../utils/regions';

export default function ProModal(): JSX.Element {
  const { t } = useTranslation('whoopPro');
  const showSignIn = !useIsSignedIn();
  const landingContent = useProLandingContent();
  const showModal = useShowProModal();
  const toggleProModal = useToggleProModal('modal');
  const buttonContent = useProCtaButtonContent();
  const joinWhoopPro = useJoinWhoopPro();
  const isEligibleForProCheckout = useIsEligibleForProCheckout();
  const monthlyPrice = useProMonthlyPrice();
  const yearlyPrice = useProNMonthsPrice(12);
  const pages: Page[] = [1, 2, 3].map((i) => ({
    id: i.toString(),
    title: t(`faq.q${i}`),
    body: t(`faq.a${i}`, { monthlyPrice, yearlyPrice }),
  }));

  const openLogin = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    toggleProModal();
    navigate(loginLink());
  };

  if (!landingContent) {
    return <></>;
  }
  return (
    landingContent && (
      <>
        <div
          className='whoop-focus-background'
          data-showing={showModal}
          style={{ zIndex: 1000 }}
          onClick={toggleProModal}
          data-event='Closed WHOOP Pro Modal'
          data-event-action='click-away'
        />
        <div className={classes(styles.modal, showModal && styles.show)}>
          <IconButton
            className={styles.closeButton}
            icon='cross'
            onClick={toggleProModal}
            data-event='Closed WHOOP Pro Modal'
            data-event-action='close-button'
          />
          <div className={styles.contentContainer}>
            <ProLogo height={60} width={60} />
            <span className={styles.title}>{landingContent.header}</span>
            {landingContent.subtitle && (
              <span className={styles.subtitle}>{landingContent.subtitle}</span>
            )}
            {landingContent.perks && (
              <ul>
                {landingContent.perks.map((perk: WhoopProPerkIF) => (
                  <li key={perk.description}>
                    <Icon name={perk.icon.name} className={styles.icon} />
                    {perk.description}
                  </li>
                ))}
              </ul>
            )}
            <h3 className={styles.ctaBody}>{landingContent.cta.title}</h3>
            <Button
              label={buttonContent}
              variant='primary'
              size='large'
              style={{
                '--color-primary': 'var(--whoop-color-pro-gold)',
                '--color-primary-contrast': 'var(--whoop-color-black)',
                fontWeight: 'bold',
              }}
              data-event={
                isEligibleForProCheckout ? 'Add Pro to Cart' : undefined
              }
              data-event-source='pro_modal'
              onClick={() => {
                toggleProModal();
                joinWhoopPro('modal');
              }}
            />
            {showSignIn && (
              <a href={loginLink()} onClick={openLogin}>
                {t('signIn')}
              </a>
            )}
            <p className={styles.footer}>{landingContent.cta.footer}</p>
          </div>
          <div className={styles.faq}>
            {!isEligibleForProCheckout && (
              <MessageBox
                alertType={MessageBoxAlertType.info}
                message={t('redirectToJoin')}
                style={{ margin: '8px 12px' }}
              />
            )}
            <Accordion pages={pages} className={styles.accordion} />
            <div className={styles.faqLink}>
              <a
                href={`${getSupportUrl('WHOOP_PRO_FAQ')}`}
                rel='noopener noreferrer'
                target='_blank'
              >
                {t('learnAbout')}
              </a>
            </div>
          </div>
        </div>
      </>
    )
  );
}
