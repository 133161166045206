import { ShopifyProductVariant } from '../types/generated';
import { Optional } from '@whoop/web-components/dist/types';
import useShopifyVariantsBySku from '../hooks/useShopifyVariantsBySku';
import useShopifyProductsByVariant from '../hooks/useShopifyProductsByVariant';
import { getMetafieldValue } from './shopify';
import { useEffect, useState } from 'react';
import { trackError } from './analytics';

export interface ShopifyProductData {
  variant?: ShopifyProductVariant;
  tags?: string[];
  earlyAccess?: Date; // this is not in the product service yet
}

function useMissingVariantErrorLogging(
  sku: Optional<string>,
  variant: Optional<ShopifyProductVariant>,
) {
  const [loggedSkus, setLoggedSkus] = useState<{ [sku: string]: true }>({});
  useEffect(() => {
    if (sku && !loggedSkus[sku] && !variant) {
      trackError(new Error(`Shopify Variant not found for sku: ${sku}`), {
        sku,
      });
      setLoggedSkus({ ...loggedSkus, [sku]: true });
    }
  }, [sku, variant]);
}

/**
 *
 * @param sku
 * @param node
 */
export function useShopifyProductData(
  sku: Optional<string>,
): ShopifyProductData {
  const variants = useShopifyVariantsBySku();
  const variant = sku ? variants?.[sku] : undefined;
  const shopifyProducts = useShopifyProductsByVariant();
  const shopifyProduct = variant && shopifyProducts?.[variant.shopifyId || ''];
  const earlyAccessMetafield =
    (getMetafieldValue(shopifyProduct, 'early_access') as string) ||
    (getMetafieldValue(variant, 'early_access') as string);
  const earlyAccess: Date | undefined = earlyAccessMetafield
    ? new Date(Date.parse(earlyAccessMetafield))
    : undefined;
  const tags = (shopifyProduct?.tags || []) as string[];
  useMissingVariantErrorLogging(sku, variant);

  return {
    variant: variant!,
    tags,
    earlyAccess,
  };
}
