import viewReducer from './viewReducer';
import userReducer from './userReducer';
import cartReducer from './cartReducer';

export default function (state = {}, action) {
  const { type } = action;
  if (type?.includes('view/')) {
    return { ...state, ...viewReducer(state, action) };
  } else if (type?.includes('user/')) {
    return { ...state, ...userReducer(state, action) };
  } else if (type?.includes('cart/')) {
    return { ...state, ...cartReducer(state, action) };
  }
  return state;
}
