import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import {
  useGetStaticRootHandle,
  useProductNode,
  useStaticProductNodes,
} from '../../hooks/staticProductHooks';
import {
  isProductItemExclusive,
  ProductItemKey,
  useProductAccordions,
} from '../../utils/productUtils';
import { useUser } from '../../redux/hooks';
import {
  PackDetails,
  ProductItem,
  ProductNode,
  PulsingLogo,
} from '@whoop/web-components';
import {
  klaviyoTrackViewedProductPage,
  trackWhoopAnalyticsEvent,
} from '../../utils/analytics';
import Seo from '../../components/Seo';
import { SHOP_CURRENCY_CODE, PATH_PREFIX } from '../../utils/regions';
import { classes, safeOpen } from '../../utils';
import styles from '../ProductPage/product-page.module.scss';
import BuyBox from '../../components/BuyBox';
import ProductHighlights from '../../components/ProductHighlights';
import { useShopifyProductData } from '../../utils/shopifyProductUtils';
import { useDiscountMessage, usePriceTag } from '../../utils/priceUtils';
import { usePackProductUrlState } from '../../hooks/urlSelectionHooks';
import JoinFlowCta from '../../components/JoinFlowCta';
import { ShopifyProduct } from '../../types/generated';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import OkendoReviewsWidget from '../../components/OkendoReviewsWidget';
import { parseShopifyId } from '../../utils/shopify';
import OkendoReviewStarsWidget from '../../components/OkendoReviewsWidget/OkendoReviewStarsWidget';
import { useIsOnForAll } from '../../utils/feature-flags';

type PackProductPageProps = {
  pageContext: {
    shopifyProduct: ShopifyProduct;
    handle: string;
  };
};

export function PackProductPage({
  pageContext: { shopifyProduct, handle },
}: PackProductPageProps): JSX.Element {
  const { t } = useTranslation('product');
  const node = useProductNode(handle);
  const packNodes = useStaticProductNodes(
    node?.product_info?.pack_details?.pack_items || [],
  );
  const [purchasable, packItems, setPackItems] = usePackProductUrlState(
    node,
    packNodes,
  );
  const accordions = useProductAccordions(purchasable?.item, node);
  const user = useUser();
  const { variant, tags, earlyAccess } = useShopifyProductData(
    purchasable?.item?.sku,
  );
  const priceTag = usePriceTag(tags, variant, purchasable);
  const discountMessage = useDiscountMessage(variant, tags);
  const isExclusive = isProductItemExclusive(
    purchasable,
    ProductItemKey.pro_exclusive,
  );
  const showReviews = useIsOnForAll('storefront-show-reviews');

  const disclaimer =
    user?.isEmployee && purchasable?.item?.employee_discount === false
      ? t('employeeBlackout')
      : undefined;

  const getStaticRootHandle = useGetStaticRootHandle();
  const onClickFullDetails = (item?: ProductItem, node?: ProductNode) => {
    const handle = getStaticRootHandle(node?.product_info?.handle || '');
    const sku = item?.sku;
    if (handle && sku) {
      safeOpen(`/${PATH_PREFIX}/products/${handle}/?sku=${sku}`, '_blank');
      trackWhoopAnalyticsEvent('Clicked full product details', {
        to_product: node?.product_info?.handle,
        to_sku: item?.sku,
      });
    }
  };

  useEffect(() => {
    // Klaviyo requires specific Shopify info
    klaviyoTrackViewedProductPage(shopifyProduct);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const productId: string | null | undefined = parseShopifyId(
    shopifyProduct?.shopifyId,
  );

  return (
    <div>
      <Seo
        title={node?.product_info?.title}
        description={node?.product_info?.description || ''}
        keywords={[node?.product_info?.title || '', 'WHOOP']}
        imageSrc={node?.product_info?.featured_media?.url}
        type='product'
        meta={[
          {
            name: 'product:price:amount',
            content: variant?.price?.toString() || '',
          },
          {
            name: 'product:price:currency',
            content: SHOP_CURRENCY_CODE,
          },
        ]}
      />
      <section className={classes(styles.productPage, 'whoop-ui')}>
        {node && (
          <PackDetails
            title={node?.product_info.title || ''}
            price={priceTag.price}
            originalPrice={priceTag.originalPrice}
            isProDiscount={priceTag.isProDiscount}
            node={node}
            value={packItems}
            onChange={setPackItems}
            onAnalyticsEvent={trackWhoopAnalyticsEvent}
            className={styles.productDetails}
            accordions={accordions}
            data-test-id='product-info'
            packNodes={packNodes}
            subTitle={discountMessage || disclaimer}
            onClickFullDetails={onClickFullDetails}
            footer={<JoinFlowCta />}
            reviewStars={
              showReviews && <OkendoReviewStarsWidget productId={productId} />
            }
          >
            <BuyBox purchasable={purchasable} />
            <ProductHighlights
              tags={tags || []}
              userStrap={user?.strap}
              userAvatar={user?.avatarUrl}
              isExclusive={isExclusive}
              earlyAccess={earlyAccess}
            />
          </PackDetails>
        )}
        {!node && (
          <div className='loading-placeholder'>
            <PulsingLogo color='dark' size='large' />
          </div>
        )}
        {showReviews && <OkendoReviewsWidget productId={productId} />}
      </section>
    </div>
  );
}

export const translationsQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PackProductPage;
