/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import styles from './user-avatar.module.scss';
// @ts-ignore
import avatar from '../../images/anonymous_user.png';
import { Icon } from '@whoop/web-components';
import { classes } from '../../utils';

export default function UserAvatar({
  avatarUrl,
  status,
}: {
  avatarUrl?: string;
  status?: 'success' | 'warning' | 'error';
}): JSX.Element {
  const icon = status && status === 'success' ? 'check' : 'attention';

  return (
    <div className={styles.avatarContainer}>
      <img
        className={styles.avatar}
        src={avatarUrl || avatar}
        alt={`user avatar`}
      />
      {status && (
        <Icon
          className={classes(styles.avatarStatus, styles[status])}
          name={icon}
        />
      )}
    </div>
  );
}
