/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CartState } from '../../types';
import {
  getInitialCheckout,
  freeRewardLineItem,
  shopifyClient,
} from '../../utils/shopify';
import {
  fetchMemberWhoopProClaimFreeReward,
  updateMemberWhoopProClaimFreeReward,
} from '../../utils/membership-service';
import { ShopifyStorefront } from '../../types/shopify-storefront';
import { and, isFulfilled, isPending, isRejected } from '../utils';
import { ShopifyCheckoutId } from '../../utils/storage';
import { Optional, ProductItem } from '@whoop/web-components';

export interface AddItemToCartPayload {
  variantId: string;
  quantity: number;
  customAttributes?: any[];
  is3d?: boolean;
  item?: ProductItem;
}

export interface UpdateLineItemQuantityPayload {
  lineItemId: string;
  quantity: number;
}

export interface RemoveItemFromCartPayload {
  lineItemId: string;
}

export interface CartUpdate {
  checkout: ShopifyStorefront.Checkout;
  freeRewardLineItem: Optional<ShopifyStorefront.CheckoutLineItem>;
  whoopProClaimReward?: boolean;
}

function isCartAction(action: AnyAction): boolean {
  return action.type.startsWith('cart/');
}

export const parseShopifyError = (error: { message: string }): string => {
  const shopErr = error.message;
  try {
    // Shopify js Buy errors comes as errors, just show the first one:
    return JSON.parse(shopErr)[0].message;
  } catch (e) {
    return 'Something went wrong. Try again later.';
  }
};

/**
 * Loads the cart from local storage or initializes a new one.
 */
export const loadCart = createAsyncThunk('cart/loadCart', async () => {
  const checkout = await getInitialCheckout();
  const whoopProClaimReward = await fetchMemberWhoopProClaimFreeReward();
  return {
    checkout,
    whoopProClaimReward,
    freeRewardLineItem: freeRewardLineItem(checkout),
  };
});

export const newCart = createAsyncThunk('cart/newCart', async () => {
  ShopifyCheckoutId.remove();
  await updateMemberWhoopProClaimFreeReward(true);
  const checkout = await getInitialCheckout();
  const whoopProClaimReward = await fetchMemberWhoopProClaimFreeReward();
  return {
    checkout,
    whoopProClaimReward,
    freeRewardLineItem: freeRewardLineItem(checkout),
  };
});

/**
 * Adds an item to cart given a variant id and a quantity.
 */
export const addItemToCart = createAsyncThunk(
  'cart/addVariantToCart',
  async (
    { variantId, quantity, customAttributes }: AddItemToCartPayload,
    thunkAPI,
  ): Promise<CartUpdate> => {
    await updateMemberWhoopProClaimFreeReward(true);
    const checkoutId = (thunkAPI.getState() as CartState).checkout?.id;
    const lineItemsToUpdate = [
      { variantId, quantity: parseInt(String(quantity), 10), customAttributes },
    ];
    const checkout = await shopifyClient.checkout.addLineItems(
      checkoutId,
      lineItemsToUpdate,
    );
    return {
      checkout,
      freeRewardLineItem: freeRewardLineItem(checkout),
      whoopProClaimReward: true,
    };
  },
);

function removeCheckoutLineItem(
  checkoutId?: string,
  lineItemId?: string,
): Promise<ShopifyStorefront.Checkout> {
  return shopifyClient.checkout.removeLineItems(checkoutId, [lineItemId]);
}

/**
 * Removes a given line item id.
 */
export const removeLineItem = createAsyncThunk(
  'cart/removeLineItem',
  async ({ lineItemId }: RemoveItemFromCartPayload, thunkAPI) => {
    const checkoutId = (thunkAPI.getState() as CartState).checkout?.id;
    const checkout = await removeCheckoutLineItem(checkoutId, lineItemId);
    return { checkout, freeRewardLineItem: freeRewardLineItem(checkout) };
  },
);

/**
 * Updates the quantity of a given line item id or removes it if <= 0.
 */
export const updateLineItem = createAsyncThunk(
  'cart/updateLineItem',
  async ({ lineItemId, quantity }: UpdateLineItemQuantityPayload, thunkAPI) => {
    const checkoutId = (thunkAPI.getState() as CartState).checkout?.id;
    if (quantity <= 0) {
      const checkout = await removeCheckoutLineItem(checkoutId, lineItemId);
      return { checkout, freeRewardLineItem: freeRewardLineItem(checkout) };
    } else {
      const lineItemsToUpdate = [
        { id: lineItemId, quantity: parseInt(String(quantity), 10) },
      ];
      const checkout = (await shopifyClient.checkout.updateLineItems(
        checkoutId,
        lineItemsToUpdate,
      )) as ShopifyStorefront.Checkout;
      return { checkout, freeRewardLineItem: freeRewardLineItem(checkout) };
    }
  },
);

/**
 * Updates the quantities of multiple line items in the cart with a single
 * GraphQL call to Shopify
 */
export const updateCartLineItems = createAsyncThunk(
  'cart/updateCartLineItems',
  async (
    lineItems: ShopifyStorefront.CheckoutLineItemUpdateInput[],
    thunkAPI,
  ) => {
    const checkoutId = (thunkAPI.getState() as CartState).checkout?.id;
    const checkout = (await shopifyClient.checkout.updateLineItems(
      checkoutId,
      lineItems,
    )) as ShopifyStorefront.Checkout;
    return { checkout, freeRewardLineItem: freeRewardLineItem(checkout) };
  },
);

/**
 * Replaces the line items in the cart with a new list of items.
 *
 * @see <a href="https://shopify.dev/api/storefront/2022-07/mutations/checkoutLineItemsReplace">checkoutLineItemsReplace</a>
 */
export const replaceCartLineItems = createAsyncThunk(
  'cart/replaceCartLineItems',
  async (lineItems: ShopifyStorefront.CheckoutLineItemInput[], thunkAPI) => {
    const checkoutId = (thunkAPI.getState() as CartState).checkout?.id;
    const checkout = (await shopifyClient.checkout.replaceLineItems(
      checkoutId,
      lineItems,
    )) as ShopifyStorefront.Checkout;
    return { checkout, freeRewardLineItem: freeRewardLineItem(checkout) };
  },
);

export const claimReward = createAsyncThunk(
  'cart/claimReward',
  async (flag: boolean, thunkAPI) => {
    // Update the customer's tags to claim/un-claim the free reward
    await updateMemberWhoopProClaimFreeReward(flag);
    const checkoutId = (thunkAPI.getState() as CartState).checkout?.id;
    // Refresh the Shopify checkout, to show updates after the discount script runs
    const checkout = await shopifyClient.checkout.fetch(checkoutId);
    return {
      checkout,
      freeRewardLineItem: freeRewardLineItem(checkout),
      whoopProClaimReward: flag,
    };
  },
);

const slice = createSlice({
  name: 'cart',
  initialState: {} as CartState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(and(isCartAction, isPending), (state, action) => {
        state.mutatingCheckout = true;
        if (action.type.includes('claimReward')) {
          // set temporarily. Will be properly set if it fails
          state.whoopProClaimReward = action?.meta.arg;
        }
      })
      .addMatcher(and(isCartAction, isFulfilled), (state, action) => {
        if (action.payload) {
          const { checkout, whoopProClaimReward, freeRewardLineItem } =
            action.payload;
          if (checkout) {
            state.checkout = checkout;
            state.cartSkus = new Set(
              checkout.lineItems?.map(
                (item: ShopifyStorefront.CheckoutLineItem) =>
                  item?.variant?.sku,
              ) || [],
            );
          }
          if (typeof whoopProClaimReward !== 'undefined') {
            state.whoopProClaimReward = whoopProClaimReward;
          }
          state.freeRewardLineItem = freeRewardLineItem;
          state.mutatingCheckout = false;
        }
      })
      .addMatcher(and(isCartAction, isRejected), (state, action) => {
        // error is handled by each individual thunk since it requires dispatch to surface error to user
        state.mutatingCheckout = false;
        if (action.type.includes('claimReward')) {
          // unset if claimReward fails
          state.whoopProClaimReward = !action?.meta.arg;
        }
        // @ts-ignore: TODO: global error for now but will be cart error in the future
        state.error = {
          message: parseShopifyError(action.error),
        };
      });
  },
});

export default slice.reducer;
