import React from 'react';
import styles from './alert.module.scss';
import { Icon } from '@whoop/web-components';
import { classes } from '../../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Alert = ({ message, onDismiss, className }) => {
  const { t } = useTranslation('errors');
  return (
    <div className={classes(styles.alert, className)}>
      <div className={styles.iconSection}>
        <Icon name='disturbances' className={styles.alertIcon} />
      </div>
      <p className={styles.message} aria-label={t('aria.alertDescription')}>
        {message}
      </p>
      <div className={styles.control}>
        <button onClick={onDismiss} aria-label={t('aria.dismiss')}>
          <Icon name='cross' className={styles.dismissIcon} />
        </button>
      </div>
    </div>
  );
};

export default Alert;
