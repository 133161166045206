import React from 'react';
import styles from './icon-button.module.scss';
import { classes } from '../../utils';
import { Icon } from '@whoop/web-components';

type IconButtonProps = {
  className?: string;
  icon: string;
};

export default function IconButton({
  className,
  icon,
  ...props
}: IconButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <button {...props} className={classes(className, styles.iconButton)}>
      <Icon name={icon} />
    </button>
  );
}
