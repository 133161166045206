import { graphql, useStaticQuery } from 'gatsby';

const WhoopSkuQuery = graphql`
  query ProductServiceSkusQuery {
    allWhoopProduct {
      nodes {
        title
        items {
          sku
          title
        }
        message
      }
    }
  }
`;

interface item {
  sku: string;
  title: string;
}

interface WhoopSkuResult {
  title: string;
  items: item[];
  message: string;
}
export interface SkuMapping {
  [sku: string]: {
    title: string;
    variantTitle: string;
    message: string;
  };
}

function useProductServiceSkuMapping(): SkuMapping {
  const result: SkuMapping = {};
  useStaticQuery<{
    allWhoopProduct: {
      nodes: WhoopSkuResult[];
    };
  }>(WhoopSkuQuery)?.allWhoopProduct?.nodes?.forEach(
    ({ title, items, message }: WhoopSkuResult) => {
      items.forEach(
        (item: item) =>
          (result[item.sku] = {
            title: title,
            variantTitle: item.title,
            message: message,
          }),
      );
    },
  );

  return result;
}

export default useProductServiceSkuMapping;
