import fetch from 'isomorphic-fetch';
import Client from '@whoop/shopify-buy';
import _ from 'lodash';
import { ShopifyAccessToken, ShopifyCheckoutId, WhoopUser } from './storage';
import { fromBase64, handleize, safeOpen } from './index';
import { CHECKOUT_FROM_CART, trackCheckout, trackError } from './analytics';
import { SHOPIFY_CDN_URL } from './regions';
import { sendSentryError } from './wrapped-sentry';

export const shopifyClient = Client.buildClient(
  {
    storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
    domain: `${process.env.SHOP_NAME}.myshopify.com`,
  },
  fetch,
);

// https://shopify.dev/docs/storefront-api/reference/customers/customeraccesstokencreatewithmultipass
const SHOPIFY_CREATE_CUSTOMER_WITH_MULTIPASS = `mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
  customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;
// https://shopify.dev/docs/storefront-api/reference/customers/customeraccesstokendelete
const SHOPIFY_DELETE_ACCESS_TOKEN = `mutation customerAccessTokenDelete($customerAccessToken: String!) {
  customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
    deletedAccessToken
    deletedCustomerAccessTokenId
    userErrors {
      field
      message
    }
  }
}`;
// https://shopify.dev/docs/storefront-api/reference/checkouts/checkoutcustomerassociatev2
const SHOPIFY_ASSOCIATE_CHECKOUT_WITH_CUSTOMER = `mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
  checkoutCustomerAssociateV2(
    checkoutId: $checkoutId
    customerAccessToken: $customerAccessToken
  ) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
    customer {
      id
    }
  }
}`;
// https://shopify.dev/docs/storefront-api/reference/checkouts/checkoutcustomerdisassociatev2
// eslint-disable-next-line no-unused-vars
const SHOPIFY_DISASSOCIATE_CHECKOUT_WITH_CUSTOMER = `mutation checkoutCustomerDisassociateV2($checkoutId: ID!) {
  checkoutCustomerDisassociateV2(checkoutId: $checkoutId) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`;
const SHOPIFY_QUERY_CUSTOMER = `query getCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      lastIncompleteCheckout {
        id
      }
      tags
    }
}`;

// https://shopify.dev/api/storefront/2022-04/mutations/checkoutGiftCardsAppend
const APPLY_GIFT_CARD = `mutation checkoutGiftCardsAppend($checkoutId: ID!, $giftCardCodes: [String!]!) {
  checkoutGiftCardsAppend(checkoutId: $checkoutId, giftCardCodes: $giftCardCodes) {
    checkout {
      id
    }
    checkoutUserErrors {
     code 
     field
     message
    }
  }
}`;
/**
 * Runs a shopify GraphQL Query.
 * Variables must match query for example, if the query includes something like
 *    `query myQuery($myVar1: String!, $myVar2: String!) {...}`
 *   Then variables must include `myVar1` & `myVar2`
 *      i.e. { myVar1: "some string", myVar2: "some other string" }
 *
 * @param query a GraphQL query string
 * @param variables optional variables object (MUST MATCH QUERY)
 * @param apiVersion optional api version default is `2021-01`
 */
export const makeShopifyGraphQLQuery = async function (
  query,
  variables,
  apiVersion = '2023-07',
) {
  const response = await fetch(
    `https://${process.env.SHOP_NAME}.myshopify.com/api/${apiVersion}/graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'x-shopify-storefront-access-token': process.env.SHOPIFY_ACCESS_TOKEN,
      },
      body: JSON.stringify({
        query: query,
        variables: variables,
      }),
    },
  );

  return response.json();
};

/**
 * https://shopify.dev/docs/storefront-api/reference/customers/customeraccesstokencreatewithmultipass
 *
 * @param multipassToken
 * @returns {Promise<{accessToken: any, expiresAt: any}>}
 */
export const createShopifyAccessToken = async function (multipassToken) {
  const response = await makeShopifyGraphQLQuery(
    SHOPIFY_CREATE_CUSTOMER_WITH_MULTIPASS,
    { multipassToken },
  );

  if (!response.errors) {
    return response?.data?.customerAccessTokenCreateWithMultipass
      ?.customerAccessToken;
  }
  sendSentryError(
    new Error(
      `Error logging in with multipass token: ${JSON.stringify(response)}`,
    ),
  );
  throw new Error('Operation failed');
};

/**
 * Fetches the current Shopify customer given an access token
 * @param customerAccessToken
 */
export const fetchShopifyCustomer = async (customerAccessToken) => {
  const response = await makeShopifyGraphQLQuery(SHOPIFY_QUERY_CUSTOMER, {
    customerAccessToken,
  });
  if (!response.errors) {
    return response?.data?.customer;
  }
  throw new Error(response.errors);
};

const getShopifyUserFromAccessToken = async function (customerAccessToken) {
  const response = await makeShopifyGraphQLQuery(SHOPIFY_QUERY_CUSTOMER, {
    customerAccessToken,
  });
  if (!response.errors) {
    return {
      shopifyUserId: _.get(response, 'data.customer.id'),
      lastCheckoutId: _.get(
        response,
        'data.customer.lastIncompleteCheckout.id',
      ),
      tags: _.get(response, 'data.customer.tags'),
    };
  }
  throw new Error(response.errors);
};

// Given the locally saved and the shopify saved user checkout
// It will fetch ot create a checkout from shopify
const getCheckoutFromCheckoutIds = async function (
  browserSavedCheckoutId,
  shopifySavedCheckoutId,
  customerAccessToken,
) {
  let newCheckout;
  // TODO: remove this when testing is done

  // const preferredCheckoutId =
  //   (window.Cypress ? undefined : shopifySavedCheckoutId) ||
  //   browserSavedCheckoutId;
  // current theory is that the browser saved checkout should NEVER be used as a source of truth unless
  // it is the only one available and the user is NOT signed in
  const preferredCheckoutId = window.Cypress
    ? undefined
    : shopifySavedCheckoutId;
  if (preferredCheckoutId) {
    newCheckout = await shopifyClient.checkout.fetch(preferredCheckoutId);
    if (newCheckout && newCheckout.completedAt) {
      // invalidate completed carts
      newCheckout = null;
    }
  } else if (
    !preferredCheckoutId &&
    browserSavedCheckoutId &&
    !customerAccessToken
  ) {
    newCheckout = await shopifyClient.checkout.fetch(browserSavedCheckoutId);
    if (newCheckout && newCheckout.completedAt) {
      // invalidate completed carts
      newCheckout = null;
    }
  }

  // validate checkout doesn't have any invalid items
  if (newCheckout) {
    const hasInvalidItems = newCheckout.lineItems.reduce(
      (acc, item) => acc || !item.variant,
      false,
    );
    if (hasInvalidItems) {
      newCheckout = null;
    }
  }

  if (!newCheckout) {
    newCheckout = await shopifyClient.checkout.create();
  }
  return newCheckout;
};

/**
 * Makes call to Shopify toinvalidate a customer access token
 * @param customerAccessToken
 */
export const invalidateShopifyAccessToken = (customerAccessToken) => {
  return makeShopifyGraphQLQuery(SHOPIFY_DELETE_ACCESS_TOKEN, {
    customerAccessToken,
  });
};

/**
 * Associates a
 * @param checkoutId
 * @param customerAccessToken
 */
export const associateCheckoutWithCustomer = (
  checkoutId,
  customerAccessToken,
) => {
  return makeShopifyGraphQLQuery(SHOPIFY_ASSOCIATE_CHECKOUT_WITH_CUSTOMER, {
    checkoutId,
    customerAccessToken,
  });
};

export const applyGiftCard = (checkoutId, giftCardCodes) => {
  return makeShopifyGraphQLQuery(APPLY_GIFT_CARD, {
    checkoutId,
    giftCardCodes,
  });
};

/**
 * Handles initial checkout logic including loading a cart if the user is currently logged in.
 */
export const getInitialCheckout = async function () {
  const browserSavedCheckoutId = ShopifyCheckoutId.get();
  const customerAccessToken = ShopifyAccessToken.get();
  let shopifySavedCheckoutId;

  if (customerAccessToken) {
    const user = await getShopifyUserFromAccessToken(customerAccessToken);
    shopifySavedCheckoutId = user.lastCheckoutId;
  }

  const newCheckout = await getCheckoutFromCheckoutIds(
    browserSavedCheckoutId,
    shopifySavedCheckoutId,
    customerAccessToken,
  );
  ShopifyCheckoutId.set(newCheckout.id);

  if (customerAccessToken) {
    await associateCheckoutWithCustomer(newCheckout.id, customerAccessToken);
  }

  return newCheckout;
};

// same timestamp from build time
const CDN_TIMESTAMP = parseInt(new Date().getTime() / 1000);
export const shopifyCdnImage = function (path) {
  return `https://${SHOPIFY_CDN_URL}/files/${path}?v=${CDN_TIMESTAMP}`;
};

export const createColorSwatchImageUrl = function (value) {
  return shopifyCdnImage(`${handleize(value)}_64x64.png`);
};

export const parseShopifyId = function (base64ShopifyId) {
  if (!base64ShopifyId) return null;

  try {
    const decoded = fromBase64(base64ShopifyId);
    return splitShopifyGid(decoded);
  } catch (e) {
    // fromBase64() can throw an exception if the string is a malformed URI component. It may be a non-encoded string,
    // so attempt to split it as-is:
    return splitShopifyGid(base64ShopifyId);
  }
};

const splitShopifyGid = function (shopifyGid) {
  const split = shopifyGid.split('/');

  if (!split || !split[0] || split[0] !== 'gid:') {
    return null;
  }
  return split[split.length - 1];
};

export const getGoogleAnalyticsTrackingParams = () => {
  return new Promise((resolve) => {
    if (typeof ga === 'undefined') {
      return resolve();
    }
    window.ga((tracker) => {
      resolve(tracker.get('linkerParam'));
    });

    // automatically resolve if ga doesn't give us the tracker
    setTimeout(resolve, 2e3);
  });
};

export const goToCheckout = async function (
  checkout,
  checkoutItemMap,
  giftCards,
  source = CHECKOUT_FROM_CART,
) {
  // WhoopUser.get() returns a string - need to parse it to get the values
  const user = JSON.parse(WhoopUser.get());
  trackCheckout(checkout, checkoutItemMap, source, !!user);
  let url = checkout.webUrl;
  if (url === undefined) {
    trackError('Checkout missing webUrl', { checkout });
    return;
  }
  try {
    const deviceId = window.amplitude?.getInstance().options?.deviceId; // existing device id
    if (deviceId) {
      url += `&deviceId=${deviceId}`;
    }
    const googleAnalyticsParams = await getGoogleAnalyticsTrackingParams();
    if (googleAnalyticsParams) {
      url += `&${googleAnalyticsParams}`;
    }
    if (giftCards && giftCards.length > 0) {
      // TODO - make an api call to get the giftcard expiration
      // TODO - order the giftcard based on expiration date
      await applyGiftCard(checkout.id, giftCards);
    }
    let locale = process.env.SHOP_LANGUAGE;
    if (locale) {
      // this adjusts locale for the checkout url via shopify for Portuguese only
      // e.g. https://whoop-staging-europe.myshopify.com/81226334509/checkouts/c3aec353c2655913ddac710681874341?_ga=2.55291051.1885778538.1709680125-2064224386.1709680125&deviceId=5fJRXcE71pj6gePCNdF0Jm&locale=pt-PT&no_cookies_from_redirect=1
      // Portuguese translations break if locale=pt vs locale=pt-PT
      // There is no default 'pt' translation, only dialects. Documentation here: https://help.shopify.com/en/manual/markets/languages/translate#available-translations
      locale = locale === 'pt' ? locale + '-PT' : locale;
      url += `&locale=${locale}`;
    }
    if (user?.email) {
      url += `&checkout[email]=${encodeURIComponent(user?.email)}`;
    }
  } catch (e) {
    console.error(e);
  }
  safeOpen(url, '_self');
};

export const hasColorOptions = (options) => {
  return options && options.some((option) => option.name === 'Color');
};

export const getColorFromSelectedOptions = (selectedOptions) => {
  return selectedOptions?.find((option) => option.name === 'Color')?.value;
};

export const getSizeFromSelectedOptions = (selectedOptions) => {
  return selectedOptions?.find((option) => option.name === 'Size')?.value;
};

export const getInseamFromSelectedOptions = (selectedOptions) => {
  return selectedOptions?.find((option) => option.name === 'Inseam')?.value;
};

export const freeRewardLineItem = (checkout) => {
  // The free reward discount is applied via a Shopify script rather than via a standard
  // DiscountCodeApplication. To determine if the reward discount has been applied, look
  // over the items to see one has the script discount referenced in its discountAllocations
  // list.
  if (checkout.lineItems)
    return checkout.lineItems.find((item) => {
      return (
        !!item.discountAllocations &&
        !!item.discountAllocations.find((allocation) => {
          return (
            allocation.discountApplication?.__typename ===
              'ScriptDiscountApplication' &&
            allocation.discountApplication?.description ===
              'Free Reward Applied'
          );
        })
      );
    });
};

export const fetchShopifyProduct = (shopifyId) =>
  shopifyClient.product.fetch(shopifyId);

export const getMetafieldValue = (resource, key) =>
  resource?.metafields?.find((metafield) => metafield?.key === key)?.value;

// Takes in a ShopifyProduct instance, fetches the latest product data via
// the Shopify Client, and returns a ShopifyProduct instance with the
// availability/quantity-available for the product and each of its variants
// updated to reflect the latest data.
export const getUpdatedAvailability = async (product) => {
  const fetchedProduct = await fetchShopifyProduct(product.shopifyId);
  product.availableForSale = fetchedProduct.availableForSale;
  product.quantityAvailable = fetchedProduct.quantityAvailable;
  if (product.variants) {
    product.variants.forEach((variant, i) => {
      const updated = fetchedProduct.variants.find(
        (v) => v.id === variant?.shopifyId,
      );
      if (updated) {
        product.variants[i].quantityAvailable = updated.quantityAvailable;
        product.variants[i].availableForSale = updated.available;
      }
    });
  }
  return product;
};

export const stitchProductServiceDataCart = (lineItem, productServiceData) => {
  return {
    ...lineItem,
    title: productServiceData.title,
    variant: {
      ...lineItem.variant,
      title: productServiceData.variantTitle,
    },
  };
};
