import React from 'react';
import styles from './message-box.module.scss';
import InfoIcon from '../../assets/alert-info-icon.svg';
import SuccessIcon from '../../assets/alert-success-icon.svg';
import WarningIcon from '../../assets/alert-warning-icon.svg';
import ErrorIcon from '../../assets/alert-error-icon.svg';
import { classes } from '../../utils';

export enum MessageBoxAlertType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export type MessageBoxProps = {
  alertType: MessageBoxAlertType;
  message?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const getIcon = (alertType: MessageBoxAlertType) => {
  switch (alertType) {
    case MessageBoxAlertType.info:
      return <InfoIcon />;
    case MessageBoxAlertType.success:
      return <SuccessIcon />;
    case MessageBoxAlertType.warning:
      return <WarningIcon />;
    case MessageBoxAlertType.error:
      return <ErrorIcon />;
  }
};

const getStyle = (alertType: MessageBoxAlertType) => {
  switch (alertType) {
    case MessageBoxAlertType.info:
      return styles.info;
    case MessageBoxAlertType.success:
      return styles.success;
    case MessageBoxAlertType.warning:
      return styles.warning;
    case MessageBoxAlertType.error:
      return styles.error;
  }
};

export default function MessageBox(props: MessageBoxProps): JSX.Element {
  const { alertType, message, className, children, ...rest } = props;

  return (
    <div
      className={classes(styles.messageBox, getStyle(alertType), className)}
      {...rest}
    >
      <div className={styles.iconSection}>{getIcon(alertType)}</div>
      <p className={styles.message}>{message || children}</p>
    </div>
  );
}
