import { Optional } from '@whoop/web-components';

export interface NavigationGroup {
  label: string;
  link: string;
  children?: Optional<NavigationGroup[]>;
}

export const getSeperatedGroups = (
  items: NavigationGroup[],
): NavigationGroup[][] => {
  const regex = /^\s/;
  const navGroups: NavigationGroup[][] = [];
  let start = 0;
  items.forEach((item, index) => {
    if (
      (index !== 0 &&
        !regex.test(item.label) &&
        regex.test(items[index - 1].label)) ||
      (index !== 0 &&
        !regex.test(item.label) &&
        !regex.test(items[index - 1].label))
    ) {
      navGroups.push(items.slice(start, index));
      start = index;
    }
    if (index + 1 === items.length) {
      navGroups.push(items.slice(start, index + 1));
    }
  });
  return navGroups;
};
