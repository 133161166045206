import { graphql, useStaticQuery } from 'gatsby';
import { ShopifyProduct, ShopifyProductConnection } from '../types/generated';
import { useMemo } from 'react';

const CompatibilitySkusQuery = graphql`
  query ShopifyProducts {
    allShopifyProduct {
      nodes {
        handle
        productType
        variants {
          shopifyId
          inventoryQuantity
        }
        tags
        metafields {
          key
          value
        }
      }
    }
  }
`;

export interface ProductsByVariantId {
  [shopifyId: string]: ShopifyProduct;
}

function useShopifyProductsByVariant(): ProductsByVariantId {
  const queryResult = useStaticQuery<{
    allShopifyProduct: ShopifyProductConnection;
  }>(CompatibilitySkusQuery);
  return useMemo<ProductsByVariantId>(() => {
    const productVariantMap: ProductsByVariantId = {};
    queryResult?.allShopifyProduct?.nodes?.forEach((product) => {
      product?.variants?.forEach((variant) => {
        if (variant && variant.shopifyId) {
          productVariantMap[variant.shopifyId] = product;
        }
      });
    });
    return productVariantMap;
  }, [queryResult]);
}

export default useShopifyProductsByVariant;
