/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Optional } from '@whoop/web-components/dist/types';
import { User } from '../types';
import { getAccessToken, logout } from '@whoop/web-auth-client';
import { ShopifyAccessToken, WhoopUser } from './storage';
import {
  fetchMultipassToken,
  StorefrontUser,
  syncShopifyTags,
} from './membership-service';
import {
  createShopifyAccessToken,
  invalidateShopifyAccessToken,
} from './shopify';
import avatar from '../images/anonymous_user.png';

const convertUser = (storefrontUser: StorefrontUser): User => {
  const userStrap =
    storefrontUser.straps?.ordered_strap?.generation ||
    storefrontUser.straps?.last_seen_strap?.generation;
  return {
    id: storefrontUser.id!,
    isLoggedIn: true!,
    isEmployee: storefrontUser.is_employee!,
    firstName: storefrontUser.first_name!,
    lastName: storefrontUser.last_name!,
    fullName: `${storefrontUser.first_name!} ${storefrontUser.last_name!}`!,
    avatarUrl: storefrontUser.avatar_url ? storefrontUser.avatar_url : avatar,
    strap: userStrap!,
    couldUpgradeForFree: storefrontUser.could_upgrade_for_free!,
    upgradeRequiresAnnualPlan: storefrontUser.upgrade_requires_annual_plan!,
    upgradeProRatedMonths: storefrontUser.upgrade_pro_rated_months!,
    billingRegion: storefrontUser.billing_region!,
    featureFlags: storefrontUser.feature_flags!,
    email: storefrontUser.email,
  };
};

/**
 * Remove all of the user data
 */
export const logoutUser = async () => {
  const customerAccessToken: Optional<string> = ShopifyAccessToken.get();
  await logout();
  ShopifyAccessToken.remove();
  WhoopUser.remove();
  // make that Shopify access token invalid to log them out globally
  await invalidateShopifyAccessToken(customerAccessToken);
};

/**
 * Loads the user if it exists in local storage or can be loaded from access token.
 */
export const loadUser = async (): Promise<Optional<User>> => {
  const accessToken = await getAccessToken().catch((err) => console.error(err));
  if (!accessToken) {
    await logoutUser(); // clear credentials if access token is invalid
    return;
  }

  // return user if it's already in local storage
  const savedUserStr = WhoopUser.get();
  const whoopUser =
    savedUserStr && (JSON.parse(savedUserStr) as Optional<User>);
  if (whoopUser) {
    await syncShopifyTags(); // update tags before returning
    return whoopUser;
  }

  try {
    const { multipass_token, user } = await fetchMultipassToken();
    const { accessToken: shopifyAccessToken } = await createShopifyAccessToken(
      multipass_token,
    );
    const convertedUser = convertUser(user);

    // persist
    ShopifyAccessToken.set(shopifyAccessToken);
    WhoopUser.set(JSON.stringify(convertedUser));

    await syncShopifyTags();
    return convertedUser;
  } catch (err) {
    console.error(err);
    // if this fails we want to cleanup
    ShopifyAccessToken.remove();
    WhoopUser.remove();
  }
};
