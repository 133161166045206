import { graphql, useStaticQuery } from 'gatsby';
import { ShopifyProduct } from '../types/generated';

const WhoopYourWayProducts = graphql`
  query getWhoopYourWayProduct {
    shopifyProduct(handle: { eq: "whoop-your-way-kit" }) {
      shopifyId
      handle
      tags
    }
  }
`;

function useWhoopYourWayProduct(): ShopifyProduct {
  const queryResult = useStaticQuery<{
    shopifyProduct: ShopifyProduct;
  }>(WhoopYourWayProducts);
  return queryResult.shopifyProduct;
}

export default useWhoopYourWayProduct;
