import { useLayoutEffect, useState } from 'react';

const hasNavigator =
  typeof window !== 'undefined' && typeof window.navigator !== 'undefined';

const isChrome = hasNavigator && navigator.userAgent.indexOf('Chrome') > -1;
let isSafari = hasNavigator && navigator.userAgent.indexOf('Safari') > -1;

if (isChrome && isSafari) {
  isSafari = false;
}

const isApplePay = hasNavigator && !!window.ApplePaySession;

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export { isChrome, isSafari, isApplePay, useWindowSize };
