import { trackWhoopAnalyticsEvent } from '../analytics';
const APP_ID = process.env.INTERCOM_API_KEY;

export const initIntercom = () => {
  // this is generated code from intercom :)
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('onUserEmailSupplied', (e) => {
      trackWhoopAnalyticsEvent('Intercom - Shop: Email Provided');
    });
    window.Intercom('onUnreadCountChange', function (unreadCount) {
      if (unreadCount > 0) {
        trackWhoopAnalyticsEvent('Intercom - Shop: Message Received');
      }
    });
    window.Intercom('boot', {
      app_id: APP_ID,
    });
  }
};
