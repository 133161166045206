import React from 'react';
import styles from './join-flow-cta.module.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { JOIN_URL } from '../../utils/regions';

interface JoinFlowCtaProps {
  className?: string;
  joinFlowCareText?: boolean;
}

export default function JoinFlowCta(props: JoinFlowCtaProps): JSX.Element {
  const { t } = useTranslation('joinFlowCta');
  const joinFlowCtaText = props.joinFlowCareText
    ? 'joinFlowCareCta'
    : 'joinFlowCta';
  const joinFlowCtaHtml = t(joinFlowCtaText, {
    joinFlowLink: `<a href='${JOIN_URL}' target='_blank'>join.whoop.com</a>`,
  });
  return (
    <div {...props} className={styles.ctaText}>
      <p dangerouslySetInnerHTML={{ __html: joinFlowCtaHtml }} />
    </div>
  );
}
