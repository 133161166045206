import { parse } from 'node-html-parser';
import { unescapeHtml } from '../utils';
import { graphql, useStaticQuery } from 'gatsby';
import selectLocalePage from './selectLocalePage';
import { useNavigationVariant } from '../utils/experimentUtils';

const NavigationQuery = graphql`
  query getNavigation {
    allWhoopPage(filter: { handle: { regex: "/navigation.*/" } }) {
      edges {
        node {
          handle
          body
        }
      }
    }
  }
`;

const parseNavigationData = function (pageHtml) {
  const parseNode = (node) => {
    const tree = [];
    let last;
    node?.querySelectorAll(':scope > *').forEach((el) => {
      if (el && el.rawTagName === 'li') {
        last = {
          label: unescapeHtml(el.innerText?.replace(/\n/g, '')),
          link: el.querySelector('a')?.attributes?.href,
        };
        tree.push(last);
      } else if (el && el.rawTagName === 'ul' && last) {
        last.children = parseNode(el);
      }
    });
    return tree;
  };

  try {
    const body = parse(pageHtml);
    return parseNode(body.querySelector(':scope > ul'));
  } catch (e) {
    console.error(e);
  }
  return [];
};

const useNavigationTree = () => {
  const { allShopifyPage, allWhoopPage } = useStaticQuery(NavigationQuery);
  const useVariant = useNavigationVariant();
  const navigationPage = selectLocalePage(
    useVariant ? 'navigation-variant' : 'navigation',
    allShopifyPage,
    allWhoopPage,
  );
  return parseNavigationData(navigationPage.body);
};

export default useNavigationTree;
