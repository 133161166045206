import Fuse from 'fuse.js';
import { FullProduct } from '../hooks/useAllProducts';

export function fuseSearch(
  data: FullProduct[],
  query: string,
): FuseSearchResults[] {
  const options = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 3,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    fieldNormWeight: 1,
    keys: [
      'title',
      'color',
      'handle',
      'tags',
      'children_handles',
      'parent_handle',
      'root_handle',
    ],
  };
  const filteredData = data
    .filter((item) => item.children_handles?.length === 0)
    .filter((item) => {
      return (
        // we want to filter items that are hidden, not searchable, or parents from returning in search
        !item.tags.includes('__hidden') &&
        !item.tags.includes('__no-search') &&
        !item.tags.includes('__no-search')
      );
    });
  const fuse = new Fuse(filteredData, options);
  return fuse.search(query);
}

export function showGenerationToggleFuse(
  products: FuseSearchResults[],
): boolean {
  if (products) {
    const hasGen4OnlyItems = products.find(
      ({ item }) =>
        item.tags?.find((tag: string) => tag === 'gen4') &&
        !item.tags?.find((tag: string) => tag === 'gen3'),
    );
    const hasGen3OnlyItems = products.find(
      ({ item }) =>
        item.tags?.find((tag: string) => tag === 'gen3') &&
        !item.tags?.find((tag: string) => tag === 'gen4'),
    );
    return !!hasGen4OnlyItems && !!hasGen3OnlyItems;
  } else {
    return false;
  }
}

export interface FuseSearchResults {
  item: FullProduct;
}
