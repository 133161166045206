import { Dispatch, useEffect, useState } from 'react';
import { FullProduct } from '../hooks/useAllProducts';
import { compareStrings } from '../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useQueryParam } from '../utils/queryParamUtils';

export const ORDER_BY_URL_PARAM = 'order_by';

export function useCollectionSort(): {
  [key: string]: {
    label: string;
    sortFn: (a: FullProduct, b: FullProduct) => number;
  };
} {
  const { t } = useTranslation('product');
  return {
    featured: {
      label: t('featured'),
      sortFn: (product1: FullProduct, product2: FullProduct) => {
        const left = product1.orderize ?? Infinity;
        const right = product2.orderize ?? Infinity;

        return left - right;
      },
    },
    best_sellers: {
      label: t('bestSellers'),
      sortFn: (product1: FullProduct, product2: FullProduct) => {
        const p1BestSeller =
          product1.tags && product1.tags.indexOf('bestseller') !== -1;
        const p2BestSeller =
          product2.tags && product2.tags.indexOf('bestseller') !== -1;
        if (p1BestSeller && !p2BestSeller) {
          return -1;
        }
        if (!p1BestSeller && p2BestSeller) {
          return 1;
        }
        return 0;
      },
    },
    a_to_z: {
      label: t('alphabeticallyAZ'),
      sortFn: (product1: FullProduct, product2: FullProduct) =>
        compareStrings(product1.title, product2.title),
    },
    z_to_a: {
      label: t('alphabeticallyZA'),
      sortFn: (product1: FullProduct, product2: FullProduct) =>
        compareStrings(product2.title, product1.title),
    },
    low_to_high: {
      label: t('priceLowHigh'),
      sortFn: (product1: FullProduct, product2: FullProduct) =>
        parseFloat(product1.variants?.[0]?.price ?? '') -
        parseFloat(product2.variants?.[0]?.price ?? ''),
    },
    high_to_low: {
      label: t('priceHighLow'),
      sortFn: (product1: FullProduct, product2: FullProduct) =>
        parseFloat(product2.variants?.[0]?.price ?? '') -
        parseFloat(product1.variants?.[0]?.price ?? ''),
    },
    old_to_new: {
      label: t('dateOldNew'),
      sortFn: (product1: FullProduct, product2: FullProduct) =>
        compareStrings(product1.createdAt ?? '', product2.createdAt ?? ''),
    },
    new_to_old: {
      label: t('dateNewOld'),
      sortFn: (product1: FullProduct, product2: FullProduct) =>
        compareStrings(product2.createdAt ?? '', product1.createdAt ?? ''),
    },
  };
}

function useSort(
  defaultValue: string,
): [sortKey: string, sortQueryParams: string, setSortKey: Dispatch<string>] {
  const [sortKey, setSortKey] = useState<string>('');
  const [sortQueryParams, setSortQueryParams] = useQueryParam(
    ORDER_BY_URL_PARAM,
    defaultValue,
  );

  useEffect(() => {
    setSortQueryParams(sortKey);
  }, [sortKey]);

  return [sortKey, sortQueryParams, setSortKey];
}

export default useSort;
