import { useTranslation } from 'gatsby-plugin-react-i18next';
import { formatPrice } from '../utils/priceUtils';

function useFormattedPrice(value?: number | string): string {
  const { t } = useTranslation('price');
  const float = parseFloat(value as string);
  if (isNaN(float)) {
    return '--';
  }
  if (float === 0) {
    return t('free');
  }
  return formatPrice(value);
}

export default useFormattedPrice;
