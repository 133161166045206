import React, { useEffect, useState } from 'react';
import SEO from '~/components/Seo';
import '@whoop/web-components/dist/index.css';
import { Logo } from '@whoop/web-components';
import SignInForm from '~/components/SignInForm';
import { navigate, graphql } from 'gatsby';
import parse from 'url-parse';
import MessageBox from '../../components/MessageBox';
import { MessageBoxAlertType } from '../../components/MessageBox/MessageBox';
import { safeOpen } from '../../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useQueryParam } from '../../utils/queryParamUtils';
import { useCheckout, useUser } from '../../redux/hooks';
import { goToCheckout } from '../../utils/shopify';
import useShopifyCheckoutItemMap from '../../hooks/useShopifyCheckoutItemMap';

const LoginPage = ({ location }) => {
  const [redirect, setRedirect] = useState();
  const [alert] = useQueryParam('alert', '');
  const { t } = useTranslation('login');
  const user = useUser();
  const checkout = useCheckout();
  const cartItemsBySku = useShopifyCheckoutItemMap(checkout);

  useEffect(() => {
    const url = parse(location.href, true);
    const query = url.query;
    const redirectUrl = query?.checkout_url || query?.redirect;

    if (user) {
      if (redirectUrl) {
        if (query?.checkout_url) {
          goToCheckout(checkout, cartItemsBySku);
        } else {
          safeOpen(redirectUrl, '_self');
        }
      } else {
        navigate('/', { replace: true }); // navigate to home page without history
      }
    } else {
      setRedirect(redirect);
    }
  }, [user, location]);

  return (
    <>
      <SEO
        title={t('seo.title')}
        description={t('seo.description')}
        keywords={[t('seo.whoop'), t('seo.signIn'), t('seo.login')]}
      />
      <div
        style={{
          width: '400px',
          maxWidth: '90%',
          textAlign: 'center',
          margin: '8% auto',
        }}
      >
        <Logo variant='circle' width={64} height={64} />
        <h1
          style={{ fontFamily: 'var(--whoop-font-family-light)', fontSize: 32 }}
        >
          {t('signIn')}
        </h1>
        {alert === 'whoop-pro' && (
          <MessageBox
            alertType={MessageBoxAlertType.info}
            message={t('whoopPro')}
          />
        )}
        {alert === 'replacement-device' && (
          <MessageBox
            alertType={MessageBoxAlertType.info}
            message={t('replacementDevice')}
          />
        )}
        <p
          style={{
            fontFamily: 'var(--whoop-font-family-semibold)',
            color: 'var(--whoop-color-gray-600)',
          }}
        >
          {t('signInToCheckout')}
        </p>
        <SignInForm redirect={redirect} />
      </div>
    </>
  );
};

export default LoginPage;

export const translationQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
