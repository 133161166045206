/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import { useSelector } from 'react-redux';
import { ShopifyStorefront } from '../../types/shopify-storefront';
import { Store } from '../../types';
import Subtotal from '../Subtotal';
import { SubtotalLine } from '../Subtotal/Subtotal';
import { useHasProBenefits } from '../../redux/hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useFormattedPrice from '../../hooks/useFormattedPrice';
import { BasicComponentProps } from '@whoop/web-components/dist/types';
import { formatPrice } from '../../utils/priceUtils';

const CartSubtotal = ({ ...props }: BasicComponentProps): JSX.Element => {
  const { t } = useTranslation('cart');
  const checkout = useSelector((state: Store) => state.checkout);
  const hasProBenefits = useHasProBenefits();
  const subtotal = parseFloat(checkout?.subtotalPriceV2?.amount);
  const formattedPrice = useFormattedPrice(subtotal);
  const discount =
    checkout?.discountApplications
      ?.map(
        (discountApplication: ShopifyStorefront.DiscountApplication) =>
          (discountApplication.value as ShopifyStorefront.MoneyV2)?.amount,
      )
      .map(parseFloat)
      .reduce((acc: number, n: number) => n + acc, 0) || 0;
  const hasProDiscounts = !!checkout?.discountApplications.find(
    ({ description }) =>
      description?.toLowerCase() === t('freeRewardApplied') ||
      description?.toLowerCase().startsWith('pro member'),
  );
  const subtotalOriginalPrice = subtotal + discount;
  const hasItems = (checkout?.lineItems?.length || 0) > 0;
  const value = hasItems ? formattedPrice : '--';

  const lines: SubtotalLine[] = [];
  lines.push({
    label: t('subtotal'),
    value: value,
    isProDiscount: hasProDiscounts,
    originalPrice:
      !isNaN(subtotalOriginalPrice) && subtotalOriginalPrice !== subtotal
        ? formatPrice(subtotalOriginalPrice)
        : undefined,
  });
  if (hasProBenefits) {
    lines.push({
      label: t('shipping'),
      value: t('free'),
      isProDiscount: true,
    });
    lines.push({
      label: t('tax'),
      calculatedAtCheckout: true,
    });
  } else {
    lines.push({
      label: t('shippingAndTax'),
      value: !hasItems ? '--' : undefined,
      calculatedAtCheckout: hasItems,
    });
  }

  return <Subtotal lines={lines} {...props} />;
};
export default CartSubtotal;
