import { classes, openJoin } from '../../utils';
import styles from './side-navV2.module.scss';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Optional } from '@whoop/web-components';
import { navigate } from 'gatsby';
import IconButton from '../IconButton';
import { useReduxDispatch } from '../../redux/create-store';
import useNavigationTree from '../../hooks/useNavigationTree';
import { toggleSideNav } from '../../redux/reducers/viewReducer';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  getSeperatedGroups,
  NavigationGroup,
} from '../../utils/navigationUtils';
import { StorefrontUser } from '../../utils/membership-service';
import { Store } from '../../types';

/**
 * Side navigation panel
 */
const SideNavV2 = () => {
  const { t } = useTranslation('header');
  const navigationTree = useNavigationTree() as NavigationGroup[];
  const dispatch = useReduxDispatch();
  const showSideNav: boolean = useSelector(
    (state: Store) => state?.showSideNav,
  ) as boolean;
  const user: StorefrontUser = useSelector(
    (state: Store) => state?.user,
  ) as StorefrontUser;
  const [currentMenu, setCurrentMenu] = useState<number | null>();
  const dispatchToggleSideNav = () =>
    currentMenu === null || dispatch(toggleSideNav());
  const expandOrCollapse = (i: number) =>
    currentMenu === i ? setCurrentMenu(null) : setCurrentMenu(i);
  const navigateToLink = (link: string) => {
    dispatchToggleSideNav();
    link && navigate(link);
  };
  const goToJoin = (event: MouseEvent) => {
    dispatchToggleSideNav();
    openJoin(event);
  };

  return (
    <>
      <div
        className={classes('whoop-focus-background', styles.sideNavContainer)}
        data-showing={showSideNav}
        onClick={dispatchToggleSideNav}
      />
      <div
        role='menubar'
        aria-label={t('aria.mobileProductMenu')}
        className={classes(styles.sideNav, showSideNav && styles.show)}
      >
        <IconButton
          icon='cross'
          className={styles.close}
          onClick={dispatchToggleSideNav}
        />
        {navigationTree.map((group: NavigationGroup, i) => {
          const sortedChildren: Optional<NavigationGroup[][]> =
            group.children && getSeperatedGroups(group.children);
          return (
            <div key={`${i}-l3-group-items`} className={styles.group}>
              <div
                className={styles.groupLabel}
                onClick={() => {
                  if (!group.children) {
                    navigateToLink(group.link);
                  } else {
                    expandOrCollapse(i);
                  }
                }}
                data-event='Clicked Navigation'
                data-event-name={group.label}
                data-event-link={group.link}
                data-event-type='navigation-group'
                data-event-index={i + 1}
                data-event-navigation-level='1'
                data-event-navigation-type='Mobile'
              >
                <a
                  className='no-color'
                  href={group.link}
                  aria-label={group.label}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  {group.label}
                </a>
                {group.children && (
                  <Icon
                    name='caret_down'
                    className={classes(
                      'whoop-collapsible-plus',
                      styles.toggleButton,
                      currentMenu === i && styles.toggleArrowBtnDown,
                      currentMenu === null && styles.toggleArrowBtnUp,
                    )}
                  />
                )}
              </div>

              {group.children && (
                <div
                  className={classes(
                    styles.groupItems,
                    currentMenu === i && styles.expanded,
                  )}
                >
                  {group.link && (
                    <a
                      key={`${i}-l3-all-group-items`}
                      href={group.link}
                      aria-label={group.label}
                      className={classes(styles.item, styles.itemHeader)}
                      onClick={(event) => {
                        event.preventDefault();
                        navigateToLink(group.link);
                      }}
                      data-event='Clicked Navigation'
                      data-event-name={`Shop All ${group.label}`}
                      data-event-link={group.link}
                      data-event-type='menu-item'
                      data-event-index={0}
                      data-event-navigation-level='2'
                      data-event-navigation-type='Mobile'
                    >
                      {t('shopAll')}
                      {' - '}
                      {`${group.label}`}
                    </a>
                  )}
                  {sortedChildren?.map((items, i) =>
                    items.map((item, index) => (
                      <a
                        key={`${i}-${index}-l3-group-items`}
                        href={item.link}
                        aria-label={item.label}
                        className={classes(
                          styles.item,
                          index === 0 && styles.itemHeader,
                          sortedChildren.length === i + 1 &&
                            items.length === index + 1 &&
                            styles.lastItemHeader,
                        )}
                        onClick={(event) => {
                          event.preventDefault();
                          navigateToLink(item.link);
                        }}
                        data-event='Clicked Navigation'
                        data-event-name={item.label.trim()}
                        data-event-link={item.link}
                        data-event-navigation-level={index === 0 ? 2 : 3}
                        data-event-type='menu-item'
                        data-event-index={index === 0 ? index + 1 : index}
                        data-event-navigation-type='Mobile'
                      >
                        {item.label}
                      </a>
                    )),
                  )}
                </div>
              )}
            </div>
          );
        })}
        {!user && (
          <Button
            variant='primary'
            label={t('joinWhoop')}
            aria-label={t('joinWhoop')}
            style={{ marginTop: '16px' }}
            onClick={goToJoin}
          />
        )}
      </div>
    </>
  );
};

export default SideNavV2;
