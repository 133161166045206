import { startExperiment } from '../utils/experiments';
import { useIsOnForAll } from '../utils/feature-flags';
import { useEffect, useState } from 'react';

export enum CollectionRedesignVariant {
  'control' = 'control',
  'scroll' = 'scroll',
  'new' = 'new',
}

export function useCollectionRedesign(): CollectionRedesignVariant | undefined {
  const [variant, setVariant] = useState<CollectionRedesignVariant>();
  const featureIsOn = useIsOnForAll('storefront-collection-redesign');

  useEffect(() => {
    if (!variant && featureIsOn !== undefined) {
      if (featureIsOn) {
        setVariant(
          startExperiment(
            'experiment_collection_redesign',
          ) as CollectionRedesignVariant,
        );
      }
    }
  }, [featureIsOn]);

  return variant;
}
