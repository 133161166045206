import React, { useState, useEffect } from 'react';
import parse from 'url-parse';
import styles from './full-search.module.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useAllProducts } from '../../hooks/useAllProducts';
import {
  fuseSearch,
  FuseSearchResults,
  showGenerationToggleFuse,
} from '../../utils/fuseSearch';
import { ResultList } from '../ResultList';
import { SearchForm } from '../SearchForm';

interface FullSearchProps {
  location: {
    href: string;
  };
}

const FullSearch = ({ location }: FullSearchProps): JSX.Element => {
  const { t } = useTranslation('search');
  const [results, setResults] = useState<FuseSearchResults[]>([]);
  const [infoText, setInfoText] = useState<string>(t('whatAreYouLookingFor'));
  const url = parse(location.href, true);
  const query = url.query;
  const hasSearch = !!query.q;
  let componentToShow;

  const [genToggle, setGenToggle] = useState<string>('all');
  const allProducts = useAllProducts();

  useEffect(() => {
    if (hasSearch) {
      const searchResults: FuseSearchResults[] = fuseSearch(
        allProducts!,
        query.q!,
      );
      setResults(searchResults);
      setInfoText(t('somethingWentWrong'));
      if (searchResults.length === 0) {
        setInfoText(t('noResults', { query: query.q! }));
      } else {
        setInfoText(t('showingResults', { query: query.q! }));
      }
    }
  }, []);

  if (results.length === 0) {
    componentToShow = <SearchForm infoText={infoText} />;
  }

  if (results.length > 0) {
    const showToggle = showGenerationToggleFuse(results);
    componentToShow = (
      <ResultList
        results={results}
        infoText={infoText}
        genToggle={genToggle}
        setGenToggle={setGenToggle}
        showGenToggle={showToggle}
      />
    );
  }

  return <div className={styles.container}>{componentToShow}</div>;
};

export default FullSearch;
