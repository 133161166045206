import { ProductItem, ProductNode } from '@whoop/web-components';
import React, { useEffect, useState } from 'react';
import useShopifyVariantsBySku, {
  ShopifyVariantsBySku,
} from './useShopifyVariantsBySku';
import { parseShopifyId } from '../utils/shopify';
import { nonNull, skuWithoutSuffix, skuWithSuffix } from '../utils';
import {
  useQueryParam,
  QueryParams,
  useQueryParams,
} from '../utils/queryParamUtils';
import { Optional } from '@whoop/web-components/dist/types';
import {
  findFirstProductItem,
  findProductItemBySku,
} from '../utils/productUtils';
import { Purchasable, usePurchasablePack } from '../utils/purchasableUtils';

const findProductItemFromVariantId = (
  variantId: string,
  node: ProductNode,
  variants: ShopifyVariantsBySku,
): ProductItem | undefined =>
  node?.product_info?.items?.find(
    (item) => parseShopifyId(variants?.[item?.sku]?.shopifyId) === variantId,
  ) ||
  node?.children
    ?.map((n) => findProductItemFromVariantId(variantId, n, variants))
    ?.find(nonNull); // first non-null

/**
 * Selects a ProductItem from a given node based on the URL.
 * The expected URL is one with a query parameter `sku=[SKU]`
 * For backwards compatibility it will also look for `variant=[Shopify Variant ID]`
 * but it will replace it with the SKU in the URL
 *
 * @param node node to select item from
 */
export function useProductUrlState(
  node: ProductNode,
): [
  ProductItem | undefined,
  React.Dispatch<React.SetStateAction<ProductItem | undefined>>,
] {
  const [value, setValue] = useState<ProductItem>();
  const variants = useShopifyVariantsBySku();
  const [variantIdFromUrl, setVariantIdFromUrl] = useQueryParam('variant', '');
  const [skuFromUrl, setSkuFromUrl] = useQueryParam('sku', '');

  // Update state from URL
  useEffect(() => {
    if (!value && node) {
      if (skuFromUrl || variantIdFromUrl) {
        // Select based on SKU
        if (skuFromUrl) {
          const valueFromUrl = findProductItemBySku(
            skuWithSuffix(skuFromUrl),
            node,
          );
          if (valueFromUrl) {
            setValue(valueFromUrl);
          } else {
            setValue(findFirstProductItem(node));
            setSkuFromUrl(''); // clear if we didn't find one
          }
        }
        // Select based on Variant
        else if (variantIdFromUrl) {
          setValue(
            findProductItemFromVariantId(variantIdFromUrl, node, variants) ||
              findFirstProductItem(node),
          );
        }
      } else {
        setValue(findFirstProductItem(node));
      }
    }
  }, [node]);

  // Update URL from state
  useEffect(() => {
    if (value?.sku) {
      setSkuFromUrl(encodeURIComponent(skuWithoutSuffix(value.sku) || ''));
    }
  }, [value]);

  // Clear out legacy URL
  useEffect(() => {
    if (variantIdFromUrl) {
      setVariantIdFromUrl(''); // clear out the variant
    }
  }, [variantIdFromUrl]);

  return [value, setValue];
}

const packItemQueryParam = (index: number) => `item${index + 1}`;

export function usePackProductUrlState(
  node: Optional<ProductNode>,
  packNodes: ProductNode[],
): [
  purchasable: Optional<Purchasable>,
  packItems: Optional<ProductItem>[],
  setPackItem: (items: ProductItem[]) => any,
] {
  const [packItems, setPackItems] = useState<Optional<ProductItem>[]>([]);
  const purchasable = usePurchasablePack(node, packItems);
  const [params, setParams] = useQueryParams({});
  const [variantIdFromUrl, setVariantIdFromUrl] = useQueryParam('variant', '');

  // Update state from URL
  useEffect(() => {
    if (node && (!packItems || packItems.length === 0)) {
      let stateChanged = false;
      const newState = [...packItems];
      packNodes?.forEach((packNode, index) => {
        if (packNode && !packItems[index]) {
          const itemParam = packItemQueryParam(index);
          const skuFromUrl = params[itemParam];
          if (skuFromUrl) {
            const valueFromUrl = findProductItemBySku(
              skuWithSuffix(skuFromUrl),
              packNode,
            );
            if (valueFromUrl) {
              stateChanged = true;
              newState[index] = valueFromUrl;
            } else {
              newState[index] = findFirstProductItem(packNode);
              stateChanged = true;
            }
          }
        }
      });
      if (stateChanged) {
        setPackItems(newState);
      }
    }
  }, [node]);

  // Update URL from state
  useEffect(() => {
    const params: QueryParams = {};
    packItems?.forEach((item, index) => {
      if (item?.sku) {
        params[packItemQueryParam(index)] = skuWithoutSuffix(item.sku);
      }
    });
    setParams(params);
  }, [packItems]);

  // Clear out legacy URL
  useEffect(() => {
    if (variantIdFromUrl) {
      setVariantIdFromUrl(''); // clear out the variant
    }
  }, [variantIdFromUrl]);

  return [purchasable, packItems, setPackItems];
}
