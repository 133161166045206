import useShopifyVariantsBySku from '../hooks/useShopifyVariantsBySku';
import { useEffect, useState } from 'react';
import { fetchShopifyProduct } from './shopify';
import { ShopifyStorefront } from '../types/shopify-storefront';
import { cachedPromise } from './fetchUtils';
import { useCheckout } from '../redux/hooks';
import { singuralizeHook } from './index';
import { ShopifyProduct, ShopifyProductVariant } from '../types/generated';
import { Optional } from '@whoop/web-components/dist/types';

/**
 * Checks the total inventory for a particular SKU in cart
 * @param sku sku to check
 * @param checkout checkout to search
 */
export const quantityInCartForSku = (
  sku?: string,
  checkout?: ShopifyStorefront.Checkout,
) => {
  let quantity = 0;

  if (sku) {
    checkout?.lineItems?.forEach((line) => {
      if (line?.variant?.sku === sku) {
        quantity += line.quantity;
      }
      let qtyInCustomAttrs = 0;
      line?.customAttributes?.forEach(({ key, value }) => {
        if (key.includes('sku') && value === sku) {
          qtyInCustomAttrs += line.quantity;
        }
      });
      quantity += qtyInCustomAttrs;
    });
  }
  return quantity;
};

function useSkuAvailabilities(skus?: (string | undefined)[]) {
  const variants = useShopifyVariantsBySku();
  const [skuCache, setSkuCache] = useState<{ [sku: string]: number }>({});

  useEffect(() => {
    skus?.forEach((sku) => {
      const variant = variants?.[sku || ''];
      if (variant?.product?.shopifyId && sku && skuCache[sku] === undefined) {
        cachedPromise(
          `shopifyClientProductFetch:${variant?.product?.shopifyId}`,
          () =>
            fetchShopifyProduct(
              variant?.product?.shopifyId,
            ) as Promise<ShopifyProduct>,
        ).then((product: ShopifyProduct) => {
          const newSkus = {};
          product?.variants?.forEach(
            (variant: Optional<ShopifyProductVariant>) => {
              if (variant?.sku) {
                if (product?.productType?.toLowerCase() === 'gift cards') {
                  skuCache[variant?.sku] = Infinity;
                } else {
                  skuCache[variant?.sku] = variant?.quantityAvailable || 0;
                }
              }
            },
          );
          setSkuCache({ ...skuCache, ...newSkus });
        });
      }
    });
  }, [skus]);

  return skus?.map((sku) => sku && skuCache[sku]);
}

export const useSkuMaxQuantities = (
  skus?: (string | undefined)[],
): (number | undefined)[] => {
  const checkout = useCheckout();
  const availabilities = useSkuAvailabilities(skus);

  return (
    skus?.map((sku, i): number | undefined => {
      if (availabilities?.[i] !== undefined) {
        return (availabilities?.[i] || 0) - quantityInCartForSku(sku, checkout);
      }
      return undefined;
    }) || []
  );
};

export const useSkuMaxQuantity = singuralizeHook(useSkuMaxQuantities);
