import { FuseSearchResults } from '../../utils/fuseSearch';
import React, { Dispatch } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styles from './result-list.module.scss';
import { ToggleButton } from '@whoop/web-components';
import ProductGrid from '../ProductGrid/ProductGrid';
import ProductCardWrapper from '../ProductCardWrapper';

export const ResultList = ({
  results,
  infoText,
  genToggle,
  setGenToggle,
  showGenToggle,
}: {
  results: FuseSearchResults[];
  infoText: string;
  genToggle: string;
  setGenToggle: Dispatch<string>;
  showGenToggle: boolean;
}) => {
  const { t } = useTranslation('search');

  return (
    <section className={styles.results} aria-label={t('aria.searchResults')}>
      <h2 className={styles.header}>{t('search')}</h2>
      <p className={styles.stats}>{infoText}</p>
      {showGenToggle && (
        <div className={styles.toggle}>
          <ToggleButton
            value={genToggle}
            options={[
              { value: 'all', label: t('all') },
              { value: 'gen4', label: 'whoop 4.0' },
              { value: 'gen3', label: 'whoop 3.0' },
            ]}
            onChange={(value: string) => setGenToggle(value)}
          />
        </div>
      )}
      <ProductGrid>
        {results
          .filter((product) =>
            genToggle !== 'all'
              ? product.item.tags.includes(genToggle)
              : product,
          )
          .map(({ item }) => {
            return (
              <ProductCardWrapper
                key={item.handle}
                item={item}
                userLocation='Search'
              />
            );
          })}
      </ProductGrid>
    </section>
  );
};
