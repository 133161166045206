// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-login-js": () => import("./../../../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-collection-page-collection-page-tsx": () => import("./../../../src/templates/CollectionPage/CollectionPage.tsx" /* webpackChunkName: "component---src-templates-collection-page-collection-page-tsx" */),
  "component---src-templates-gift-card-page-gift-card-page-tsx": () => import("./../../../src/templates/GiftCardPage/GiftCardPage.tsx" /* webpackChunkName: "component---src-templates-gift-card-page-gift-card-page-tsx" */),
  "component---src-templates-pack-product-page-pack-product-page-tsx": () => import("./../../../src/templates/PackProductPage/PackProductPage.tsx" /* webpackChunkName: "component---src-templates-pack-product-page-pack-product-page-tsx" */),
  "component---src-templates-product-page-product-page-tsx": () => import("./../../../src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-product-page-tsx" */),
  "component---src-templates-whoop-your-way-page-whoop-your-way-page-tsx": () => import("./../../../src/templates/WhoopYourWayPage/WhoopYourWayPage.tsx" /* webpackChunkName: "component---src-templates-whoop-your-way-page-whoop-your-way-page-tsx" */)
}

