/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
import React, { useRef } from 'react';
import styles from './okendo-reviews-widget.module.scss';
import { Optional } from '@whoop/web-components/dist/types';
import { classes } from '@whoop/web-components';
import { useOkendoStarReviews } from '../../hooks/okendoHooks';
interface OkendoReviewStarsWidgetProps
  extends React.HTMLAttributes<HTMLDivElement> {
  productId: Optional<string> | undefined;
  className?: string;
}

const OkendoReviewStarsWidget = ({
  productId,
  className,
  ...props
}: OkendoReviewStarsWidgetProps): JSX.Element => {
  const widgetContainer = useRef<HTMLDivElement>(null);

  useOkendoStarReviews(widgetContainer, productId);
  return (
    <div
      ref={widgetContainer}
      data-oke-star-rating
      data-oke-reviews-product-id={`shopify-${productId ?? ''}`}
      className={classes(styles.reviewStars, className)}
      {...props}
    ></div>
  );
};

export default OkendoReviewStarsWidget;
