import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        type
        siteName
        url
        twitterUsername
        image
        author
      }
    }
  }
`;

type Meta = ConcatArray<PropertyMetaObj | NameMetaObj>;

type PropertyMetaObj = {
  property: string;
  content: string;
};

type NameMetaObj = {
  name: string;
  content: string;
};

interface SeoProps {
  description?: string;
  lang?: string;
  meta?: Meta;
  keywords?: string[];
  title?: string;
  imageSrc?: string | null;
  type?: string;
}

function Seo({
  description,
  lang,
  meta,
  keywords,
  title,
  imageSrc,
  type,
}: SeoProps): JSX.Element {
  const { site } = useStaticQuery<QueryTypes>(detailsQuery);
  const { pathname, search } = useLocation();

  const {
    title: defaultTitle,
    description: defaultDescription,
    url: siteUrl,
    type: defaultType,
    image: defaultImage,
    siteName,
    twitterUsername,
    author,
  } = site.siteMetadata;

  const seo = {
    title,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}${search}`,
    type: type ?? defaultType,
    image: `${imageSrc || defaultImage}`,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'author', content: author },
        {
          name: 'description',
          content: seo.description,
        },
        {
          name: 'image',
          content: seo.image,
        },
        { name: 'og:url', content: seo.url },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: seo.description,
        },
        {
          property: 'og:type',
          content: seo.type,
        },
        {
          property: 'og:site_name',
          content: siteName,
        },
        {
          property: 'og:site_url',
          content: siteUrl,
        },
        {
          property: 'og:image',
          content: seo.image,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: seo.title,
        },
        {
          name: 'twitter:description',
          content: seo.description,
        },
        {
          name: 'twitter:creator',
          content: twitterUsername,
        },
        { name: 'twitter:image', content: seo.image },
      ]
        .concat(
          keywords!.length > 0
            ? {
                name: 'keywords',
                content: keywords!.join(', '),
              }
            : [],
        )
        .concat(meta!)}
    />
  );
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  title: null,
  description: null,
  imageSrc: null,
  article: false,
};

export default Seo;

type QueryTypes = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
      url: string;
      type: string;
      image: string;
      siteName: string;
      twitterUsername: string;
    };
  };
};
