/* eslint-disable @typescript-eslint/ban-ts-comment */
import { graphql } from 'gatsby';
import { classes } from '../../utils';
import styles from './gift-card-page.module.scss';
import React, { useEffect, useMemo } from 'react';
import {
  getDescriptionFromSelection,
  getMediaFromSelection,
  MediaItem,
  ProductDetailsGrid,
  PulsingLogo,
  RadioButtons,
} from '@whoop/web-components';
import { SHOP_CURRENCY_CODE } from '../../utils/regions';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import BuyBox from '../../components/BuyBox';
import { useProductNode } from '../../hooks/staticProductHooks';
import Seo from '../../components/Seo';
import { useProductUrlState } from '../../hooks/urlSelectionHooks';
import { useProductAccordions } from '../../utils/productUtils';
import { usePurchasableItem } from '../../utils/purchasableUtils';
import { useShopifyProductData } from '../../utils/shopifyProductUtils';
import { formatPrice, usePriceTag } from '../../utils/priceUtils';
import { ShopifyProduct } from '../../types/generated';
import ProductHighlights from '../../components/ProductHighlights';
import { klaviyoTrackViewedProductPage } from '../../utils/analytics';

export const Media = ({ url, id, type }: MediaItem) => {
  if (type === 'video') {
    return (
      <video autoPlay loop playsInline disableRemotePlayback muted>
        <source src={url} type='video/mp4' />
      </video>
    );
  }
  return <img src={url} alt={`product-img-${id}`} />;
};

type GiftCardPageProps = {
  pageContext: {
    handle: string;
    shopifyProduct: ShopifyProduct;
  };
};

export default function GiftCardPage({
  pageContext: { handle, shopifyProduct },
}: GiftCardPageProps): JSX.Element {
  const { t } = useTranslation('product');
  const node = useProductNode(handle);
  const [value, setValue] = useProductUrlState(node);
  const accordions = useProductAccordions(value, node);
  const { variant, tags } = useShopifyProductData(value?.sku);
  const purchasable = usePurchasableItem(value, node);
  const priceTag = usePriceTag(tags, variant, purchasable);
  const media = getMediaFromSelection(value!, node);
  const description = getDescriptionFromSelection(value, node);

  const cardOptions = useMemo(
    () =>
      shopifyProduct?.variants?.map((variant) => ({
        label: formatPrice(variant?.price),
        value: variant?.sku,
      })),
    [shopifyProduct],
  );

  const onSelect = (sku: string) => {
    const newValue = node?.product_info?.items?.find(
      (item) => item?.sku === sku,
    );
    setValue(newValue);
  };

  useEffect(() => {
    // Klaviyo requires specific Shopify info
    klaviyoTrackViewedProductPage(shopifyProduct);
  }, []);

  return (
    <div>
      <Seo
        title={node?.product_info?.title}
        description={node?.product_info?.description || ''}
        keywords={[node?.product_info?.title || '', 'WHOOP']}
        imageSrc={node?.product_info?.featured_media?.url}
        type='product'
        meta={[
          {
            name: 'product:price:amount',
            content: variant?.price?.toString() || '',
          },
          {
            name: 'product:price:currency',
            content: SHOP_CURRENCY_CODE,
          },
        ]}
      />
      <section className={classes(styles.productPage, 'whoop-ui')}>
        <ProductDetailsGrid
          className={styles.productDetails}
          title={node?.product_info.title || ''}
          price={priceTag.price}
          accordions={accordions}
          noImageScroll={true}
          isFancy={false}
          data-test-id='product-info'
          media={media?.map((item) => (
            <Media {...item} key={item.id} />
          ))}
        >
          <div className={styles.radioContainer}>
            <h4>{t('amount')}</h4>
            <RadioButtons
              options={cardOptions}
              size='small'
              value={value?.sku}
              onChange={onSelect}
            />
          </div>
          <BuyBox purchasable={purchasable} />
          <ProductHighlights tags={tags || []} />
          {description && (
            <div
              className={styles.productDescription}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </ProductDetailsGrid>
        {!node && (
          <div className='loading-placeholder'>
            <PulsingLogo color='dark' size='large' />
          </div>
        )}
      </section>
    </div>
  );
}

export const translationsQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
