/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import styles from './toast.module.scss';
// import { useTranslation } from 'gatsby-plugin-react-i18next';

export interface ToastProps {
  //   lines: SubtotalLine[];
  children: React.ReactNode;
}

const Toast = ({ children }: ToastProps): JSX.Element => {
  //   const { t } = useTranslation('cart');
  return <div className={styles.toast}>{children}</div>;
};
export default Toast;
