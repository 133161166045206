import React from 'react';
import { useSelector } from 'react-redux';
import styles from './application-error.module.scss';
import Alert from '../Alert';
import { useReduxDispatch } from '../../redux/create-store';
import { dismissGlobalError } from '../../redux/reducers/viewReducer';

const ApplicationError = () => {
  const appError = useSelector((state) => state.error);
  const dispatch = useReduxDispatch();
  let notification = null;

  const dismiss = () => {
    dispatch(dismissGlobalError());
  };

  if (appError) {
    const { message } = appError;
    notification = (
      <Alert
        className={styles.appError}
        message={message}
        onDismiss={dismiss}
      />
    );
  }

  return notification;
};

export default ApplicationError;
