import { SearchResult } from '../../types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ProductGrid from '../ProductGrid/ProductGrid';
import React from 'react';
import { FuseSearchResults } from '../../utils/fuseSearch';
import ProductCardWrapper from '../ProductCardWrapper';

export const ShortResultList = ({
  results,
  onReset,
}: {
  results: Array<FuseSearchResults>;
  onClick: (props: SearchResult) => void;
  onReset: () => void;
}) => {
  const shortenedResults = results.splice(0, 4);
  const { t } = useTranslation('search');

  return (
    <section aria-label={t('aria.searchResults')}>
      <ProductGrid>
        {shortenedResults
          .map(({ item }) => {
            return (
              <ProductCardWrapper
                onReset={onReset}
                key={item.handle}
                item={item}
                userLocation='Search'
              />
            );
          })
          .splice(0, 4)}
      </ProductGrid>
    </section>
  );
};
