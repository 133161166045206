/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { ImageDataLike } from 'gatsby-plugin-image';

const HomepagesQuery = graphql`
  query getHomepagesV2 {
    allWhoopHomepage {
      nodes {
        handle
        image
        title
        sub_title
        link
        fields {
          localFile
        }
      }
    }
    allFile(filter: { ext: { regex: "/(png|jpe?g)/" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
export type HomepageWidget = {
  handle: string;
  image: string;
  title: string;
  sub_title: string;
  link: string;
  staticImage?: ImageDataLike;
};

export default function (): {
  [handle: string]: HomepageWidget;
} {
  const queryResult = useStaticQuery<{
    allFile: {
      nodes: ({ id: string } & ImageDataLike)[];
    };
    allWhoopHomepage: {
      nodes: {
        handle: string;
        image: string;
        title: string;
        sub_title: string;
        link: string;
        fields: { localFile: string }; // id from allFile query
      }[];
    };
  }>(HomepagesQuery);
  return useMemo(() => {
    const widgets: { [handle: string]: HomepageWidget } = {};
    queryResult?.allWhoopHomepage?.nodes?.forEach((node) => {
      widgets[node.handle] = {
        handle: node.handle,
        image: node.image,
        title: node.title,
        sub_title: node.sub_title,
        link: node.link,
        staticImage: queryResult?.allFile?.nodes?.find(
          ({ id }) => id === node?.fields?.localFile,
        ),
      };
    });
    return widgets;
  }, [queryResult]);
}
