/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { MouseEventHandler, useEffect, useState } from 'react';
import {
  BadgeColors,
  ProductCard,
  ProductItem,
  ProductNode,
  WhoopYourWayCard,
} from '@whoop/web-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';
import { FullProduct, useAllProducts } from '../../hooks/useAllProducts';
import styles from './product-card-wrapper.module.scss';
import { useStaticProductNode } from '../../hooks/staticProductHooks';
import { classes } from '../../utils';
import { parseShopifyId } from '../../utils/shopify';
import {
  formatPrice,
  useDiscountMessage,
  useIsDiscounted,
  usePriceTag,
  useWhoopYourWayPriceTag,
} from '../../utils/priceUtils';
import { useShopifyProductData } from '../../utils/shopifyProductUtils';
import { useSkuMaxQuantity } from '../../utils/quantityUtils';
import {
  findFirstProductItem,
  isGiftCard,
  isMemberExtension,
  isPack,
} from '../../utils/productUtils';
import useWhoopYourWayProduct from '../../hooks/useWhoopYourWayProduct';
import { useIsEmployee, useIsPro } from '../../redux/hooks';
import { trackWhoopAnalyticsEvent } from '../../utils/analytics';
import OkendoReviewStarsWidget from '../../components/OkendoReviewsWidget/OkendoReviewStarsWidget';
import { useIsOnForAll } from '../../utils/feature-flags';
import { usePurchasablePack } from '../../utils/purchasableUtils';
import { is3for2Sale } from '../../utils/products';

interface ProductCardWrapperProps {
  item: FullProduct;
  onReset?: () => void;
  wywFilter?: string | undefined;
  className?: string | undefined;
  carouselLocation?: string | undefined;
  userLocation?: string | undefined;
}

function WhoopYourWayCardWrapper({ onClick }: { onClick: MouseEventHandler }) {
  const shopifyProduct = useWhoopYourWayProduct();
  const tags = shopifyProduct?.tags as string[];
  const { price, originalPrice, isProDiscount, whoopProPrice } =
    useWhoopYourWayPriceTag(tags);
  const isPro = useIsPro();
  const withWhoopProPrice =
    !isProDiscount && !!whoopProPrice ? whoopProPrice : undefined;
  const discountMessage = useDiscountMessage(undefined, tags);
  const showReviews = useIsOnForAll('storefront-show-reviews');

  return (
    <WhoopYourWayCard
      title={'WHOOP Your Way'}
      price={price}
      originalPrice={originalPrice}
      isProDiscount={isProDiscount}
      withWhoopProPrice={isPro ? withWhoopProPrice : undefined}
      subTitle={discountMessage}
      onClick={onClick}
      data-test-id={`product-card-whoop-your-way-kit`}
      reviewStars={
        showReviews && (
          <OkendoReviewStarsWidget
            productId={parseShopifyId(shopifyProduct?.shopifyId)}
          />
        )
      }
    />
  );
}

export default function ProductCardWrapper({
  item,
  onReset,
  wywFilter,
  className,
  carouselLocation,
  userLocation,
}: ProductCardWrapperProps): React.ReactElement {
  const { t } = useTranslation(['productCard', 'product']);
  const isEmployee = useIsEmployee();
  const isPro = useIsPro();
  const node = useStaticProductNode(item.handle);
  const [value, setValue] = useState<ProductItem>();
  const { variant, tags } = useShopifyProductData(value?.sku);
  const purchasable = usePurchasablePack(node);
  const priceTag = usePriceTag(tags, variant, purchasable);
  const discountMessage = useDiscountMessage(variant, tags);
  const maxQuantity = useSkuMaxQuantity(value?.sku);
  const is3for2SaleOnForAll = useIsOnForAll('storefront-3for2-sale');
  const isOn3for2Sale = is3for2Sale(item.tags) && is3for2SaleOnForAll;
  const isPackProduct = isPack(variant?.sku);
  const isOnSale = useIsDiscounted(variant, tags || []) && !isPackProduct;
  const showReviews = useIsOnForAll('storefront-show-reviews');

  const disclaimer =
    isEmployee && value?.employee_discount === false
      ? t('employeeBlackout')
      : undefined;
  const rootProduct = useAllProducts([item.root_handle || item.handle], true);

  const goToProductPage = (handle: string, shopifyId?: string) => {
    trackWhoopAnalyticsEvent('Clicked Product Card', {
      product: item.handle,
      is_parent: (node.children || []).length > 0,
      is_sale: value?.on_sale,
      is_new: value?.new,
      is_oos: (value?.quantity || 0) <= 0,
      is_exclusive: value?.pro_exclusive,
      carousel: carouselLocation,
      user_location: userLocation,
    });
    navigate(
      `/products/${handle}/${
        shopifyId ? `?variant=${parseShopifyId(shopifyId) as string}` : ''
      }`,
    );
    onReset && onReset();
  };

  const goToWYWProductPage = () => {
    trackWhoopAnalyticsEvent('Clicked Product Card', {
      product: item.handle,
      is_parent: (node.children || []).length > 0,
      is_sale: value?.on_sale,
      is_new: value?.new,
      is_oos: (value?.quantity || 0) <= 0,
      is_exclusive: value?.pro_exclusive,
      carousel: carouselLocation,
      user_location: userLocation,
    });
    navigate(
      `/products/whoop-your-way-kit/${
        wywFilter ? `?preselect=${wywFilter}` : ''
      }`,
    );
    onReset && onReset();
  };

  const onAnalyticsEvent = (eventName: string, { item, ...context }: any) => {
    if (item) {
      const productItem = item as ProductItem;
      trackWhoopAnalyticsEvent(eventName, {
        ...context,
        is_parent: (node.children || []).length > 0,
        is_sale: productItem.on_sale,
        is_new: productItem.new,
        is_oos: (productItem.quantity || 0) <= 0,
        is_exclusive: productItem.pro_exclusive,
        carousel: carouselLocation,
        user_location: userLocation,
      });
    } else {
      trackWhoopAnalyticsEvent(eventName, context);
    }
  };

  let badge: string;
  let badgeColor: BadgeColors;
  const isExclusive = value?.pro_exclusive;
  const isComingSoon = value?.coming_soon;
  const showOutOfStockBadge =
    !isPackProduct && maxQuantity !== undefined && maxQuantity <= 0;

  const isNew = value?.new;
  const hasPattern = item.varying_pattern;
  let hasVaryingPattern = false;
  const hasSizes = node?.product_info?.sizes
    ? node.product_info.sizes.length > 0
    : false;

  function hasVaryingPatternCheck(node: ProductNode): boolean {
    // need to recursively check the handles of the children since the different band kinds(bicep, luxe) are on different level of the tree structure
    if (node.children.length > 0) {
      node.children.forEach((element) => {
        if (
          element.product_info.handle
            .split('-superknit')[0]
            .replace('/-/g', '') === value?.color?.handle.replace('/-/g', '')
        ) {
          hasVaryingPattern = element.product_info.varying_pattern as boolean;
        }
        if (element.children.length > 0) {
          hasVaryingPatternCheck(element);
        }
      });
    }
    return hasVaryingPattern;
  }

  if (isComingSoon) {
    badge = t('comingSoon');
  } else if (isOn3for2Sale) {
    badge = t('sale');
  } else if (showOutOfStockBadge) {
    badge = t('outOfStock');
    badgeColor = BadgeColors.GRAY;
  } else if (isExclusive) {
    badge = t('exclusive');
    badgeColor = BadgeColors.PRO;
  } else if (isOnSale) {
    badge = t('onSale');
  } else if (isNew) {
    badge = t('new');
  }

  // for the WYW card we should just pick any ProductItem from it
  useEffect(() => {
    if (item.handle === 'whoop-your-way-kit') {
      setValue(findFirstProductItem(node));
    }
  }, [item]);

  return (
    <>
      {item.handle === 'whoop-your-way-kit' && (
        <WhoopYourWayCardWrapper
          onClick={(e) => {
            e.stopPropagation();
            goToWYWProductPage();
          }}
        />
      )}
      {!(item.handle === 'whoop-your-way-kit') && node && (
        <ProductCard
          node={node}
          className={classes(
            (item.tags.includes('superknit') ||
              item.tags.includes('proknit') ||
              item.tags.includes('hydroband') ||
              item.tags.includes('superbicep') ||
              item.tags.includes('pack') ||
              item.tags.includes('battery') ||
              item.tags.includes('gen3') ||
              item.tags.includes('superlux') ||
              item.tags.includes('hydroknit') ||
              item.tags.includes('_adjust_image') || // Support manual override of the style
              isMemberExtension(value?.sku) ||
              isGiftCard(value?.sku)) &&
              styles.isSuperknit,
            styles.productCardWrapper,
            hasSizes && styles.sizeSwatchSpacer,
            className,
          )}
          price={priceTag.price}
          originalPrice={priceTag.originalPrice}
          isProDiscount={priceTag.isProDiscount}
          withWhoopProPrice={
            isPro && priceTag.whoopProPrice
              ? formatPrice(priceTag.whoopProPrice)
              : undefined
          }
          // @ts-ignore
          badgeLabel={badge}
          // @ts-ignore
          badgeColor={badgeColor && badgeColor}
          onChange={setValue}
          singleImage={true}
          subTitle={discountMessage || disclaimer}
          key={item.handle}
          imageMessage={
            hasPattern || hasVaryingPatternCheck(node)
              ? t('varyingPattern')
              : undefined
          }
          data-test-id={`product-card-${item.handle}`}
          onClick={(e) => {
            e.stopPropagation();
            // @ts-ignore
            goToProductPage(
              item.root_handle || item.handle,
              variant?.shopifyId as string,
            );
          }}
          onAnalyticsEvent={onAnalyticsEvent}
          reviewStars={
            showReviews && (
              <OkendoReviewStarsWidget
                productId={parseShopifyId(
                  typeof rootProduct !== 'undefined' &&
                    rootProduct[0]?.shopifyId.replace('Shopify__Product__', ''),
                )}
              />
            )
          }
        />
      )}
    </>
  );
}
