import React from 'react';
import FullCart from '~/components/FullCart';
import styles from './cart.module.scss';
import { classes } from '../utils';
import SEO from '~/components/Seo';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const CartPage = () => {
  const { t } = useTranslation('cart');

  return (
    <div className={classes(styles.cartContainer)}>
      <SEO
        title={t('seo.title')}
        description={t('seo.description')}
        keywords={[t('seo.whoop'), t('seo.cart')]}
      />
      <h1 className={styles.header}>{t('yourCart')}</h1>
      <FullCart />
    </div>
  );
};

export default CartPage;

export const translationQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
